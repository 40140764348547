import React, { useState } from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import Input from "../../component/inputs/input";
import { Box } from "@mui/material";
import Schema from "../../schema";
import Select from "../../component/inputs/select";
import { creatOrderReturn } from "./action";
import { BillingReturn } from "../../Interface/BillingReturnInterface";

const AddUser = ({
  isEdit,
  addedProduct,
  setBarcode,
  setAddedProduct,
  setOpen,
  openName
}: any) => {
  const dispatch: any = useDispatch();
  const { products } = useSelector((state: any) => state.returnReducer);
  const [isDisabled, setIsDisabled] = useState(false);
  // const getStoreId = localStorage.getItem("storeId")
  const { storeId: getStoreId } = useSelector(
    (state: any) => state.commonReducer
  );
  const initialValues = {
    payType: isEdit?.length ? isEdit[0].userType : "",
    invoiceNumber: "",
    reason:""
  };

  var OrderId:number;

  products.map(((a:BillingReturn)=> OrderId = a.id))
  
  const filterProduct = addedProduct?.map((a:any)=>a.quantity>0&&a)

  const lastFinalProduct = filterProduct.filter(Boolean)
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema.onlineCashCollectReturnPopup}
      onSubmit={(values) => {
        setIsDisabled(true);
        dispatch(creatOrderReturn(values, lastFinalProduct, OrderId,getStoreId,setBarcode,setAddedProduct,setOpen,setIsDisabled,openName));
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Input
              value={values.invoiceNumber}
              name="invoiceNumber"
              type="text"
              error={errors.invoiceNumber}
              label="Invoice Number"
              onChange={handleChange}
              handleBlur={handleBlur}
              />
             <Input
              value={values.reason}
              name="reason"
              type="text"
              error={errors.reason}
              label="Reason"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Select
              handleChange={handleChange}
              value={values.payType}
              label={"Pay Type"}
              option={["swiggy", "zomato","Paytm","TNSApp"].map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
              name="payType"
              error={errors.payType}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button disabled={isDisabled} variant="outlined" type="submit">
              Payment collect
            </Button>
            <Button variant="outlined" sx={{ ml: 2 }}>
            Cancel
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddUser;
