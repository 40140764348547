import Utils from "../../utility"

export const getAudit = (storeId: any) => {
    return (dispatch: any) => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const formattedToday = today.toISOString().split('T')[0];
        const formattedYesterday = yesterday.toISOString().split('T')[0];

        dispatch({
            type: Utils.ActionName.LOADING,
            payload: { loading: true },
        });

        Utils.api.getApiCall(
            Utils.endPoints.cashOut,
            `?storeId=${storeId}&fromDate=${formattedYesterday}&toDate=${formattedToday}`,
            (respData: any) => {
                dispatch({
                    type: Utils.ActionName.CASHOUT,
                    payload: {
                        auditData: respData.data,
                        loading: false,
                    },
                });
                dispatch({
                    type: Utils.ActionName.LOADING,
                    payload: { loading: false },
                });
            },
            (error: any) => {
                dispatch({
                    type: Utils.ActionName.LOADING,
                    payload: { loading: false },
                });
                let { data } = error;
                if (!data && !data.message) {
                    Utils.showAlert(2, "CashOut Get Error:");
                } else {
                    Utils.showAlert(2, "CashOut Get Error: " + data?.message);
                }
            },
        );
    };
};


export const getStoreId = (storeId: any) => {
    return (dispatch: any) => {
        dispatch({
            type: Utils.ActionName.LOADING,
            payload: { loading: true },
        })
        Utils.api.getApiCall(
            Utils.endPoints.store,
            `${storeId}`,
            (respData: any) => {
                dispatch({
                    type: Utils.ActionName.CASHOUT,
                    payload: {
                        storeData: respData.data,
                        loading: false,
                    },
                })
                dispatch({
                    type: Utils.ActionName.LOADING,
                    payload: { loading: false },
                })
            },
            (error: any) => {
                dispatch({
                    type: Utils.ActionName.LOADING,
                    payload: { loading: false },
                })
                let { data } = error
                if(data?.message === "" || !data) {
                    Utils.showAlert(2,"Store Get Error:")
                }else{
                    Utils.showAlert(2,"Store Get Error: " +  data?.message)
                }
            },
        )
    }
}

export const getProductsStoreStocks = (storeId: any) => {
    return (dispatch: any, getState: any) => {
        dispatch({
            type: Utils.ActionName.LOADING,
            payload: { loading: true },
        })
        const {
            offset,
            take,
          } = getState().cashoutNewReducer;

        Utils.api.getApiCall(
            Utils.endPoints.storeStock,
            `/getProductsByCategory?order=ASC&offset=${offset}&take=${take}&store=${storeId}&category=80`,
            (respData: any) => {
                dispatch({
                    type: Utils.ActionName.CASHOUT,
                    payload: {
                        storeStocksProduct: respData.data.storeStock,
                    },
                })
                dispatch({
                    type: Utils.ActionName.LOADING,
                    payload: { loading: false },
                })
            },
            (error: any) => {
                dispatch({
                    type: Utils.ActionName.LOADING,
                    payload: { loading: false },
                })
                let { data } = error
                if(data?.message === "" || !data) {
                    Utils.showAlert(2,"Store-Stocks Get Error:")
                }else{
                    Utils.showAlert(2,"Store-Stocks Get Error: " +  data?.message)
                }
            },
        )
    }
}