import Utils from '../../utility'

const initialState = {
    auditData: [],
    storeData: [],
    loading: true,
    storeStocksProduct: [],
    offset: 1,
    take: 10
}
export const cashoutNewReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case `${Utils.ActionName.CASHOUT}`:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return { ...state }
    }
}
