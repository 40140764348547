import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import { getProductsOrder } from "../../pages/return/action";
import { useSelector } from "react-redux";

export default function ReturnBarcodeInput({ onChange, value,name ,filtered}: any) {
  const dispatch: any = useDispatch();
  // const getStoreId = localStorage.getItem("storeId");
  const { storeId: getStoreId } = useSelector((state:any) => state.commonReducer);

  const currentDate = new Date();
  const oneWeekAgo = new Date(currentDate);
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 30);

  const formattedFromDate = oneWeekAgo.toISOString();
  const formattedToDate = currentDate.toISOString();

  return (
    <Box
      sx={{
        maxWidth: "100%",
      }}
    >
      <TextField
        autoFocus={true}
        fullWidth
        label={name==="Return" ? "Search with invoice number" :"Search with name or scan barcode"}
        id="fullWidth"
        onChange={onChange}
        value={value?.toLowerCase()}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === "Enter" && !event.repeat) {
            event.preventDefault()
                 // eslint-disable-next-line
            {name==="Return" &&  dispatch(getProductsOrder(value,getStoreId,formattedFromDate,formattedToDate))} 
          }
        }}
      />
    </Box>
  );
}
