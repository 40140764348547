import { useNavigate, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { onSubmit } from "./action";
import Input from "../../component/inputs/input";
import Logo from './logo.jpeg';

const LoginPage = () => {
  const dispatch: any = useDispatch();
  let navigate = useNavigate();
  const token: any = localStorage.getItem("refreshToken");
  if (token?.length > 10) {
    return <Navigate to="/billing" />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f0f4f8",
      }}
    >
      <Card
        sx={{
          width: "600px", // Adjusted width to be slightly smaller than 700px
          padding: "40px", // Increased padding for a cleaner look
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "15px", // Rounded corners
        }}
      >
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <img
            src={Logo}
            alt="Logo"
            loading="lazy"
            style={{
              width: "70%", // Increase logo size slightly
              marginBottom: "20px",
            }}
          />
        </Box>
        <Formik
          initialValues={{ userId: "", pin: "" }}
          onSubmit={(values) => {
            dispatch(onSubmit(values, navigate));
          }}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <CardContent sx={{ padding: "0" }}>
                <Box sx={{ mb: 3 }}>
                  <Input
                    value={values.userId}
                    name="userId"
                    type="text"
                    error={errors.userId}
                    label="Username"
                    onChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </Box>
                <Box sx={{ mb: 3 }}>
                  <Input
                    value={values.pin}
                    name="pin"
                    type="password"
                    error={errors.pin}
                    label="Password"
                    onChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </Box>
              </CardContent>
              <CardActions sx={{ p: 0 }}>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    width: "100%",
                    backgroundColor: "#fbd140", // Match the button color from the image
                    color: "#000", // Black text
                    borderRadius: "10px", // Rounded corners for the button
                    padding: "10px 0", // Adjust padding for better size
                    fontSize: "16px", // Adjust font size
                    "&:hover": {
                      backgroundColor: "#fbd140", // Same color on hover
                    },
                  }}
                >
                  LOGIN
                </Button>
              </CardActions>
            </Form>
          )}
        </Formik>
      </Card>
    </Box>
  );
};

export default LoginPage;
