import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Input from "../../component/inputs/input";
import Select from "../../component/inputs/select";
import MenuItem from "@mui/material/MenuItem";
import { Formik, Form } from "formik";
import Schema from "../../schema";
import { useDispatch } from "react-redux";
import { updateCashout } from "./action";
import { useSelector } from "react-redux";
import { useState } from "react";
import CashoutPrint from "./CashoutPrint";
import PrintModal from "../../component/modal/PrintModal";

export default function Cashout() {
  const dispatch: any = useDispatch();
  // const getStoreId = localStorage.getItem("storeId")
  const { storeId: getStoreId } = useSelector(
    (state: any) => state.commonReducer
  );
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const {cashPending} = useSelector((state: any) => state.loginReducer);

  const initialValues = {
    amount: "",
    reason: "",
    notes: "",
  };

  const testform = () => {
    setConfirmOpen(true);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema.Cashout}
      onSubmit={(values, { resetForm }) => {
        setIsDisabled(true);
        dispatch(
          updateCashout(
            values.amount,
            values.reason,
            values.notes,
            getStoreId,
            setIsDisabled
          )
        );
        resetForm();
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
      }) => (
        <>
          {confirmOpen === true && (
            <PrintModal
              children={
                confirmOpen && (
                  <>
                    <CashoutPrint />
                  </>
                )
              }
              handleClose={() => {
                setConfirmOpen(false);
              }}
              open={confirmOpen}
            />
          )}
          <Form onSubmit={handleSubmit}>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                height: "50vh",
              }}
            >
              <Card style={{ width: "70%" }} sx={{ mt: 6 }}>
                <CardContent>
                  <Box sx={{ display: "flex", mb: 2 }}>
                    <Input
                      value={values.amount}
                      name="amount"
                      type="number"
                      label="Amount"
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors.amount}
                    />
                  </Box>
                  <Box sx={{ display: "flex", mb: 2 }}>
                    <Select
                      handleChange={handleChange}
                      value={values.reason}
                      label={"Reason"}
                      option={[
                        "Stock Purchase",
                        "Stock Delivery",
                        "Cash Pickup",
                        "Store Maintenance",
                        "Other"
                      ].map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                      name="reason"
                      error={errors.reason}
                    />
                  </Box>
                  <Box sx={{ display: "flex", mb: 2 }}>
                    <Input
                      value={values.notes}
                      name="notes"
                      type="text"
                      label="Notes"
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors.notes}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      disabled={values.amount > cashPending || isDisabled ?true:false}
                      variant="outlined"
                      type="submit"
                      onClick={() => {
                        testform();
                      }}
                    >
                      Done
                    </Button>
                    <Button
                      onClick={() => {
                        resetForm();
                      }}
                      variant="outlined"
                      sx={{ ml: 2 }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}