import Utils from "../../utility"

export const PosDashboard = (fromDate:any,toDate:any) => {
    return (dispatch: any) => {
        dispatch({
            type: Utils.ActionName.LOADING,
            payload: { loading: true },
        })
        Utils.api.getApiCall(
            Utils.endPoints.store,
            `posDashboard?fromDate=${fromDate}&toDate=${toDate}`,
            (respData: any) => {
                dispatch({
                    type: Utils.ActionName.STORE,
                    payload: {
                        salesData: respData.data.sales,
                        returnData: respData.data?.returns,
                        cashSalesData: respData?.data?.cashSales,
                        digitalSalesData: respData?.data?.digitalSales,
                        channelSalesData: respData?.data?.channelsales,
                        loading: false,
                        tnsAppData:respData?.data?.tnsApp,
                        creditData: respData?.data?.credit,
                        returnTotal:respData?.data?.returnTotal
                    },
                })
                dispatch({
                    type: Utils.ActionName.LOADING,
                    payload: { loading: false },
                })
            },
            (error: any) => {
                dispatch({
                    type: Utils.ActionName.LOADING,
                    payload: { loading: false },
                })
                let { data } = error
                if(data?.message === "" || !data) {
                    Utils.showAlert(2,"POS Dashboard Get Error")
                }else{
                    Utils.showAlert(2,"POS Dashboard Get Error: " +  data?.message)
                }
            },
        )
    }
}