// import moment from "moment";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Schema from "../../schema";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { creatOrder, getCustomernamebynumber } from "./action";
import Input from "../../component/inputs/input";
import { Box } from "@mui/material";
import Select from "../../component/inputs/select";
import { useSelector } from "react-redux";
import { useState } from "react";
import { RootState } from "../../Interface/BillerIdInterface";
// import Utils from "../../utility";

const AddUser = ({
  setOpen,
  isEdit,
  open,
  addedProduct,
  totalAndDiscounts,
  setAddedProduct,
  setBarcode,
  setDiscountPercentage,
  setDiscountAmount,
  setCashReturn,
  setCashReceive
}: any) => {
  const dispatch: any = useDispatch();
  const { billerIdData } = useSelector(
    (state: RootState) => state.loginReducer
  );
  // const [paytmValue, setPaytmValue] = useState(true);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  // const [loading, setLoading] = useState(false);

  const initialValues = {
    payType: isEdit?.length ? isEdit[0].userType : "",
    phoneNumber: isEdit?.length ? isEdit[0].name : "",
    customerName: isEdit?.length ? isEdit[0].userName : "",
  };

  const filterProduct = addedProduct?.map((a: any) => a.quantity > 0 && a);

  const lastFinalProduct = filterProduct.filter(Boolean);

  const newhandleChangePhone = (
    e: any,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    if (e.target.value.length === 10) {
      dispatch(getCustomernamebynumber(e.target.value, setFieldValue));
    }
  };

  const { storeId: StoreID } = useSelector((state:any) => state.commonReducer);

  return (
    <>
    <Formik
      initialValues={initialValues}
      validationSchema={Schema.onlineCashCollectPopup}
      onSubmit={(values) => {
        // if (buttonClicked) return;
        dispatch(
          creatOrder(
            values,
            lastFinalProduct,
            totalAndDiscounts,
            setOpen,
            open,
            0,
            setAddedProduct,
            billerIdData,
            setBarcode,
            setIsDisabled,
            setDiscountPercentage,
            setDiscountAmount,
            setButtonClicked,
            StoreID,
            setCashReturn,
            setCashReceive
          )
        );
      }}
    >
      {({
        values,
        errors,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>         
          <Box sx={{ display: "flex", mb: 2 }}>
               <Select
              handleChange={handleChange}
              value={values.payType}
              label={"Pay Type"}
              option={["swiggy", "zomato","ondc"].map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
              name="payType"
              error={errors.payType}
            />
          </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                disabled={
                  open === "credit"
                  //  || loading === true
                    ? isDisabled
                    : buttonClicked
                }
                variant="outlined"
                type="submit"
              >
                Payment collect
              </Button>
              {/* <Button onClick={handleRefundApply}>refund call</Button>
            <Button onClick={handleRefundStatus}>refund status</Button> */}
              <Button
                // disabled={paytmValue}
                variant="outlined"
                sx={{ ml: 2 }}
                // onClick={handleClosePaytm}
              >
              Cancel Payment

                {/* Cancel Paytm Payment */}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddUser;
