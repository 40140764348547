import * as React from "react";
import Box from "@mui/material/Box";
import { Divider,Button, Typography } from "@mui/material";
// import { QRCodeCanvas } from "qrcode.react";
import IconButton from "@mui/material/IconButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import ClearIcon from "@mui/icons-material/Clear";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Utils from "../../utility";
import {
  acceptOrder,
  cancelOrder,
  updateOrderStatus,
  // asignRunner,
  // deleteSingleProduct,
  updateSingleOrderStatus,
  zomatoUpdate,
} from "./action";
import { orderInvoice } from "../billing/action";
import axios from 'axios';
import moment from "moment";
import { Grid } from "@mui/system";

const Index = (props: any) => {
  const {
    id,
    orderDate,
    customerName,
    phoneNumber,
    orderAmountTotal,
    addressLine1,
    addressLine2,
    lat,
    runnerId,
    stateInvoiceNo,
    long,
    totalQuantity,
    orderStatus,
    products,
    token,
    deliveryMode,
    zomato,
    storeName
  } = props?.data;
  const { filterdOrder } = useSelector((state: any) => state.ordersReducer);
  const dispatch: any = useDispatch();
  const [rider, setRider] = React.useState<string>("");
  const [zomatoData, setZomatoData] = React.useState<any>()
  const [totalAmount, setToatalAmount] = React.useState<number>(0);
  const [totalItem, setTotalItem] = React.useState<number>(0);
  // const { runnerList } = useSelector((state: any) => state.ordersReducer);
  const [isLoading, setIsLoading] = React.useState(false);
  // const getStoreId = localStorage.getItem("storeId");
  const { storeId: getStoreId } = useSelector(
    (state: any) => state.commonReducer
  );

  const currentDate = new Date();
  const oneWeekAgo = new Date(currentDate);
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  const formattedFromDate = oneWeekAgo.toISOString();
  const formattedToDate = currentDate.toISOString();

  const handleChangeQuantity = (e: any, i: number) => {
    let data = filterdOrder[props.index];
    let productData = data?.products[i];
    productData.quantity = e.target.value;
    dispatch({
      type: Utils.ActionName.ORDERS,
      payload: {
        filterdOrder: filterdOrder,
      },
    });

    let newCount = 0;
    let newTotal = 0;
    data?.products.forEach((element: any) => {
      newCount = newCount + +element.quantity;
      newTotal = +element.quantity * element.sellingPrice + newTotal;
    });
    setTotalItem(newCount);
    setToatalAmount(newTotal);
  };
  const productChangeStatus = (i: number, status: string) => {
    let data = filterdOrder[props.index];
    let productData = data?.products[i];
    productData.status = status;
    dispatch({
      type: Utils.ActionName.ORDERS,
      payload: {
        filterdOrder: filterdOrder,
      },
    });
    if (
      data?.products?.filter(
        (row: any) => row?.status === "packed" || row?.status === "reject"
      ).length === data?.products.length
    ) {
      dispatch(updateOrderStatus(id, "packed",getStoreId,formattedFromDate,formattedToDate));
    }
  };

  React.useEffect(() => {
    // if(zomato!== null && zomato?.internalOrderID !== undefined && Number(zomato?.externalOrderID)===id){
    //   setIsLoading(true);
    // const postData = {
    //   internal_order_id: zomato?.internalOrderID
    // };

    // const apiUrl = "https://uatservices.thenewshop.in/api/v1/zomato/track";

    // axios.post(apiUrl, postData)
    //   .then(response => {
    //     setIsLoading(false);
    //     if(response.data.status.code===500){
    //       console.error(response.data.errors)
    //       // Utils.showAlert(2, response.data.errors);
    //     }else{
    //      setZomatoData(response.data)
    //     }
    //   })
    //   .catch(error => {
    //     setIsLoading(false);
    //     console.error(error);
    //   });
    // }
    
    if (runnerId) {
      setRider(runnerId);
    }
     // eslint-disable-next-line
  }, [runnerId]);

  // const DeleteProduct = (id:number) =>{
  //   dispatch(deleteSingleProduct(id,getStoreId,formattedFromDate,formattedToDate))
  // }

  const totalQuantityAll = products?.reduce((acc, item) => acc + item.quantity, 0);

  return (
    <>
    {orderStatus!=="delivered" && orderStatus!=="cancelled" && token!=="" && (
      <>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{ flex: 1, m: 1 }}
        >
              <Typography
                            variant="subtitle1"
                            sx={{ flex: 3.2, fontWeight: 600,
                              // fontSize:"16px",
                              color: '#8b8e97', 
  fontFamily: "Poppins, sans-serif",
  lineHeight: "1.75",
  letterSpacing: "0.00938em",
  display: 'inline', // Ensure the paragraph behaves like inline text
  margin: 0,
  paddingRight: '8px', // Space between the text and id
    fontSize:"15px"
                             }}
                          >
                            Store name:{" "}
                          </Typography>
                          <br />
  <Typography variant="body2" sx={{ flex: 1,fontSize:"15px",marginBottom: "5px", display: 'inline-block', fontWeight: "500"}}>
                          {storeName}
                          </Typography>
                          <br />
                          <Typography
                            variant="subtitle1"
                            sx={{ flex: 3.2, fontWeight: 600,
                              // fontSize:"16px",
                              color: '#8b8e97', 
  fontFamily: "Poppins, sans-serif",
  lineHeight: "1.75",
  letterSpacing: "0.00938em",
  display: 'inline', // Ensure the paragraph behaves like inline text
  margin: 0,
  paddingRight: '8px', // Space between the text and id
    fontSize:"15px"
                             }}
                          >
                              Order No:{" "}
                          </Typography>
                          <br />
  <Typography variant="body2" sx={{ flex: 1,fontSize:"15px",marginBottom: "5px", display: 'inline-block', fontWeight: "500"}}>
  {id}
                          </Typography>
                          <br />
                          <Typography
                            variant="subtitle1"
                            sx={{ flex: 3.2, fontWeight: 600,
                              // fontSize:"16px",
                              color: '#8b8e97', 
  fontFamily: "Poppins, sans-serif",
  lineHeight: "1.75",
  letterSpacing: "0.00938em",
  display: 'inline', // Ensure the paragraph behaves like inline text
  margin: 0,
  paddingRight: '8px', // Space between the text and id
    fontSize:"15px"
                             }}
                          >
  Order Time:
  </Typography>
  <br />
  <Typography variant="body2" sx={{ flex: 1,fontSize:"15px",marginBottom: "5px", display: 'inline-block', fontWeight: "500"}}>
  {moment(orderDate).format('YYYY-MM-DD || hh:mm:ss A')}
                          </Typography>
                          <br />
                          <Typography
                            variant="subtitle1"
                            sx={{ flex: 3.2, fontWeight: 600,
                              // fontSize:"16px",
                              color: '#8b8e97', 
  fontFamily: "Poppins, sans-serif",
  lineHeight: "1.75",
  letterSpacing: "0.00938em",
  display: 'inline', // Ensure the paragraph behaves like inline text
  margin: 0,
  paddingRight: '8px', // Space between the text and id
    fontSize:"15px"
                             }}
                          >
  Customer Name:
  </Typography>
  <br />
  <Typography variant="body2" sx={{ flex: 1,fontSize:"15px",marginBottom: "5px", display: 'inline-block', fontWeight: "500"}}>
  {customerName}
                          </Typography>
                          <br />
                          <Typography
                            variant="subtitle1"
                            sx={{ flex: 3.2, fontWeight: 600,
                              // fontSize:"16px",
                              color: '#8b8e97', 
  fontFamily: "Poppins, sans-serif",
  lineHeight: "1.75",
  letterSpacing: "0.00938em",
  display: 'inline', // Ensure the paragraph behaves like inline text
  margin: 0,
  paddingRight: '8px', // Space between the text and id
    fontSize:"15px"
                             }}
                          >
  Customer No:
  </Typography>
  <br />
  <Typography variant="body2" sx={{ flex: 1,fontSize:"15px",marginBottom: "5px", display: 'inline-block', fontWeight: "500"}}>
  {phoneNumber}
                          </Typography>
                          <br />
          {deliveryMode!=="self" ? (
            <>
          <Typography
                            variant="subtitle1"
                            sx={{ flex: 3.2, fontWeight: 600,
                              // fontSize:"16px",
                              color: '#8b8e97', 
  fontFamily: "Poppins, sans-serif",
  lineHeight: "1.75",
  letterSpacing: "0.00938em",
  display: 'inline', // Ensure the paragraph behaves like inline text
  margin: 0,
  paddingRight: '8px', // Space between the text and id
    fontSize:"15px"
                             }}>
            
            Customer Address:
           
          </Typography>
          <br />
          <Typography variant="body2" sx={{ flex: 1,fontSize:"15px",marginBottom: "5px", display: 'inline-block', fontWeight: "500"}}>
          {`${addressLine1},  ${addressLine2}`}
            </Typography>
            </>)
           : (
            <>
              <Typography
                            variant="subtitle1"
                            sx={{ flex: 3.2, fontWeight: 600,
                              // fontSize:"16px",
                              color: '#8b8e97', 
  fontFamily: "Poppins, sans-serif",
  lineHeight: "1.75",
  letterSpacing: "0.00938em",
  display: 'inline', // Ensure the paragraph behaves like inline text
  margin: 0,
  paddingRight: '8px', // Space between the text and id
    fontSize:"15px"
                             }}>
           Customer Address:
            </Typography>
              <br />
              <Typography variant="body2" sx={{ flex: 1,fontSize:"15px",marginBottom: "5px", display: 'inline-block', fontWeight: "500"}}>
              Store Pickup       
               </Typography>
               </>
          )}
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box
          sx={{
            flex: 2,
            m: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: 3 }}>
            {products?.length &&
              products?.map((item: any, i: number) => (
                <>
       <Box
  sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
  key={i}
  style={item?.status === "packed" ? { paddingRight: 40 } : {}}
>
                          <Grid style={{display: "flex", width: "100%"}}>
                          <Typography
                            variant="body2"
                            sx={{ flex: 3.2, fontWeight: 600,fontSize:"16px" }}
                          >
                            {item?.name}{" "}
                            <span
                              style={{ fontSize: "13px",color:"#8b8e97" }}
                            >{`${item?.barcode}:-`}</span>
                          </Typography>
                          <Typography variant="body2" sx={{ flex: 1,fontSize:"15px" }}>
                            {" "}
                            {`${item?.quantity} * ${item.sellingPrice} =  ${
                              item?.quantity * item.sellingPrice
                            }`}
                          </Typography>
                        </Grid>
</Box>
                <Divider />
            </>
              ))}
                              <Divider />
                              <Grid style={{display: "flex", width: "100%",marginTop:"1.875rem"}}>
                  <Typography variant="body2" sx={{ flex: 3.2,fontSize:"16px" }}>
                    Total quantity :-
                  </Typography>
                  <Typography variant="body2" sx={{ flex: 1, fontWeight: 500,fontSize:"15px" }}>
                  {totalQuantityAll}
                  </Typography>
                  </Grid>
                              {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1,fontSize: "15px" }}>
  <span>Total Quantity :-</span>
  <span style={{ marginRight: '20px',fontWeight:"bold"}}>{totalQuantityAll}</span>
</Box> */}
                                <Divider />
            {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1,fontSize: "15px" }}>
  <span>Total :-</span>
  <span style={{ marginRight: '20px',fontWeight:"bold"}}>{totalAmount ? Math.round(totalAmount) : Math.round(orderAmountTotal)}</span>
</Box> */}
 <Grid style={{display: "flex", width: "100%"}}>
                  <Typography variant="body2" sx={{ flex: 3.2 ,fontSize:"16px" }}>
                    Total :-
                  </Typography>
                  <Typography variant="body2" sx={{ flex: 1, fontWeight: 500 ,fontSize:"15px"}}>
                   {totalAmount ? Math.round(totalAmount) : Math.round(orderAmountTotal)}
                  </Typography>
                </Grid>

            {/* <Button
              variant="outlined"
              sx={{ width: 200 }}
              disabled={orderStatus !== "placed"}
              onClick={() => props.setOpen(id)}
            >
              Add Item
            </Button> */}
          </Box>
          <Divider orientation="vertical" flexItem />
        </Box>
        <Box sx={{ flex: 1, m: 1 }}>
          <Box
            style={
              orderStatus === "placed"
                ? { backgroundColor: "yellow" }
                : orderStatus === "packed"
                ? { backgroundColor: "lightblue" }
                : orderStatus === "out for delivery"
                ? { background: "lightgreen" }
                : orderStatus === "delivered"
                ? { background: "lightgreen" }
                : { background: "lightred" }
            }
            sx={{ p: 2, textAlign: "center" }}
          >
            <b>Status: {orderStatus}</b>
          </Box>
          {orderStatus === "packed" || deliveryMode === "self" ? (
  <Button
    variant="outlined"
    sx={{ width: "100%", marginTop: "20px"}}
    onClick={() => {
        dispatch(updateSingleOrderStatus(id, "delivered",getStoreId,formattedFromDate,formattedToDate));
    }}
    disabled={orderStatus!=="packed"}
  >
   Mark as Delivered
   {/* {orderStatus !== "out for delivery" && orderStatus !== "packed" && deliveryMode !== "self" ? "Pick up" : "Mark as Delivered"}  */}
  </Button>
) : null}
{/* <Button
  variant="outlined"
  sx={{
    width: "100%",
    marginTop: "20px",
    borderColor: "#d32f2f", // Sets the border color to red
    color: "red", // Sets the text color to red
    '&:hover': {
      borderColor: "#d32f2f", // Optional: Darker red color on hover
      color: "#d32f2f", // Optional: Darker red color on hover
    }
  }}
  onClick={() => {
    dispatch(cancelOrder(id))
  }}
>
  Cancel Order
</Button>
  <Button
  variant="outlined"
  sx={{
    width: "100%",
    marginTop: "20px",
    borderColor: "#2e7d32", // Sets the border color to red
    color: "#2e7d32", // Sets the text color to red
    '&:hover': {
      borderColor: "#2e7d32", // Optional: Darker red color on hover
      color: "#2e7d32", // Optional: Darker red color on hover
    }
  }}
  onClick={() => {
      dispatch(acceptOrder(id, "delivered"));
  }}
>
 Accept Order
</Button> */}
{orderStatus === 'placed' && (
        <>
          <Button
            variant="outlined"
            sx={{
              width: "100%",
              marginTop: "20px",
              borderColor: "#d32f2f", // Sets the border color to red
              color: "red", // Sets the text color to red
              '&:hover': {
                borderColor: "#d32f2f", // Darker red color on hover
                color: "#d32f2f", // Darker red color on hover
              }
            }}
            onClick={() => {
              dispatch(cancelOrder(id));
            }}
          >
            Cancel Order
          </Button>

          <Button
            variant="outlined"
            sx={{
              width: "100%",
              marginTop: "20px",
              borderColor: "#2e7d32", // Sets the border color to green
              color: "#2e7d32", // Sets the text color to green
              '&:hover': {
                borderColor: "#2e7d32", // Darker green color on hover
                color: "#2e7d32", // Darker green color on hover
              }
            }}
            onClick={() => {
              dispatch(acceptOrder(id, "packed"));
            }}
          >
            Accept Order
          </Button>
        </>
       )} 

      {orderStatus === 'packed' && (
        <Button
          variant="outlined"
          sx={{
            width: "100%",
            marginTop: "20px",
            borderColor: "#d32f2f", // Sets the border color to red
            color: "red", // Sets the text color to red
            '&:hover': {
              borderColor: "#d32f2f", // Darker red color on hover
              color: "#d32f2f", // Darker red color on hover
            }
          }}
          onClick={() => {
            dispatch(cancelOrder(id));
          }}
        >
          Cancel Order
        </Button>
       )} 

  {orderStatus!=="delivered" && orderStatus!=="cancelled" && lat && token!=="" && (
        <Button
          variant="outlined"
          sx={{ width: "100%",marginTop: "20px"}}
        >
          <a target="_blank" style={{ textDecoration: 'none' }}  rel="noreferrer" href={`https://maps.google.com/?q=${lat},${long}`}>
            Open map
          </a>
        </Button>
      )}
        <Button
              variant="outlined"
              sx={{ width: "100%", marginTop: "20px" }}
              onClick={() => dispatch(orderInvoice(stateInvoiceNo))}
            >
              Print Invoice
            </Button>
        </Box>
      </Box>
      </>
           )} 
    </>
  );
};

export default Index;
