const endPoint = {
  shift:"shift/create",
  shiftClose:"shift/close",
  shiftDetails:"shift/",
  login: "login",
  refreshLogin:"refresh-token",
  logout:"logout",
  forgotPassword: "user/forget-password",
  resetPassword: "user/reset-password",
  user: "users",
  createUser: "users/create",
  addPropertyOwner: "user/property-owner/create",
  updatePropertyOwner: "user/property-owner/",
  brands: "brands",
  propertyOwner: "user/property-owner",
  store: "store/",
  createBrand: "brands/create",
  updateBrand: "/brands/",
  createStore: "store/create",
  warehouse: "warehouse/",
  warehouseCreate: "warehouse/create",
  products: "products/",
  createProducts: "products/create",
  category: "category/",
  createCategory: "category/create",
  fileUpload: "files/upload",
  createBrandManager: "user/brand-manager/create",
  brandOwner: "user/brand-manager",
  oprationManager: "user/operations-manager",
  createOprationManager: "user/operations-manager/create",
  biller: "user/biller",
  createBiller: "user/biller/create",
  warehouseStock: "warehouse-stocks",
  createWarehouseStock: "warehouse-stocks/create",
  warehouseById: "warehouse-stocks/warehouse/",
  storeStock: "store-stocks",
  createStoreStock: "store-stocks/create",
  storeStockInvoice: "store-stocks/invoice/",
  customers: "customers/",
  customerPin: "customers/customerByPin",
  storeGRN: "GRN/store/",
  warehouseGRN: "GRN/warehouse/",
  GRN: "GRN/",
  createGRN: "GRN/create",
  SKUTimeline: "sku-timelines",
  createW2S: "w2s-stocks/create",
  createW2W: "w2w-stocks/create",
  createS2S: "s2s-stocks/create",
  deleteGrn: "GRN/delete/",
  rtvStoreStock: "rtvstore-stocks",
  rtvStoreStockCreate:"rtvstore-stocks/create",
  orderCreate: "orders/create",
  orderReturn: "orderReturn/",
  getOrderById: "orders/list/",
  orders: "orders",
  shiftStoreDetails:"shift/shift-reports/",
  createOrderReturn: "orderReturn/create/",
  customerAddress: "customers/cAddress",
  runnerAsign: "orders/runner/",
  orderStatusUpdate: "orders/status/",
  orderInvoice: "orders/invoice/",
  returnInvoice:"orderReturn/pdf/",
  orderInvoicePdf: "orders/invoice/pdf",
  rtvInvoice:"rtvstore-stocks/pdf/",
  cashOut:"cashOut",
  deleteOrderProduct:"orders/deleteOrderProduct/",
  customerName:"customers/",
  credit:"credit",
  cancelOrder: "/orderReturn/cancel-order/",
  acceptOrder: "/orders/status/"
};

export default endPoint;
