import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import Input from "../../component/inputs/input";
import { Box } from "@mui/material";
import { creatOrderReturn } from "./action";
import { BillingReturn } from "../../Interface/BillingReturnInterface";
import Schema from "../../schema";
import { useState } from "react";

const AddUser = ({ addedProduct,name,setBarcode,setAddedProduct,setOpen,openName }: any) => {
  const dispatch:any = useDispatch();
  const { products } = useSelector((state: any) => state.returnReducer);
  const [isDisabled, setIsDisabled] = useState(false);
  // const getStoreId = localStorage.getItem("storeId")
  const { storeId: getStoreId } = useSelector(
    (state: any) => state.commonReducer
  );
  const initialValues = {
    invoiceNumber: "",
    reason:""
  };

  var OrderId:number;

  products.map(((a:BillingReturn)=> OrderId = a?.id))
  
  const filterProduct = addedProduct?.map((a:any)=>a.quantity>0&&a)

  const lastFinalProduct = filterProduct.filter(Boolean)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema.offlineCashCollectReturnPopup}
      onSubmit={(values) => {
        setIsDisabled(true);
        dispatch(creatOrderReturn(values, lastFinalProduct, OrderId,getStoreId,setBarcode,setAddedProduct,setOpen,setIsDisabled,openName));
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm
      }) => (
        <Form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Input
              value={values.invoiceNumber}
              name="invoiceNumber"
              type="text"
              error={errors.invoiceNumber}
              label="Invoice Number"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
            </Box>
            <Box sx={{ display: "flex", mb: 2 }}>
             <Input
              value={values.reason}
              name="reason"
              type="text"
              error={errors.reason}
              label="Reason"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button variant="outlined" type="submit" disabled={isDisabled}>
             {name==="Return" ? "Return" : "Payment collect"}
            </Button>
            <Button onClick={() => {resetForm()}} variant="outlined" sx={{ ml: 2 }}>
            Cancel
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddUser;
