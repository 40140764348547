import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Interface/BillerIdInterface";
import { getProductsBackendSearch } from "../../pages/billing/action";
import Utils from "../../utility";

type DiscardBarcodeInputProps = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  name?: string;
  filtered?: any[];
};

export default function DiscardBarcodeInput({
  onChange,
  value = "",
  name = "",
  filtered = [],
}: DiscardBarcodeInputProps) {
  const dispatch: any = useDispatch();
  const { billerIdData } = useSelector((state: RootState) => state.loginReducer);

  // Check if the item is already added in filtered list
  const isAlreadyAdded = filtered?.some(
    (item: any) => item.barcode?.toLowerCase() === value.toLowerCase() || item.name?.toLowerCase().includes(value.toLowerCase())
  );

  return (
    <Box
      sx={{
        maxWidth: "100%",
      }}
    >
      <TextField
        autoFocus
        fullWidth
        label={
          name === "Return" ? "Search with invoice number" : "Search with name or scan barcode"
        }
        id="fullWidth"
        onChange={onChange}
        value={value.toLowerCase()}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === "Enter" && !event.repeat) {
            event.preventDefault();

            // Prevent duplicate product from being searched/added
            if (isAlreadyAdded) {
              // Utils.showAlert(2, "Product is already added");
              return;
            }

            if (name === "Discard" && value.length >= 1) {
              // Handle discard search
              if (filtered.map((ele: any) => ele.barcode).includes(value)) {
                Utils.showAlert(2, "Product is already in the list");
              } else if (billerIdData) {
                dispatch(getProductsBackendSearch(billerIdData, value));
              } else {
                Utils.showAlert(2, "Biller ID not found");
              }
            }
          }
        }}
        autoComplete="off"
      />
    </Box>
  );
}
