import { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { creatOrderForPayment, getBillerId, getRoyalityData } from '../../pages/login/action';
import TransitionsModal from '../modal';
import { Table, TableHead, TableBody, TableRow, TableCell, Button } from '@mui/material';
import moment from 'moment';
import Sidebar from '../header/index';
import ShiftStart from '../header/ShiftStart';
import useWebSocket from './useWebSocket';

const Index = () => {
  const dispatch = useDispatch();
  const { royalityData } = useSelector((state) => state.loginReducer);
  const { userId } = useSelector((state) => state.commonReducer);
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(true);
  const [collapsed, setCollapsed] = useState(true);
  const userName = localStorage.getItem('username');
  useWebSocket();
  
  useEffect(() => {
    dispatch({
      type: 'COMMON',
      payload: {
        userId: localStorage.getItem('id'),
        storeId: localStorage.getItem('storeId'),
        userType: localStorage.getItem('userType'),
        username: localStorage.getItem('username'),
        email: localStorage.getItem('email'),
      },
    });
  }, [dispatch]);

  const {ongoingShiftId} = useSelector((state)=> state.loginReducer)
  const shiftGet = localStorage.getItem("shiftId")
  useEffect(()=>{
   if(!userName){
    navigate("/")
   }
  },[userName,navigate])

  useEffect(() => {
    if (userId) dispatch(getBillerId(userId,navigate));
  }, [userId, dispatch,navigate]);

  const initializeRazorpay = () => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const makePayment = async (amount) => {
    const res = await initializeRazorpay();

    if (!res) {
      alert('Razorpay SDK Failed to load');
      return;
    }

    dispatch(
      creatOrderForPayment(
        {
          invoiceNumber: 'invoice_20',
          amount: amount,
          storeId: localStorage.getItem('storeId'),
          notes: 'string',
        },
        (res) => {
          const options = {
            key: 'rzp_test_hNVoPt48fZga5Q',
            amount: res?.amount,
            currency: 'INR',
            name: 'NEW SHOP CSTORE NETWORK PRIVATE LIMITED',
            description: 'Test Transaction',
            image: 'https://thenewshop.in/images/favicon.png',
            order_id: res?.id,
            handler: async function (response) {
              if (response.razorpay_order_id)
                dispatch(getRoyalityData(localStorage.getItem('storeId'), navigate));
              // const data = {
              //   orderCreationId: res?.id,
              //   razorpayPaymentId: response.razorpay_payment_id,
              //   razorpayOrderId: response.razorpay_order_id,
              //   razorpaySignature: response.razorpay_signature,
              // };
            },
            prefill: {
              email: 'productxventures@gmail.com',
              contact: '9650857683',
            },
            notes: {
              address: '',
            },
            theme: {
              color: '#0000',
            },
            method: {
              netbanking: true,
              card: true,
              upi: true,
            },
            config: {
              display: {
                hide: [
                  { method: 'cardless_emi' },
                  { method: 'paylater' },
                  { method: 'wallet' },
                ],
              },
            },
          };

          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        }
      )
    );
  };

  const isRoyaltyFinalDatePassed = (royaltyFinalDate) => {
    const currentDate = moment();
    const finalDate = moment(royaltyFinalDate);
    return currentDate.isAfter(finalDate);
  };

  const handleModalClose = () => {
    if (isRoyaltyFinalDatePassed(royalityData[0]?.royaltyFinalDate)) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  };

  const handleSidebarToggle = () => {
    setCollapsed(!collapsed);
  };

  const [openShiftModal, setOpenShiftModal] = useState(true);

  const handleShiftModalClose = () => {
    if(shiftGet!==undefined && shiftGet!=="undefined" ){
      setOpenShiftModal(false);
    }else{
      setOpenShiftModal(true);
    }
  };

  const {ongoingShiftBillerId,previousShiftId,loading} = useSelector((state)=>state.loginReducer);
  
  return (
    <>
    {/* {shiftGet!==undefined && shiftGet===null && previousShiftId!=="null" && ( */}
{loading ? (
        <></>
      ) : (
        !ongoingShiftId &&
        !previousShiftId && (
          <TransitionsModal
            header="Shift"
            children={
              openShiftModal && (
                <ShiftStart onShiftCreated={handleShiftModalClose} />
              )
            }
            open={openShiftModal}
            shiftStart={true}
          />
        )
      )}
{loading ? (
  <></>
) : (
!ongoingShiftId && (
  <TransitionsModal
  header="Shift"
  children={
    openShiftModal && (
    <ShiftStart onShiftCreated={handleShiftModalClose} />
    )
  }
  open={openShiftModal}
  shiftStart={true}
/>)
)}
{loading ? (
  <></>
) : (
ongoingShiftId && ongoingShiftBillerId != userId && (
   <TransitionsModal
   header="Shift"
   children={
     openShiftModal && (
     <ShiftStart onShiftCreated={handleShiftModalClose} />
     )
   }
   open={openShiftModal}
   shiftStart={true}
 />)
) }
      {royalityData.length > 0 && (
        <TransitionsModal
          header="Royalty Pending"
          children={
            openModal && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Store Name</TableCell>
                    <TableCell>Royalty Pending</TableCell>
                    <TableCell>Month / Year</TableCell>
                    <TableCell>Invoice</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {royalityData.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.storename}</TableCell>
                      <TableCell>{row.royaltyPayable}</TableCell>
                      <TableCell>{row.month_mmm} / {row.year}</TableCell>
                      <TableCell>
                        <a href={row.invoice} target="_blank" rel="noopener noreferrer">
                          View Invoice
                        </a>
                      </TableCell>
                      <TableCell>
                        <Button variant="outlined" onClick={() => makePayment(row?.royaltyPayable)}>Pay now</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )
          }
          handleClose={handleModalClose}
          open={openModal}
        />
      )}
      <Box display="flex">
        <Sidebar onToggle={handleSidebarToggle} collapsed={collapsed} />
        <Box
          flex={1}
          style={{ marginTop: '10px', transition: 'margin-left 0.3s' }}
          ml={collapsed ? '160px' : '300px'} // Adjust margin based on sidebar state
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default Index;
