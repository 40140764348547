import Login from "../pages/login";
import { HashRouter as Routera, Routes, Route } from "react-router-dom";
import MainLayout from "../component/hoc/pageContainer";
import Billing from "../pages/billing";
import Return from "../pages/return";
import RTVProducts from "../pages/rtvProducts";
import DiscordProducts from "../pages/discardProduct";
import OnlineOrder from "../pages/onlineOrder";
import DeliveryDetailPage from "../pages/onlineOrder/deliveryDetailPage";
import Dashboard from "../pages/dashboard/Dashboard";
import Cashout from "../pages/cashout/Cashout";
import Audit from "../pages/audit/Audit";
import ErrorPage from "../utility/ErrorPage";
// import Customer from "../pages/customers/Customer";
import { useEffect } from "react";
import CreditTab from "../pages/credit/CreditTab";
import ShiftDetails from "../component/header/ShiftDetails";

const RedirectToHTTPS = () => {
    const currentHostname = window.location.hostname;

    if (currentHostname === 'localhost' || window.location.href === 'https://uatpos.thenewshop.in/') {
      return;
    }
    else if(window.location.href==="http://pos.thenewshop.in/" || window.location.protocol === 'http:'){
       const httpsURL = window.location.href.replace('http:', 'https:');
       window.location.href = httpsURL;
    } else if(window.location.href==="http://uatpos.thenewshop.in/" || window.location.protocol === 'http:'){
       const httpsURL = window.location.href.replace('http:', 'https:');
       window.location.href = httpsURL;
    } else {
      return;
    }
  return null;
};

export default function Router() {
  useEffect(()=>{
    RedirectToHTTPS()
  },[])

  return (
    <Routera>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/" element={<MainLayout />}>
          <Route path="billing" element={<Billing />} />
          <Route path="shift" element={<ShiftDetails />} />
          <Route path="return" element={<Return />} />
          <Route path="dashboard" element={<Dashboard />} />
          {/* <Route path="customers" element={<Customer />} /> */}
          <Route path="credit" element={<CreditTab />} />  
          <Route path="rtv" element={<RTVProducts />} />
          <Route path="discard" element={<DiscordProducts />} />
          <Route path="online-order" element={<OnlineOrder />} />
          <Route path="cashout" element={<Cashout />} />
          <Route path="audit" element={<Audit />} />
          <Route path="detail-page-for-delivery">
          <Route
            path=":id"
            element={<DeliveryDetailPage />}
          />
          </Route>
          {/* build and check in production without this but change in online orders qr page scan */}
          <Route
            path=":id"
            element={<DeliveryDetailPage />}
          />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </Routera>
  );
}
