import * as React from "react";
import Box from "@mui/material/Box";
import BarcodeInput from "../../component/inputs/barcodeInput";
import ItemCard from "../../component/card/productCard";
import Button from "@mui/material/Button";
import {makeStyles } from "@mui/styles";
import { addNewItemInOrder } from "./action";
import { useDispatch } from "react-redux";
import {  getProductsBackendSearch } from "../billing/action";
import { useSelector } from "react-redux";
import Utils from "../../utility";
import { RootState } from "../../Interface/BillerIdInterface";

const useStyles = makeStyles(() => ({
  addButton: {
    background: "black !important",
    color: "white  !important",
    padding: "6px 35px !important",
    "&:hover": {
      background: "black !important",
    },
  },
  cashCollectMethod: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 25px",
    marginRight: "1px !important",
    cursor: "pointer",
  },
  amount: {
    width: 60,
    "&>input": {
      padding: 10,
      boxSizing: "border-box",
      width: "100%",
    },
  },
}));

export default function Index(props: any) {
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const [barcode, setBarcode] = React.useState("");
  const { products } = useSelector((state: any) => state.productsReducer);
  const [addedProduct, setAddedProduct]: any = React.useState([]);
  const [grandTotal, setGrandTotal] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const { ordersList } = useSelector((state: any) => state.ordersReducer);
  const [discounPersentage, setDiscountPercentage]: any = React.useState();
  const [discounAmount, setDiscountAmount]: any = React.useState();
  const {billerIdData} = useSelector((state: RootState) => state.loginReducer);

  const handleChangeBarcode = (e: any) => {
    const val = e.target.value;
    setBarcode(e.target.value);
    dispatch(getProductsBackendSearch(billerIdData,e.target.value))

    const filteredNewArray: any = [];
    if (ordersList?.length) {
      const orderLists = ordersList?.filter(
        (item: any) => item.id === props.orderId
      );
      const isProductAlreadyExist = orderLists[0]?.actualBillingSent.filter(
        (item: any) => item.product.barcode === val
      );

      if (isProductAlreadyExist?.length) {
        Utils.showAlert(2, "Product Already Exist in order");
        return;
      }
    }
    if (e.target.value.length > 0) {
      // eslint-disable-next-line
      const filteredProduct = products.filter((item: any) => {
        if (item?.product?.barcode === e.target.value || item?.product?.barcode?.includes(e.target.value)===true || item?.product?.name.toLowerCase().replace(/ +/g, "") === e.target.value.toLowerCase().replace(/ +/g, "")|| item?.product?.name.toLowerCase().replace(/ +/g, "")?.includes(e.target.value)===true ) {
          const newObj = {
            id: item.product?.id,
            name: item.product.name,
            quantity: 0,
            printedMrp: item.printedMrp,
            sellingPrice: item.sellingPrice,
            productCostPrice: item.productCostPrice,
            expiryDate: item.expiryDate,
          };
          filteredNewArray.push(newObj);
          // return newObj;
        }
        // return filteredNewArray
      });
      if (!filteredProduct.length) {
        return;
      }
      setAddedProduct([...addedProduct,...filteredNewArray]);
    }
  };

  React.useEffect(() => {
    let filteredNewArray: any = [];
    if (barcode?.length > 0) {
      // eslint-disable-next-line
      const filteredProduct = products.filter((item: any) => {
         if( item?.product?.barcode === barcode ||  item?.product?.barcode?.includes(barcode) === true ||
        item?.product?.name.toLowerCase().replace(/ +/g, "") ===
        barcode?.toLowerCase().replace(/ +/g, "") ||
        item?.product?.name
          .toLowerCase()
          .replace(/ +/g, "")
          ?.includes(barcode) === true){
            const newObj = {
              id: item.product?.id,
              name: item.product.name,
              quantity: 0,
              barcode: item?.product?.barcode,
              printedMrp: item.printedMrp,
              sellingPrice: item.sellingPrice,
              productCostPrice: item.productCostPrice,
              expiryDate: item.expiryDate,
              weight: item?.product?.weight,
            };
            filteredNewArray = [...filteredNewArray, newObj];
            return newObj;
          }
          // return filteredNewArray
        });
        if (!filteredProduct.length) {
          return;
        }
        setAddedProduct([...addedProduct, ...filteredNewArray]);
    }
    // eslint-disable-next-line
  }, [products]);

  const increaseQuantity = (key: any) => {
    const updateProduct = addedProduct.map((item: any) => {
      if (item.id === key) {
        const newArray = { ...item, quantity: ++item.quantity };
        setBarcode("")
        return newArray;
      } else return item;
    });
    setAddedProduct([...updateProduct]);
  };

  const decreaseQuantity = (key: any) => {
    const updateProduct = addedProduct
      .map((item: any) => {
        if (item.id === key) {
          if (item.quantity === 1) {
            return null;
          }
          const newArray = { ...item, quantity: --item.quantity };
          return newArray;
        } else return item;
      })
      .filter((item: any) => item);
    setAddedProduct([...updateProduct]);
  };

  React.useEffect(() => {
    let total: number = 0;
    addedProduct.forEach((singleTotal: any) => {
      total = total + +singleTotal.printedMrp * singleTotal.quantity;
    });

    if (discounAmount) {
      setGrandTotal(total - (total * discounPersentage) / 100);
      setDiscountAmount((total * discounPersentage) / 100);
      // setGrandTotal(total - discounAmount);
    } else {
      setGrandTotal(total);
    }
    setTotal(total);
       // eslint-disable-next-line
  }, [addedProduct]);
  
  //unused states
  console.log(total)
  console.log(setDiscountPercentage)

  const arr = addedProduct;

  const ids = arr?.map((o:any) => o.id);
  const filtered = arr?.filter(
    ({ id }:any, index:any) => !ids?.includes(id, index + 1)
  );

  var debug = filtered?.filter(
    (x:any) =>
      x?.name?.toLowerCase()?.replace(/ +/g, "")?.includes(barcode) ||
      x["barcode"]?.includes(barcode)
  );

  return (
    <Box>
      <Box>
        <Box sx={{ width: "65%" }}>
          <BarcodeInput value={barcode} onChange={handleChangeBarcode} />
        </Box>
        <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", width: "65%" }}>
          {debug.length ? (
            <>
              {debug.map((card: any) => (
                <ItemCard
                  key={card.index}
                  productName={card.name}
                  quantity={card.quantity}
                  price={card.printedMrp}
                  increaseQuantity={() => increaseQuantity(card.id)}
                  decreaseQuantity={() => decreaseQuantity(card.id)}
                />
              ))}
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Button
          onClick={() =>
            dispatch(
              addNewItemInOrder(
                {
                  orderId: props.orderId,
                  products: filtered,
                  orderStatus: "approve",
                  orderAmountTotal: grandTotal,
                  discountAmount: discounPersentage,
                  discountPercent: discounAmount,
                },
                props.setOpen
              )
            )
          }
          className={classes.addButton}
        >
          Add Item
        </Button>
      </Box>
    </Box>
  );
}
