import { BillingReturn } from '../Interface/BillingReturnInterface';

function IncrementFunction(key: number,addedProduct:any,setAddedProduct:any) {
         const updateProduct = addedProduct.map((item: BillingReturn) => {
          if (item.itemId === key ) {
            // && item.quantity+1<=item.totalQuantity
            const newArray = { ...item, quantity: ++item.quantity };
            return newArray;
          } else{
            //  Utils.showAlert(2, "Total Quantity Exceeds")
            return item;
          } 
        });
     return setAddedProduct([...updateProduct]);
}

export default IncrementFunction