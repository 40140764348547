import React, { useState } from 'react';
import { Container, Typography, Button } from '@mui/material';
import './CustomerDetails.css'; // Import the CSS file
import { useDispatch, useSelector } from 'react-redux';
import { updateCustomerDetails } from './action'; // Import your action

const CustomerDetailsForm = ({ customerName, customerNumber, customerCompany, customerGst,setOpen,setCustomerOpen }) => {
  // Initialize state with the incoming props
  const [formData, setFormData] = useState({
    name: customerName || '',
    number: customerNumber || '',
    company: customerCompany || '',
    gst: customerGst || '',
    address: ''
  });
  const dispatch:any = useDispatch();

  // Handle input changes
  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateCustomerDetails(formData)); // Dispatch action with formData
    setOpen("")
    setCustomerOpen(false)
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        backgroundColor: '#fff',
        padding: '30px',
        borderRadius: '10px',
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
      }}
    >
      <Typography
        variant="h5"
        style={{
          marginBottom: '20px',
          fontWeight: 'bold',
        }}
      >
        Customer Details
      </Typography>
      <form noValidate autoComplete="off" className="customer-form">
        <div className="form-group">
          <label htmlFor="name">Customer Name: </label>
          <input
            id="name"
            type="text"
            name="name"
            className="dotted-border"
            value={formData.name}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="number">Phone Number: </label>
          <input
            id="number"
            type="text"
            name="number"
            className="dotted-border"
            value={formData.number}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="company">Company Name: </label>
          <input
            id="company"
            type="text"
            name="company"
            className="dotted-border"
            value={formData.company}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="gst">GST Number: </label>
          <input
            id="gst"
            type="text"
            name="gst"
            className="dotted-border"
            value={formData.gst}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Address: </label>
          <input
            id="address"
            type="text"
            name="address"
            className="dotted-border"
            value={formData.address}
            onChange={handleInputChange}
          />
        </div>
        <Button
          variant="contained"
          style={{
            marginTop: '30px',
            backgroundColor: '#121212',
            color: '#fff',
            borderRadius: '15px',
            padding: '10px 30px',
            width: '200px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          onClick={handleSubmit} // Call handleSubmit directly
        >
          UPDATE
        </Button>
      </form>
    </Container>
  );
};

export default CustomerDetailsForm;
