import * as React from "react";
import Box from "@mui/material/Box";
import ItemCard from "../../component/card/productCard";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import Modal from "../../component/modal";
import Cash from "./onlinePopup";
import Ofline from "./oflinePopup";
import { useSelector } from "react-redux";
import { BillingReturn } from "../../Interface/BillingReturnInterface";
import IncrementFunction from "../../utility/IncrementFunction";
import DecrementFunction from "../../utility/DecrementFunction";
import { useBillingStyles } from "../pagesUtils/useBillingStyles";
import ReturnBarcodeInput from "../../component/inputs/ReturnBarcodeInput";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

export default function Index() {
  const classes = useBillingStyles();
  const [barcode, setBarcode] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [isOnline, setIsonline] = React.useState(false);
  const { products } = useSelector((state: any) => state.returnReducer);
  const [addedProduct, setAddedProduct] = React.useState<BillingReturn[]>([]);
  const [grandTotal, setGrandTotal] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [cashReceive, setCashReceive] = React.useState();
  const [cashReturn, setCashReturn] = React.useState<string | number>();
  const [discounPersentage, setDiscountPercentage] = React.useState<number>(0);
  const [discounAmount, setDiscountAmount] = React.useState<number>(0);
  const [name,setName] = React.useState("")

  const handleChangeBarcode = (e: any) => {
    const val = e.target.value;
    setBarcode(e.target.value);
    let filteredNewArray: any = [];
    if (addedProduct?.length) {
      const isAlreadyAdded = addedProduct.filter(
        (item: any) => item.invoiceNo.toLowerCase() !== val
      );
      if (isAlreadyAdded.length) return;
    }
    // eslint-disable-next-line
    const filteredProduct = products.filter((a: any) =>
      // eslint-disable-next-line
      a.actualBillingSent.filter((item: any) => {
        // eslint-disable-next-line
        if (
          a?.stateInvoiceNo.includes(e.target.value) === true ||
          a?.stateInvoiceNo.toLowerCase().replace(/ +/g, "") ===
            e.target.value.toLowerCase().replace(/ +/g, "") ||
          a?.stateInvoiceNo
            .toLowerCase()
            .replace(/ +/g, "")
            ?.includes(e.target.value) === true
        ) {
          const newObj = {
            id: a.id,
            name: item.product.name,
            quantity: 0,
            price: +item?.sellingPrice,
            totalQuantity: item.quantity,
            itemId: item.product.id,
            invoiceNo: a?.stateInvoiceNo,
            weight: item?.product?.description,
            originalQuantity: item?.quantity,
            barcode: item?.product?.barcode,
            gst: item?.product?.GST
          };
          filteredNewArray = [...filteredNewArray, newObj];
          // filteredNewArray.push(newObj);
          return newObj;
        }
        // return filteredNewArray
      })
    );
    if (!filteredProduct.length) {
      // setAddedProduct([]);
      return;
    }
    setAddedProduct([...addedProduct, ...filteredNewArray]);
    // }
  };

  React.useEffect(() => {
    let total: number = 0;
    filtered.forEach((singleTotal: BillingReturn) => {
      total = total + +singleTotal.price * singleTotal.quantity;
    });
    if (discounAmount) {
      setGrandTotal(total - (total * discounPersentage) / 100);
      setDiscountAmount((total * discounPersentage) / 100);
      // setGrandTotal(total - discounAmount);
    } else {
      setGrandTotal(total);
    }
    setTotal(total);
    // eslint-disable-next-line
  }, [addedProduct,products]);

  const handleChangeDiscountAmount = (e: any) => {
    const amount = e.target.value;
    if (amount > total) {
      setDiscountAmount(0);
      setDiscountPercentage(0);
      setGrandTotal(total);
    } else {
      setDiscountAmount(amount);
      setGrandTotal(total - amount);
      setDiscountPercentage((amount / total) * 100);
    }
  };

  const handleChangeDiscountPercentage = (e: any) => {
    const percentage = e.target.value;
    if (Number(percentage) >= 100) {
      setDiscountPercentage(100);
    } else {
      setDiscountPercentage(percentage);
      setGrandTotal(total - (total * percentage) / 100);
      setDiscountAmount((total * percentage) / 100);
    }
  };

  const handleChnageCashReceive = (e: any) => {
    const receive = e.target.value;
    setCashReceive(receive);
    setCashReturn(receive - grandTotal);
  };
  const handleChnageCashReturn = (e: any) => {
    const returnCash = e.target.value;
    setCashReturn(returnCash);
  };

  const arr = addedProduct;
  const ids = arr.map((o) => o.itemId);
  const filtered = arr.filter(({ itemId }, index) => !ids.includes(itemId, index + 1));
  var debug = filtered?.filter((x) => {
    return x?.invoiceNo.toLowerCase().replace(/ +/g, "") ===
    barcode.toLowerCase().replace(/ +/g, "") ;
  });
  

  React.useEffect(() => {
    let filteredNewArray: any = [];
    // eslint-disable-next-line
    const filteredProduct = products.filter((a: any) =>
      // eslint-disable-next-line
      a.actualBillingSent.filter((item: any) => {
        if (
          a?.stateInvoiceNo.includes(barcode) === true ||
          a?.stateInvoiceNo.toLowerCase().replace(/ +/g, "") ===
            barcode.toLowerCase().replace(/ +/g, "") ||
          a?.stateInvoiceNo
            .toLowerCase()
            .replace(/ +/g, "")
            ?.includes(barcode) === true
        ) {
          const newObj = {
            id: a.id,
            name: item.product.name,
            quantity: 0,
            price: +item?.sellingPrice,
            totalQuantity: item.quantity,
            itemId: item.product.id,
            invoiceNo: a?.stateInvoiceNo,
            weight: item?.product?.description,
            originalQuantity: item?.quantity,
            barcode: item?.product?.barcode,
            gst: item?.product?.GST
          };
          filteredNewArray = [...filteredNewArray, newObj];
          // filteredNewArray.push(newObj);
          return newObj;
        }
        // return filteredNewArray
      })
    );
    if (!filteredProduct.length) {
      // setAddedProduct([]);
      return;
    }
    setAddedProduct([...addedProduct, ...filteredNewArray]);
    // }
    // eslint-disable-next-line
  }, [products,barcode]);

  const totalItems = filtered.reduce((acc, item) => Number(acc) + Number(item.quantity), 0);  

  const shouldScroll = filtered.length > 5;

  return (
    <Box>
      <Box>
        <Box sx={{ width: "65%" }}>
          <ReturnBarcodeInput
            filtered={filtered}
            value={barcode}
            onChange={handleChangeBarcode}
            name={"Return"}
          />
        </Box>
        <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", width: "65%" }}>
        <>
            {debug.length ? (
            <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell className={`${classes.headerCell}`}><b>Product</b></TableCell>
                  <TableCell className={`${classes.headerCell}`}><b>Brand</b></TableCell>
                  <TableCell className={`${classes.headerCell}`}><b>Weight</b></TableCell>
                  <TableCell className={`${classes.headerCell}`}><b>Price</b></TableCell>
                  <TableCell className={`${classes.headerCell}`}><b>In Stock</b></TableCell>
                  <TableCell className={`${classes.headerCell}`}><b>Quantity</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
          {barcode !== null && barcode !== "" ? (
            <>
              {debug?.map((card: BillingReturn) => (
                <ItemCard
                  key={card.index}
                  productName={card.name}
                  quantity={card.quantity}
                  price={card.price}
                  increaseQuantity={() =>
                    IncrementFunction(card.itemId, addedProduct, setAddedProduct)
                  }
                  decreaseQuantity={() =>
                    DecrementFunction(card.itemId, addedProduct, setAddedProduct)
                  }
                  weight={card.weight}
                  totalQuantity={card.totalQuantity}
                  originalQuantity={card.originalQuantity}
                />
              ))}
            </>
          ) : (
            <></>
          )}
                         </TableBody>
                      </Table>
                      </TableContainer>
            ):(
              <></>
            )}
            </>
        </Box>
        {filtered.length ? (
          <Paper
            sx={{
              width: 400,
              position: "fixed",
              top: 0,
              bottom: 0,
              right: 0,
              zIndex: 99,
              padding: 2,
            }}
          >
            <Box sx={{ position: "relative", height: "97vh" }}>
            <Box
  sx={{
    maxHeight: shouldScroll ? '50vh' : 'unset', // Match maxHeight to 50vh
    overflowY: shouldScroll ? 'auto' : 'unset', // Use 'auto' for scrollbar only when needed
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
        width: '12px',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: '#f1f1f1',
        borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#ccc',
        borderRadius: '10px',
        border: '3px solid #f1f1f1',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#bbb',
    },
    padding: '10px',
    height: "50vh", // Set height to 50vh to match the maxHeight
  }}
>

              {filtered.length &&
                // eslint-disable-next-line
                filtered?.map((item: BillingReturn,index) => {
                  if (item.quantity > 0) {
                    return (
                      <React.Fragment key={index}>
                          <Box
                              sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  padding: '10px 0',
                                  borderBottom: '2px dotted black', // Dotted border instead of solid divider
                              }}
                          >
                              <Box sx={{ flex: 3 }}> {/* 60% space */}
                                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                      {item.name}
                                  </Typography>
                                  <Typography variant="body2">
                                      Rs.{Math.round(item.price)} {" "} 
                                      | Barcode: {item.barcode} | GST: {item.gst}%
                                  </Typography>
                              </Box>
                              <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}> {/* 20% space */}
                                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                      x {item.quantity}
                                  </Typography>
                              </Box>
                              <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}> {/* 20% space */}
                                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                      Rs. {Math.round(item.price) * item.quantity}
                                  </Typography>
                              </Box>
                              </Box>
                             
                      </React.Fragment>
                  );
                 }
                  return null;
                })}
                
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography component="div">Total:-</Typography>
                <Typography component="div">Rs {total}</Typography>
              </Box> */}
                   
                  </Box>
                
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2,mt:4 }}>
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
      Sub Total
    </Typography>
    <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
      {totalItems} items
    </Typography>
    <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
      Rs. {Math.round(total)}
    </Typography>
  </Box>

  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderTop: '2px dotted black',
      borderBottom: '2px dotted black',
      py: 1,
      // mt: 2, // Reduced margin to bring Sub Total closer to Grand Total
    }}
  >
    <Typography variant="h5" style={{ fontWeight: 'bold' }}>
      Grand Total
    </Typography>
    <Typography style={{ fontWeight: 'bold', fontSize: '1.3rem', whiteSpace: 'nowrap' }}>
      Rs. {Math.round(total)}
    </Typography>
  </Box>
</Box>

                  <Box 
  sx={{
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center", 
    alignItems: "center",
    marginBottom: "10px",
    height: "50px",     
  }}
>
               <Typography
                  className={classes.cashCollectMethod}
                  onClick={() => {
                    setOpen(true);
                    setName("cash")
                  }}
                >
                  Cash
                </Typography>
              </Box>
            </Box>
          </Paper>
        ) : (
          <></>
        )}
      </Box>
      <Modal
        header="Return"
        children={
          isOnline ? (
            <Cash
              addedProduct={filtered}
              orderId={barcode}
              grandTotal={grandTotal}
              setBarcode={setBarcode}
              setAddedProduct={setAddedProduct}
              setOpen={setOpen}
              openName={name}
            />
          ) : (
            <Ofline
              addedProduct={filtered}
              orderId={barcode}
              grandTotal={grandTotal}
              name={"Return"}
              setBarcode={setBarcode}
              setAddedProduct={setAddedProduct}
              setOpen={setOpen}
              openName={name}
            />
          )
        }
        handleClose={() => {
          setOpen(false);
          setIsonline(false);
        }}
        open={open}
      />
    </Box>
  );
}
