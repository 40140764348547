import { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import CreditOrders from './CreditOrders';
import TransitionsModal from '../../component/modal';
import { useDispatch } from 'react-redux';
import { CreditByStore, CreditByStoreId, creditEmailAndSms } from './action';
import { useSelector } from 'react-redux';
import Utils from '../../utility';

const CreditTableTab = ({ creditData }) => {
  const[open,setOpen] = useState(false);
  // const getStoreId = localStorage.getItem("storeId")
  const { storeId: getStoreId } = useSelector(
    (state: any) => state.commonReducer
  );
  const dispatch: any = useDispatch();
  const[customerId,setCustomerId] = useState(0)

  const { offset, count,take } = useSelector(
    (state: any) => state.creditsReducer
  );

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null,newPage: any) => {
    dispatch({
      type: Utils.ActionName.CREDIT,
      payload: { offset: newPage + 1  },
    });
    dispatch(CreditByStore(getStoreId,""));
  };

  const handleChangeRowsPerPage = (event: any) => {
    dispatch({
      type: Utils.ActionName.CREDIT,
      payload: { take: take, offset: offset },
    });
    dispatch(CreditByStore(getStoreId,""));
  };

  const openView = (id:number,amount:string) =>{
      setOpen(true);
      dispatch(CreditByStoreId(id,getStoreId))
      // dispatch(creditEmailAndSms(getStoreId,id))
      setCustomerId(id)
  }

  const sendEmail = (id:number) =>{
    dispatch(creditEmailAndSms(getStoreId,id))
}

  return (
    <>
    <div style={{marginTop:"60px"}}>
        <TransitionsModal
        header="Credit Orders"
        children={
          open && (
            <>
           <CreditOrders customerId={customerId} setOpen={setOpen}/>
           </>
                  ) 
        }
        handleClose={() => {
          setOpen(false);
        }}
        open={open}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
            <TableCell>S.No</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Phone/Email</TableCell>
              <TableCell>Total Credit</TableCell>
              <TableCell>Unpaid Credit</TableCell>
              <TableCell>Action</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {creditData.map((data:any,i:number) => (
              <TableRow key={data?.customer_id}>
                <TableCell>{i+1}</TableCell>
                <TableCell>{data?.customer_name}</TableCell>
                <TableCell>{
  data?.customer_phoneNumber && data?.customer_email
    ? `${data?.customer_phoneNumber} / ${data?.customer_email}`
    : data?.customer_phoneNumber
    ? data?.customer_phoneNumber
    : data?.customer_email
    ? data?.customer_email
    : null
}</TableCell>
                <TableCell>{Math.round(data?.totalcredit)}</TableCell>
                <TableCell>{Math.round(data?.unpaidcredit)}</TableCell>
                <TableCell style={{cursor:"pointer"}} onClick={() => openView(data?.customer_id,data?.totalOrderAmount)}>View</TableCell>
                <TableCell style={{cursor:"pointer"}} onClick={() => sendEmail(data?.customer_id)}> {data?.customer_email? "Send Email": data?.customer_phoneNumber? "Send SMS": null}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
        <TablePagination
        component="div"
        count={count}
        rowsPerPage={take}
        page={offset - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
    </>
  );
};

export default CreditTableTab;
