import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import { CreditByStore } from "../../pages/credit/action";
import { useSelector } from "react-redux";

export default function CreditBarcodeInput({ onChange, value,name}: any) {
  const dispatch: any = useDispatch();
  // const getStoreId = localStorage.getItem("storeId");
  const { storeId: getStoreId } = useSelector((state:any) => state.commonReducer);

  return (
    <Box
    sx={{
      position: "absolute",
      right: 0,
      width: "30%",
      marginBottom: "20px",
    }}
  >
      <TextField
        autoFocus={true}
        fullWidth
        label={name==="Credit" ? "Search with name or number" :"Search with name or number"}
        id="fullWidth"
        onChange={onChange}
        value={value?.toLowerCase()}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === "Enter" && !event.repeat) {
            event.preventDefault()
                 // eslint-disable-next-line
            {name==="Credit" &&  dispatch(CreditByStore(getStoreId,value))} 
          }
        }}
      />
    </Box>
  );
}
