import Utils from ".";

export const checkImageDimension = (file, callImageUpload) => {
  if (file.size / 1024 > 200) {
    Utils.showAlert(2, "Please Upload a image less than 200kb");
    return;
  }
  if (file && file) {
    var img = document.createElement("img");
    img.onload = function () {
      console.log(this.width + " " + this.height);
      callImageUpload(this.width, this.height);
    };
    var reader = new FileReader();
    reader.onloadend = function (ended) {
      img.src = ended.target.result;
    };
    reader.readAsDataURL(file);
  }
};

const commonFunction = {
  checkImageDimension,
};

export default commonFunction;
