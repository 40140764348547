import Utils from '../../utility'

export const getProductsOrder: any = (value: any,storeID:number,fromDate:any,toDate:any) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    const { order, sortField, offset } = getState().returnReducer

    Utils.api.getApiCall(
      Utils.endPoints.orders,
      `?order=${order}&sortField=${sortField}&offset=${offset}&take=${50}&search=${value}&store=${storeID}&fromDate=${fromDate}&toDate=${toDate}`,
      (respData: any) => {
        dispatch({
          type: Utils.ActionName.RETURN,
          payload: {
            products: respData.data?.order,
            count: respData?.data?.count,
          },
        })
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        let { data } = error
        if(data?.message === "" || !data) {
          Utils.showAlert(2,"Orders Get Error:")
      }else{
          Utils.showAlert(2,"Orders Get Error: " + data?.message)
      }
      },
    )
  }
}

export const creatOrderReturn = (values: any, products: any, id: any, storeId: any, setBarcode: any,
  setAddedProduct: any, setOpen: any, setIsDisabled: any,openName:string) => {
  return (dispatch: any) => {
    let dataToSend = {}
    dataToSend = {
      product: products.map((item: any) => {
        return {
          product: item.itemId,
          quantity: item.quantity,
        }
      }),
      store: parseInt(storeId),
      reason: values?.reason,
      paymentMethod:openName
    }
    Utils.api.postApiCall(
      Utils.endPoints.createOrderReturn + id,
      dataToSend,
      (respData: any) => {
        Utils.showAlert(1, 'Success')
        dispatch(returnInvoice(respData.data.id))
        setBarcode("")
        setAddedProduct([])
        setOpen('')
        setIsDisabled(false)
      },
      (error: any) => {
        let { data } = error
        if(data?.message === "" || !data) {
          Utils.showAlert(2,"Order Return Add Error:")
      }else{
          Utils.showAlert(2,"Order Return Add Error: " + data?.message)
      }
        setBarcode("")
        setAddedProduct([])
        setOpen('')
      },
    )
  }
}

export const returnInvoice = (id: any) => {
  return (dispatch: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    Utils.api.getApiCall(
      Utils.endPoints.returnInvoice + id,
      '',
      (respData: any) => {
        var winparams =
          'dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,' +
          'resizable,screenX=50,screenY=50,width=850,height=1050'
        var htmlPop =
          '<embed width=100% height=100%' +
          ' type="application/pdf"' +
          ' src="data:application/pdf;base64,' +
          escape(respData?.data) +
          '"></embed>'
        var printWindow = window.open('', 'PDF', winparams)
        //@ts-ignore
        printWindow.document.write(htmlPop)
        //@ts-ignore
        // printWindow.print()

        // const linkSource = `data:application/pdf;base64,${respData.data}`;
        // const downloadLink = document.createElement("a");
        // const fileName = "abc.pdf";
        // downloadLink.href = linkSource;
        // downloadLink.download = fileName;
        // downloadLink.click();

        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        // Utils.showAlert(1, 'successfully')
      },
      (error: any) => {
        let { data } = error
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        if(data?.message === "" || !data) {
          Utils.showAlert(2,"Invoice Get Error")
      }else{
          Utils.showAlert(2,"Invoice Get Error: " + data?.error || data?.message)
      }
      },
    )
  }
}