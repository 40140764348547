import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@mui/material';
import { getAllShiftDetails } from '../../pages/billing/action';
import { useSelector, useDispatch } from 'react-redux';
// import ShiftDatePicker from './ShiftDatePicker';
import TransitionsModal from '../modal';
import ShiftApprove from './ShiftApprove';
import ShiftView from './ShiftView';
import Utils from '../../utility';
import { format, startOfDay, endOfDay, subDays } from 'date-fns';
import ShiftViewApprove from './ShiftViewApprove';

function ShiftDetails() {
  const { shiftStoreDetails } = useSelector((state: any) => state.productsReducer);
  const [open, setOpen] = useState(false);
  const [openViewTest, setOpenViewTest] = useState(false);
  const [openViewApproveTest, setOpenViewApproveTest] = useState(false);
  const [selectedShiftId, setSelectedShiftId] = useState(null);
  const [selectedShiftApprovedId, setSelectedShiftApprovedId] = useState(null);
  const [viewShift, setViewShift] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const { storeId: getStoreId } = useSelector((state: any) => state.commonReducer);
  const dispatch: any = useDispatch();

  const openView = (id: any) => {
    setOpenViewTest(true);
    setViewShift(id);
  };

  const openViewApproved = (id:any) => {
    setOpenViewApproveTest(true);
    setSelectedShiftApprovedId(id)
  }

  const approveShift = (id: any) => {
    setOpen(true);
    setSelectedShiftId(id);
  };

  const fetchDefaultWeekData = () => {
    const defaultFromDate = subDays(new Date(), 7);
    const defaultToDate = new Date();
    const formattedFromDate = format(startOfDay(defaultFromDate), 'yyyy-MM-dd HH:mm:ss');
    const formattedToDate = format(endOfDay(defaultToDate), 'yyyy-MM-dd HH:mm:ss');
    if(getStoreId){
      dispatch(getAllShiftDetails(getStoreId, formattedFromDate, formattedToDate));
    }
  };

  const handleDateSubmit = () => {
    if (fromDate && toDate) {
      const formattedFromDate = format(startOfDay(fromDate), 'yyyy-MM-dd HH:mm:ss');
      const formattedToDate = format(endOfDay(toDate), 'yyyy-MM-dd HH:mm:ss');
      dispatch(getAllShiftDetails(getStoreId, formattedFromDate, formattedToDate));
    } else {
      Utils.showAlert(2, 'Please select both dates');
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: any) => {
    dispatch({
      type: Utils.ActionName.PRODUCTS,
      payload: { offset: newPage + 1 },
    });
    if (fromDate && toDate) {
      handleDateSubmit();
    } else {
      fetchDefaultWeekData();
    }
  };

  const { offset, take } = useSelector((state: any) => state.productsReducer);

  useEffect(() => {
    if (!fromDate && !toDate) {
      fetchDefaultWeekData();
    }
  }, [fromDate, toDate,getStoreId]);

  return (
    <div>
      <TransitionsModal
        header="Shift Approve"
        children={
          open && <ShiftApprove key={selectedShiftId} selectedShiftId={selectedShiftId} fetchDefaultWeekData={fetchDefaultWeekData} setOpen={setOpen} />
        }
        handleClose={() => {
          setOpen(false);
        }}
        open={open}
      />
      <TransitionsModal
        header="Shift Details"
        children={
          openViewTest && <ShiftView key={viewShift} viewShift={viewShift} />
        }
        handleClose={() => {
          setOpenViewTest(false);
        }}
        open={openViewTest}
      />
            <TransitionsModal
        header="Shift Details"
        children={
          openViewApproveTest && <ShiftViewApprove key={selectedShiftApprovedId} selectedShiftApprovedId={selectedShiftApprovedId} />
        }
        handleClose={() => {
          setOpenViewApproveTest(false);
        }}
        open={openViewApproveTest}
      />
      
      {/* <ShiftDatePicker
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        onSubmit={handleDateSubmit}
      /> */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Shift Id</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shiftStoreDetails.map((data: any, i: number) => (
              <TableRow key={data?.id}>
                <TableCell>{data?.id}</TableCell>
                <TableCell>{data?.billerName}</TableCell>
                <TableCell>{data?.status ? data.status.charAt(0).toUpperCase() + data.status.slice(1) : ''}</TableCell>
                {data?.status === 'closed' && (
                  <TableCell
                    style={{ cursor: 'pointer' }}
                    onClick={() => approveShift(data?.id)}
                  >
                    Approve
                  </TableCell>
                )}
                {data?.status === 'active' && (
                  <TableCell
                    style={{ cursor: 'pointer' }}
                    onClick={() => openView(data?.id)}
                  >
                    View
                  </TableCell>
                )}
                {data?.status === 'approved' && (
                  <TableCell style={{ cursor: 'pointer' }}
                  onClick={() => openViewApproved(data?.id)}
                  >View</TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={shiftStoreDetails?.length}
        rowsPerPage={take}
        page={offset - 1}
        onPageChange={handleChangePage}
        nextIconButtonProps={{ disabled: shiftStoreDetails?.length < 10 }}
      />
    </div>
  );
}

export default ShiftDetails;
