import Utils from "../../utility";

const initialState: any = {
  userId: "",
  storeId: "",
  userType: "",
  username: "",
  email: "",
};
export const commonReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case `${Utils.ActionName.COMMON}`:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
