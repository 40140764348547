import Utils from '../../utility'

const initialState = {
    salesData: [],
    returnData: [],
    cashSalesData: 0,
    digitalSalesData: 0,
    channelSalesData: 0,
    loading: true,
    tnsAppData:0
}
export const dashboardReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case `${Utils.ActionName.STORE}`:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return { ...state }
    }
}
