import React, { useState } from 'react';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { TextField, Button, Stack, Box } from '@mui/material';
import { format, startOfDay, endOfDay } from 'date-fns';
import { useDispatch } from 'react-redux';
import { getAllOrders } from './action';
import Utils from '../../utility';

const DatePickers = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (fromDate && toDate) {
      // Format the dates
      const formattedFromDate = format(startOfDay(fromDate), "yyyy-MM-dd HH:mm:ss");
      const formattedToDate = format(endOfDay(toDate), "yyyy-MM-dd HH:mm:ss");
      dispatch(getAllOrders(3,formattedFromDate,formattedToDate))
    } else {
      Utils.showAlert(2, "Please select both dates");
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={2} direction="row">
      <Box sx={{ width: '10vw' }}>
          <DesktopDatePicker
            label="From Date"
            inputFormat="yyyy-MM-dd"
            value={fromDate}
            onChange={(newValue) => setFromDate(newValue)}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </Box>
        <Box sx={{ width: '10vw' }}>
        <DesktopDatePicker
          label="To Date"
          inputFormat="yyyy-MM-dd"
          value={toDate}
          onChange={(newValue) => setToDate(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
            />
          )}
        />
                </Box>
          <Button 
 variant="contained"
 type="submit"
 sx={{
   width: "10vw",
   backgroundColor: "#fbd140", // Match the button color from the image
   color: "#000", // Black text
   borderRadius: "10px", // Rounded corners for the button
   padding: "10px 0", // Adjust padding for better size
   fontSize: "16px", // Adjust font size
   "&:hover": {
     backgroundColor: "#fbd140", // Same color on hover
   },
 }}
          onClick={handleSubmit}>
            Submit
          </Button>
      </Stack>
    </LocalizationProvider>
  );
};

export default DatePickers;
