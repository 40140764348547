import { ProductInterface } from '../Interface/ProductsInterface';

function RtvDiscardIncrementFunction(key:number,addedProduct:any,setAddedProduct:any,setBarcode:any) {
    const updateProduct = addedProduct.map((item: ProductInterface) => {
        if (item.id === key) {
          const newArray = { ...item, quantity: ++item.quantity };
          setBarcode("")
          return newArray;
        } else {
          setBarcode("")
          return item;
        }
      });
      setAddedProduct([...updateProduct]);
}

export default RtvDiscardIncrementFunction