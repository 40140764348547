import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { getProductsBackendSearch } from "../../pages/billing/action";
import { getProductsOrder } from "../../pages/return/action";
import Utils from "../../utility";
import { useSelector } from "react-redux";

type MyObject = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: any;
  name?: string;
  filtered?: any;
};

export default function FullWidthTextField({
  onChange,
  value,
  name,
  filtered,
}: MyObject) {
  const dispatch: Dispatch = useDispatch();
  const { storeId: getStoreId } = useSelector((state: any) => state.commonReducer);

  // Remove leading zeros from barcode
  const modifiedBarcode = value.replace(/^0+/, "");

  // Check if the item is already added in filtered
  const isAlreadyAdded = filtered?.some(
    (item: any) => item.barcode?.toLowerCase() === value?.toLowerCase() || item.name?.toLowerCase().includes(value?.toLowerCase())
  );

  return (
    <Box
      sx={{
        maxWidth: "100%",
      }}
    >
      <TextField
        autoFocus={true}
        fullWidth
        label={
          name === "Return"
            ? "Search with invoice number"
            : "Search with name or scan barcode"
        }
        id="fullWidth"
        onChange={onChange}
        value={value?.toLowerCase()}
        onKeyDown={(event: any) => {
          if (event.key === "Enter" && !event.repeat) {
            event.preventDefault();

            if (isAlreadyAdded) {
              // Prevent adding the same product again
              // Utils.showAlert(2, "Product is already added to the cart");
              return;
            }

            if (name === "Billing" && value?.length >= 1) {
              if (filtered?.map((ele: any) => ele.barcode).includes(value)) {
                Utils.showAlert(2, "Product is already in the cart");
              } else if (getStoreId) {
                dispatch(getProductsBackendSearch(getStoreId, modifiedBarcode));
              } else {
                Utils.showAlert(2, "Store Id not found");
              }
            } else if (name === "Return") {
              dispatch(getProductsOrder(value, getStoreId));
            }
          }
        }}
        autoComplete="off"
      />
    </Box>
  );
}
