import Utils from '../../utility'

const initialState = {
  products: [],
  images: [],
  count: 0,
  order: 'ASC',
  sortField: 'name',
  offset: 1,
  take: 10,
  search: '',
  pin: [],
  loading: false,
  customerName: "",
  customerNumber:"",
  customerCompany:"",
  customerGst:"",
  customerDetails:{},
  invoiceHtml: '',
  totalOrders: 0,
  cash: 0,
  upi: 0,
  swiggyZomato: 0,
  credit: 0,
  rtv: 0,
  discard: 0,
  startTime:"",
  shiftId:"",
  openingCash:0,
  cashPending:"",
  tnsapp:0,
  returns:0,
  totalCashouts:0,
  cashOutSales:0,
  endTime:"",
  closingCash:0,
  shiftStoreDetails:[],
  closedByBillerName:"",
  shiftTestDetails:[]
}

// Action types
const CLEAR_CUSTOMER_DETAILS = 'CLEAR_CUSTOMER_DETAILS';
const CLEAR_INVOICE = 'CLEAR_INVOICE';
const CLEAR_SHIFT_DETAILS = 'CLEAR_SHIFT_DETAILS';

export const productsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case `${Utils.ActionName.PRODUCTS}`:
      return {
        ...state,
        ...action.payload,
      }
      case `${Utils.ActionName.LOADING}`:
      return {
        ...state,
        loading: action.payload.loading,
      };
      case CLEAR_CUSTOMER_DETAILS:
      return {
        ...state,
        customerName: '',
        customerNumber: '',
        customerCompany: '',
        customerGst: '',
        customerDetails: {},
      };
      case CLEAR_INVOICE:
        return {
          ...state,
          invoiceHtml: '' 
        };
        case CLEAR_SHIFT_DETAILS:
      return {
        ...state,
        shiftTestDetails: {}, // Clear the shiftTestDetails
      };
    default:
      return { ...state }
  }
}

export const clearCustomerDetails = () => ({
  type: CLEAR_CUSTOMER_DETAILS,
});

export const clearShiftDetails = () => ({
  type: CLEAR_SHIFT_DETAILS,
});

export const clearInvoiceDetails = () => ({
  type: CLEAR_INVOICE,
});