import * as Yup from "yup";
/**
 * @description
 * @param t language
 */

/**
 * @description
 * @param t language
 */

// const phoneRegExp =
//   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
// const IFSCReg = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/;
// const GSTINReg = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
// const pancardReg = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;

/****Schema for SKU time line filter */
const onlineCashCollectReturnPopup = () =>
  Yup.object({
    payType: Yup.string().required("Required").nullable(),
    invoiceNumber: Yup.string().required("Required").nullable(),
    reason: Yup.string().required("Required").nullable(),
  });

  const offlineCashCollectReturnPopup = () =>
  Yup.object({
    invoiceNumber: Yup.string().required("Required").nullable(),
    reason: Yup.string().required("Required").nullable(),
  });

const onlineCashCollectPopup = () =>
  Yup.object({
    payType: Yup.string().required("Required").nullable(),
    phoneNumber: Yup.string().nullable(),
    customerName: Yup.string().nullable(),
  });

const offlineCashCollectPopup = () =>
  Yup.object({
    phoneNumber: Yup.string().nullable(),
    customerName: Yup.string().nullable(),
  });

  const Cashout = () =>
  Yup.object({
    amount: Yup.string().required("Required").nullable(),
    reason: Yup.string().required("Required").nullable(),
  });

const Schema = {
  onlineCashCollectReturnPopup,
  offlineCashCollectReturnPopup,
  onlineCashCollectPopup,
  offlineCashCollectPopup,
  Cashout
};

export default Schema;
