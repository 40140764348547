import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { PosDashboard } from "./action";
import { useDispatch } from "react-redux";
import DashboardSalesTable from "./DashboardSalesTable";
import DashboardCashout from "./DashboardCashout";
import { Suspense } from "react";
import LoadingComponent from "../../utility/LoadingComponent";
import { useSelector } from "react-redux";
import moment from 'moment';

const DashboardReturnTable = React.lazy(() => import("./DashboardReturnTable"));

function DashboardCard() {
  const dispatch: any = useDispatch();
  const { salesData } = useSelector((state: any) => state.dashboardReducer);
  const {returnTotal, channelSalesData, loading, digitalSalesData, cashSalesData,tnsAppData,creditData } =
    useSelector((state: any) => state.dashboardReducer);
  const { returnData } = useSelector((state: any) => state.dashboardReducer);
  const todayFromDate = moment().format('YYYY-MM-DD') + "T00:00"
  const todayToDate = moment().format('YYYY-MM-DD') + "T23:59"

  React.useEffect(() => {
    dispatch(PosDashboard(todayFromDate,todayToDate));
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
        <DashboardCashout
          returnData={returnData}
          channelSalesData={channelSalesData}
          loading={loading}
          digitalSalesData={digitalSalesData}
          cashSalesData={cashSalesData}
          tnsAppData={tnsAppData}
          creditData={creditData}
          returnTotal={returnTotal}
        />
        <React.Fragment>
          <CardContent>
            <h3>Sales</h3>
            <DashboardSalesTable salesData={salesData} loading={loading} />
            <Divider />
            <h3>Returns</h3>
            <Suspense fallback={<LoadingComponent />}>
              <DashboardReturnTable returnData={returnData} loading={loading} />
            </Suspense>
          </CardContent>
        </React.Fragment>
      </Card>
    </Box>
  );
}
export default DashboardCard;
