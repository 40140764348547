import Utils from '../../utility'

const initialState = {
    generatedMaps: [],
    addedTime: "",
    affected: 0,
    addedReason:"",
    addedCashout:"",
    userDoneBy:""
}
export const cashoutReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case `${Utils.ActionName.CASHOUT}`:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return { ...state }
    }
}
