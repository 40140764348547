import ItemCard from "../../component/card/productCard";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { useStyles } from "./useStyles";
import { ProductInterface } from "../../Interface/ProductsInterface";
import RtvDiscardDecrementFunction from "../../utility/RtvDiscardDecrementFunction";
import RtvDiscardIncrementFunction from "../../utility/RtvDiscardIncrementFunction";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useBillingStyles } from "../pagesUtils/useBillingStyles";
import { useSelector } from "react-redux";
import { RootStateBillingProduct } from "../../Interface/BillerIdInterface";

interface Props{
  debug:any
  addedProduct:ProductInterface[],
  barcode:string;
  name:string;
  submitForm:() => void;
  setAddedProduct:any;
  disabled:boolean;
  filtered:any;
  setBarcode:any
}

function AddedProduct({debug,addedProduct,barcode,setAddedProduct,name,submitForm,disabled,filtered,setBarcode}:Props) {
  const classes = useStyles();
  const classesB = useBillingStyles();
  const { products } = useSelector(
    (state: RootStateBillingProduct) => state.productsReducer
  );
  // var cartProducts:any = filtered.map((product:any) => product.quantity>0 && product);

  // var myFilterArray = cartProducts.filter(Boolean);

  var cartProducts:any = filtered.map((product:any) => product.quantity>0 && product);

var similarObjects:any = [];

filtered.map((obj1:any) => {
  const obj2 = products.find((obj) => obj.product.id === obj1.id);
  if (obj2) {
    similarObjects.push(obj1);
  }
  return null;
});

var myFilterArray = cartProducts.filter(Boolean);

  const [total, setTotal] = useState<number>(0);


 useEffect(() => {
    let total: number = 0;
    filtered.forEach((singleTotal: any) => {
      total = total + +singleTotal.productCostPrice * singleTotal.quantity;
    });
    setTotal(total);
  },[addedProduct,filtered])

  const shouldScroll = filtered.length > 5;

  const totalItems = filtered.reduce((acc, item) => acc + item.quantity, 0);  

  return (
    <>
    <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", width: "65%" }}>
    {debug.length ? (
            <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead className={classesB.tableHeader}>
                <TableRow>
                  <TableCell className={`${classesB.headerCell}`}><b>Product</b></TableCell>
                  <TableCell className={`${classesB.headerCell}`}><b>Brand</b></TableCell>
                  <TableCell className={`${classesB.headerCell}`}><b>Weight</b></TableCell>
                  <TableCell className={`${classesB.headerCell}`}><b>Price</b></TableCell>
                  <TableCell className={`${classesB.headerCell}`}><b>Cost Price</b></TableCell>
                  <TableCell className={`${classesB.headerCell}`}><b>In Stock</b></TableCell>
                  <TableCell className={`${classesB.headerCell}`}><b>Quantity</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
    {barcode!==null && barcode!==""  && (
        <>
          {debug?.map((card: any) => (
              <ItemCard
                key={card.id}
                productName={card.name}
                quantity={card.quantity}
                price={card.price}
                increaseQuantity={() => RtvDiscardIncrementFunction(card.id,addedProduct,setAddedProduct,setBarcode)}
                decreaseQuantity={() => RtvDiscardDecrementFunction(card.id,addedProduct,setAddedProduct)}
                weight={card.weight}
                name={"Discard"}
                originalQuantity={card.originalQuantity}
                productCostPrice = {card.productCostPrice}
                brandName={card?.brandName}
                />
            // )
          ))}
        </>
      )}
       </TableBody>
                      </Table>
                      </TableContainer>
            ):(
              <></>
            )}
      <>
      {barcode==="" && myFilterArray.map((card:any) => (
       <ItemCard
       key={card.id}
       productName={card.name}
       quantity={card.quantity}
       price={card.price}
       increaseQuantity={() => RtvDiscardIncrementFunction(card.id,addedProduct,setAddedProduct,setBarcode)}
       decreaseQuantity={() => RtvDiscardDecrementFunction(card.id,addedProduct,setAddedProduct)}
       weight={card.weight}
       name={"Discard"}
       originalQuantity={card.originalQuantity}
       productCostPrice = {card.productCostPrice}
       brandName={card?.brandName}
       />
))}
      </>
        </Box>
        {filtered?.map((item: any) => {
                  if (item.quantity > 0) {
                    return (
                      <>
          <Paper
            sx={{
              width: 400,
              position: "fixed",
              top: 0,
              bottom: 0,
              right: 0,
              zIndex: 99,
              padding: 2,
            }}
          >
            <Box sx={{ position: "relative", height: "97vh" }}>
            <Box
  sx={{
    maxHeight: shouldScroll ? '50vh' : 'unset', // Match maxHeight to 50vh
    overflowY: shouldScroll ? 'auto' : 'unset', // Use 'auto' for scrollbar only when needed
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
        width: '12px',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: '#f1f1f1',
        borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#ccc',
        borderRadius: '10px',
        border: '3px solid #f1f1f1',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#bbb',
    },
    padding: '10px',
    height: "50vh", // Set height to 50vh to match the maxHeight
  }}
>
            {filtered.length &&
                filtered?.map((item, index) => {
                    if (item.quantity > 0) {
                        return (
                            <React.Fragment key={index}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        padding: '10px 0',
                                        borderBottom: '2px dotted black', // Dotted border instead of solid divider
                                    }}
                                >
                                    <Box sx={{ flex: 3 }}> {/* 60% space */}
                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                            {item.name}
                                        </Typography>
                                        <Typography variant="body2">
                                            Rs.{Math.round(item.productCostPrice)} | Barcode: {item.barcode} | GST: {item.gst}%
                                        </Typography>
                                    </Box>
                                    <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                            x {item.quantity}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}> {/* 20% space */}
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                         Rs {Math.round(item.productCostPrice) * item.quantity}
                                        </Typography>
                                    </Box>
                                </Box>
                            </React.Fragment>
                        );
                    }
                    return null;
                })}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt:10 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '2px dotted black',
            // pb: 1,
            // mt: 7,
          }}
        >
          <Typography variant="h6" style={{fontWeight:"bold", fontSize: '1.1rem'}}>Sub Total</Typography>
          <Typography variant="h6" style={{fontWeight:"bold", fontSize: '1.1rem'}}>{totalItems} items</Typography>
          <Typography variant="h6" style={{fontWeight:"bold", fontSize: '1.1rem'}}>Rs.{Math.round(total)}</Typography>
        </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      borderBottom: "2px dotted black",
                      py: 1,
                      // mt: 7,
                    }}
                  >
           <Typography variant="h5" style={{fontWeight:"bold"}}>Grand Total</Typography>
           <Typography style={{fontWeight:"bold", fontSize: '1.3rem', whiteSpace: 'nowrap'}}>
                     Rs. {Math.round(total)}           
                    </Typography>{" "}
                  </Box>
                  </Box>
        {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt:10 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
           <Typography variant="h5" style={{fontWeight:"bold"}}>Grand Total</Typography>
                    <Typography component="div" className={classes.amount} style={{fontWeight:"bold", fontSize: '1.3rem'}}>
                    Rs.{Math.round(total)}           
                    </Typography>{" "}
        </Box>
        </Box> */}
              {/* {addedProduct.length &&
                // eslint-disable-next-line
                addedProduct?.map((item: any) => {
                  if (item.quantity > 0) {
                    return (
                      <>
                        {item.quantity && (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography>
                              {`${item.name} ${Math.round(item.productCostPrice)}x${item.quantity}`}{" "}
                              :-
                            </Typography>
                            <Typography>
                              Rs {Math.round(item.productCostPrice) * item.quantity}
                            </Typography>
                          </Box>
                        )}
                      </>
                    );
                  }
                }
                )}
                 {filtered.length && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop:"20px"
                    }}
                  >
                    <Typography component="div">Total:-</Typography>
                    <Typography component="div">Rs {total}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderTop: "2px solid black",
                      borderBottom: "2px solid black",
                      py: 1,
                      mt: 2,
                    }}
                  >
                    <Typography variant="h4">Grand Total :-</Typography>
                    <Typography component="div" className={classes.amount}>
                      {Math.round(total)}           
                    </Typography>{" "}
                  </Box>
                </>
              )} */}
            </Box>
            <Box 
  sx={{
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center", 
    alignItems: "center",
    marginBottom: "10px",
    height: "50px",     
  }}
>
  <Button 
    disabled={disabled} 
    onClick={name === "Discard" ? submitForm : submitForm} 
    className={classes.rtvButton}
  >
    {name === "Discard" ? "Submit Discard" : "Submit RTV"}
  </Button>
</Box>
          </Paper> 
          </>
            )}})}
       
        </>
  )
}

export default AddedProduct