import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  tableHeader: {
    backgroundColor: '#ffeb3b', // Yellow background for header
  },
  headerCell: {
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'left',
    wordWrap: 'break-word',
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cellContent: {
    textAlign: 'left',
    wordWrap: 'break-word',
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  counterBtn: {
    background: 'black !important',
    color: 'white !important',
    borderRadius: '8px 0 0 8px !important',
    padding: '6px !important',
    '&:hover': {
      background: 'black !important',
    },
  },
  counterBtnRemove: {
    background: 'black !important',
    color: 'white !important',
    borderRadius: '0 8px 8px 0 !important',
    padding: '6px !important',
    marginLeft: 0,
    '&:hover': {
      background: 'black !important',
    },
  },
  count: {
    textAlign: 'center',
    width: '40px',
  },
  tableCell: {
    // Removed static width, we'll calculate dynamically based on column count
    padding: '8px', // Add some padding for better spacing
  },
}));

function ProductTable({
  id,
  productName,
  quantity,
  price,
  increaseQuantity,
  decreaseQuantity,
  weight,
  originalQuantity,
  product,
  updateProductAmount,
  totalQuantity,
  name,
  productCostPrice,
  brandName
}: any) {
  const classes = useStyles();

  // Determine how many columns we have dynamically
  const totalColumns = name === 'Discard' ? 6 : 5;

  // Calculate column width percentage based on the number of columns
  const columnWidth = `${100 / totalColumns}%`;

  return (
    <TableRow key={id} style={{ width: '100%' }}>
      <TableCell className={classes.cellContent} style={{ width: columnWidth }}>
        {productName}
      </TableCell>
      <TableCell className={classes.cellContent} style={{ width: columnWidth }}>
        {brandName}
      </TableCell>
      <TableCell className={classes.cellContent} style={{ width: columnWidth }}>
        {weight || 'N/A'}
      </TableCell>
      <TableCell className={classes.cellContent} style={{ width: columnWidth }}>
        {Math.round(price)}
      </TableCell>
      {name === 'Discard' && (
        <TableCell className={classes.cellContent} style={{ width: columnWidth }}>
          {Math.round(productCostPrice)}
        </TableCell>
      )}
      <TableCell className={classes.cellContent} style={{ width: columnWidth }}>
        <Typography color={originalQuantity < 0 ? 'red' : 'black'}>
          {Math.round(originalQuantity)}
        </Typography>
      </TableCell>
      <TableCell className={classes.cellContent} style={{ width: columnWidth }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            className={classes.counterBtn}
            onClick={() => increaseQuantity(id)}
          >
            <AddCircleIcon />
          </IconButton>
          <span className={classes.count}>{quantity}</span>
          <IconButton
            className={classes.counterBtnRemove}
            onClick={() => decreaseQuantity(id)}
          >
            <RemoveCircleIcon />
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  );
}

export default ProductTable;
