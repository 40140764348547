import Utils from '../../utility'

const initialState = {
    loginData: [],
    billerIdData: "",
    billerName: "",
    royalityData:[],
    ongoingShiftId:0,
    previousShiftId:0,
    ongoingShiftBillerId:0,
    loading:true,
}
export const loginReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case `${Utils.ActionName.BILLER}`:
            return {
                ...state,
                ...action.payload,
            }
            case Utils.ActionName.LOADING_START:
        return {
          ...state,
          loading: true,
        };
  
      case Utils.ActionName.LOADING_END:
        return {
          ...state,
          loading: false,
        };
        default:
            return { ...state }
    }
}
