import DashboardCard from "./DashboardCard";
import TodayYesterdayToggle from "./TodayYesterdayToggle";

export default function Dashboard() {
  return (
    <>
    <TodayYesterdayToggle />
        <DashboardCard />
   </>
  );
}
