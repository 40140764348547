import { BillingReturn } from '../Interface/BillingReturnInterface';

function DecrementFunction(key:number,addedProduct:any,setAddedProduct:any) {
    const updateProduct = addedProduct.map((item: BillingReturn) => {
        if (item.itemId === key) {
          const newArray = { ...item, quantity: item.quantity > 1 ? --item.quantity : 0 };
          return newArray;
        } else return item;
      });
      setAddedProduct([...updateProduct]);
}

export default DecrementFunction