import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { getProductsBackendSearch } from "../../pages/billing/action";
import Utils from "../../utility";

type RtvBarcodeInputProps = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  name?: string;
  filtered?: any[];
};

export default function RtvBarcodeInput({
  onChange,
  value = "",
  name = "",
  filtered = [],
}: RtvBarcodeInputProps) {
  const dispatch: Dispatch = useDispatch();
  const { storeId: getStoreId } = useSelector((state: any) => state.commonReducer);

  // Remove leading zeros from the barcode
  const modifiedBarcode = value.replace(/^0+/, "");

  // Check if the product is already added in filtered
  const isAlreadyAdded = filtered.some(
    (item: any) => item.barcode?.toLowerCase() === value.toLowerCase() || item.name?.toLowerCase().includes(value.toLowerCase())
  );

  return (
    <Box
      sx={{
        maxWidth: "100%",
      }}
    >
      <TextField
        autoFocus
        fullWidth
        label={
          name === "Return" ? "Search with invoice number" : "Search with name or scan barcode"
        }
        id="fullWidth"
        onChange={onChange}
        value={value.toLowerCase()}
        onKeyDown={(event: any) => {
          if (event.key === "Enter" && !event.repeat) {
            event.preventDefault();

            // Prevent duplicate product addition
            if (isAlreadyAdded) {
              // Utils.showAlert(2, "Product is already added");
              return;
            }

            if (name === "RTV" && value.length >= 1) {
              // Handle RTV logic
              if (filtered.map((ele: any) => ele.barcode).includes(value)) {
                Utils.showAlert(2, "Product is already in the list");
              } else if (getStoreId) {
                dispatch(getProductsBackendSearch(getStoreId, modifiedBarcode));
              } else {
                Utils.showAlert(2, "Store Id not found");
              }
            } else if (name === "Return") {
              // Dispatch action to get products for Return
              if (getStoreId) {
                dispatch(getProductsBackendSearch(getStoreId, modifiedBarcode));
              } else {
                Utils.showAlert(2, "Store Id not found");
              }
            }
          }
        }}
        autoComplete="off"
      />
    </Box>
  );
}
