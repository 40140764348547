import React, { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material/';
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import moment from 'moment';
import { useDispatch } from "react-redux";
import { PosDashboard } from './action';

const useStyles = makeStyles((theme:any) => ({
  toggleButtonGroup: {
    // marginBottom: theme.spacing(2),
  },
}));

const TodayYesterdayToggle = () => {
  const classes = useStyles();
  const [date, setDate] = useState('today');

  const handleDateChange = (event:any, newDate:any) => {
    setDate(newDate);
  };

  const todayFromDate = moment().format('YYYY-MM-DD') + "T00:00"
  const todayToDate = moment().format('YYYY-MM-DD') + "T23:59"

  const yesterdayFromDate = moment().subtract(1, 'days').format('YYYY-MM-DD') + "T00:00"
  const yesterdayToDate = moment().subtract(1, 'days').format('YYYY-MM-DD') + "T23:59"
  const dispatch: any = useDispatch();

  React.useEffect(() => {
  }, [dispatch]);

const handleTodayChange = () =>{
    dispatch(PosDashboard(todayFromDate,todayToDate));
}

const handleYesterdayChange = () =>{
    dispatch(PosDashboard(yesterdayFromDate,yesterdayToDate));
}

  return (
    <>
      <ToggleButtonGroup
        className={classes.toggleButtonGroup}
        value={date}
        exclusive
        onChange={handleDateChange}
        aria-label="Today or Yesterday"
      >
        <ToggleButton value="today" aria-label="Today" onClick={handleTodayChange}>
          <Typography>Today</Typography>
        </ToggleButton>
        <ToggleButton value="yesterday" aria-label="Yesterday" onClick={handleYesterdayChange}>
          <Typography>Yesterday</Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

export default TodayYesterdayToggle;
