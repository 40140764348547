import Utils from "../../utility"

export const CreditByStore = (storeId:any,search:string|number) => {
    return (dispatch: any,getState:any) => {
        dispatch({
            type: Utils.ActionName.LOADING,
            payload: { loading: true },
        })
        const {
            order,
            offset,
            take,
          } = getState().creditsReducer
        Utils.api.getApiCall(
            Utils.endPoints.credit,
            `/${storeId}?search=${search?search:""}&order=${order}&offset=${offset}&take=${take}`,
            (respData: any) => {
                dispatch({
                    type: Utils.ActionName.CREDIT,
                    payload: {
                        count:respData?.data?.count,
                        creditData:respData?.data?.result,
                        loading: false,
                    },
                })
                dispatch({
                    type: Utils.ActionName.LOADING,
                    payload: { loading: false },
                })
            },
            (error: any) => {
                dispatch({
                    type: Utils.ActionName.LOADING,
                    payload: { loading: false },
                })
                let { data } = error
                if(data?.message === "" || !data) {
                    Utils.showAlert(2,"POS Credit Get Error")
                }else{
                    Utils.showAlert(2,"POS Credit Get Error: " +  data?.message)
                }
            },
        )
    }
}

export const creditEmailAndSms = (
    storeId: any,
    customerId:any
  ) => {
    return () => {
      let dataToSend = {}
      dataToSend = {
        customer:customerId
      }
      Utils.api.postApiCall(
        Utils.endPoints.credit + `/${storeId}`,
        dataToSend,
        (data:any) => {
          Utils.showAlert(1, data.data.status)
        },
        (error: any) => {
          let { data } = error
          Utils.showAlert(2, data?.message)
        },
      )
    }
  }
  

export const CreditByStoreId = (customerId:any,storeId:any) => {
    return (dispatch: any,getState:any) => {
        dispatch({
            type: Utils.ActionName.LOADING,
            payload: { loading: true },
        })
        Utils.api.getApiCall(
            Utils.endPoints.credit,
            `?customer=${customerId}&store=${storeId}&status=credit&sortField=createdAt&order=ASC&offset=1&take=10`,
            (respData: any) => {
                dispatch({
                    type: Utils.ActionName.CREDIT,
                    payload: {
                        count:respData?.data?.count,
                        customerCreditData:respData?.data?.results,
                        loading: false,
                    },
                })
                dispatch({
                    type: Utils.ActionName.LOADING,
                    payload: { loading: false },
                })
            },
            (error: any) => {
                dispatch({
                    type: Utils.ActionName.LOADING,
                    payload: { loading: false },
                })
                let { data } = error
                if(data?.message === "" || !data) {
                    Utils.showAlert(2,"POS Credit Get Error")
                }else{
                    Utils.showAlert(2,"POS Credit Get Error: " +  data?.message)
                }
            },
        )
    }
}

export const updateCredit = (storeId: any,customerId:number,amount:string,id:any,type:string,setOpen:any) => {
    return (dispatch: any) => {
        let dataToSend = {
            customer: customerId,
            ids: id,
            payments: amount,
            paymentType: type
        }
        Utils.api.putApiCall(
            // eslint-disable-next-line
            Utils.endPoints.credit + `/${storeId}`,
            dataToSend,
            () => {
                Utils.showAlert(1, 'Credit Paid')
                dispatch(CreditByStoreId(customerId,storeId))
                setOpen(false)
            },
            (error: any) => {
                let { data } = error
                if(data?.error === "" || !data) {
                    Utils.showAlert(2,"Credit Update Error")
                }else{
                    Utils.showAlert(2,"Credit Update Error: " +  data?.error)
                }
            },
        )
    }
}