import Utils from "../../utility"

export const updateCashout = (amount: any, reason: string, notes: any, storeId: any, setIsDisabled: any) => {
    return (dispatch: any) => {
        let dataToSend = {}
        Utils.api.putApiCall(
            // eslint-disable-next-line
            Utils.endPoints.cashOut + `?storeId=${parseInt(storeId)}&cashOutAmount=${amount}&reason=${notes !== "" ? reason + " " + "," + notes : reason}`,
            dataToSend,
            (respData: any) => {
                dispatch({
                    type: Utils.ActionName.CASHOUT,
                    payload: {
                        generatedMaps: respData.data,
                        addedTime: respData.data?.addDate,
                        loading: false,
                        addedReason:respData.data?.reason,
                        addedCashout: respData.data?.cashOutAmount,
                        userDoneBy: respData.data?.doneBy?.userName
                    },
                })
                Utils.showAlert(1, 'Success')
                setIsDisabled(false)
            },
            (error: any) => {
                let { data } = error
                if(data?.message === "" || !data) {
                    Utils.showAlert(2,"Cashout Update Error")
                }else{
                    Utils.showAlert(2,"Cashout Update Error: " +  data?.message)
                }
                // Utils.showAlert(2, data?.message)
            },
        )
    }
}