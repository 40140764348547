import axios from "axios";

export const getToken = () =>
	localStorage.getItem("accessToken")
		? localStorage.getItem("accessToken")
		: null;
const getAuthorizationHeader = () => getToken();
/**
 * setup axios instance
 */

const currentURL = window.location.href;

let apiUrl;

if (currentURL.includes("https://uatpos.thenewshop.in/")) {
	apiUrl = "https://uatapi.thenewshop.in/";
} else if (currentURL.includes("https://pos.thenewshop.in/")) {
	apiUrl = "https://api.thenewshop.in/";
} else if (currentURL.includes("https://betapos.thenewshop.in/")) {
	apiUrl = "https://betaapi.thenewshop.in/";
} else {
	apiUrl = "http://localhost:5000/";
}

const $axios = axios.create({
	timeout: 100000,
	baseURL: apiUrl,
	headers: {
		Authorization: `${localStorage.getItem("accessToken") || ""}`,
	},
});
$axios.interceptors.request.use(
	(config) => {
		const user = getAuthorizationHeader();
		if (user) {
			config.headers["Authorization"] = `${user}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);
$axios.interceptors.response.use(
	(config) => {
		const user = getAuthorizationHeader();
		if (user) {
			config.headers["Authorization"] = `${user}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);
const api_header_code = {
	alreadyEnabled2fa: 2003,
};
const api_error_code = {
	unauthorized: 401,
	accessDenied: 430,
	sessionExpired: 440,
	validationError: 400,
	emailNotVerified: 403,
};

const api_success_code = {
	postSuccess: 201,
	success: 200,
};

const insuranceCompany = {
	MAD: 11,
};

const response_alert = {
	INFORMATION_ALERT_WITHOUT_ACTION: 1,
	ALERT_WITH_ACTION: 2,
	ALERT_WITHOUT_ACTION: 3,
	CONNECTION_ERROR: 4,
	CONTACT_SUPPORT: 5,
};

const user_status = {
	ACTIVE: "1",
	NOT_ACTIVE: "2",
	MODERATION: "3",
	REFUSAL_TO_REGISTRATION: "4",
	BAN: "5",
	IN_REGISTRATION_PROCESS: "6",
};

const policy_status = {
	ACTIVE: "1",
	EXPIRED: "2",
	BALANCE_INSUFFICIENT: "3",
	BENEFITS_UPDATING: "4",
	BENEFITS_CHECK_FROM_LOCAL: "1",
	CLAIM_NOT_ALLOWED: "5",
};
// const API_URL = "https://usa.liviaapp.com";
// const API_URL = "https://liviastaging.appskeeper.in";
// const API_URL = "https://staging.liviaapp.com";
const API_URL = process.env.REACT_APP_API_URL;

/**
 * function to slugify url entered by user for business link
 * @param url
 */
// const slugifyUrl = (url: string) => {
//   var slugify = require("slugify");
//   return slugify(url, { remove: /[*+~.()'"!:@#$%!]/g, lower: true });
// };

// Retrieving the bucket name from env variable

const Bucket = process.env.REACT_APP_BUCKET_NAME;
const BASE_S3_URL = process.env.REACT_APP_S3_BASE_PATH;
const UserType = [
	{ value: "SA", text: "Super admin" },
	{ value: "B", text: "BILLER" },
	{ value: "WM", text: "Warehouse Manager" },
	{ value: "SM", text: "Store Manager" },
	{ value: "PO", text: "Property Owner" },
	{ value: "BO", text: "Brand Owner" },
	{ value: "CLM", text: "Cluster Manager" },
	{ value: "CAM", text: "Catalog Manager" },
	{ value: "AM", text: "Accounts Manager" },
	{ value: "OM", text: "Opration Manager" },
	{ value: "R", text: "Runner" },
	{ value: "C", text: "" },
];

/**
 * state and city
 */
const states = [
	{
		abbreviation: "AN",
		name: "Andaman and Nicobar Islands",
	},
	{
		abbreviation: "AP",
		name: "Andhra Pradesh",
	},
	{
		abbreviation: "AR",
		name: "Arunachal Pradesh",
	},
	{
		abbreviation: "AS",
		name: "Assam",
	},
	{
		abbreviation: "BR",
		name: "Bihar",
	},
	{
		abbreviation: "CG",
		name: "Chandigarh",
	},
	{
		abbreviation: "CH",
		name: "Chhattisgarh",
	},
	{
		abbreviation: "DH",
		name: "Dadra and Nagar Haveli",
	},
	{
		abbreviation: "DD",
		name: "Daman and Diu",
	},
	{
		abbreviation: "DL",
		name: "Delhi",
	},
	{
		abbreviation: "GA",
		name: "Goa",
	},
	{
		abbreviation: "GJ",
		name: "Gujarat",
	},
	{
		abbreviation: "HR",
		name: "Haryana",
	},
	{
		abbreviation: "HP",
		name: "Himachal Pradesh",
	},
	{
		abbreviation: "JK",
		name: "Jammu and Kashmir",
	},
	{
		abbreviation: "JH",
		name: "Jharkhand",
	},
	{
		abbreviation: "KA",
		name: "Karnataka",
	},
	{
		abbreviation: "KL",
		name: "Kerala",
	},
	{
		abbreviation: "LD",
		name: "Lakshadweep",
	},
	{
		abbreviation: "MP",
		name: "Madhya Pradesh",
	},
	{
		abbreviation: "MH",
		name: "Maharashtra",
	},
	{
		abbreviation: "MN",
		name: "Manipur",
	},
	{
		abbreviation: "ML",
		name: "Meghalaya",
	},
	{
		abbreviation: "MZ",
		name: "Mizoram",
	},
	{
		abbreviation: "NL",
		name: "Nagaland",
	},
	{
		abbreviation: "OR",
		name: "Odisha",
	},
	{
		abbreviation: "PY",
		name: "Puducherry",
	},
	{
		abbreviation: "PB",
		name: "Punjab",
	},
	{
		abbreviation: "RJ",
		name: "Rajasthan",
	},
	{
		abbreviation: "SK",
		name: "Sikkim",
	},
	{
		abbreviation: "TN",
		name: "Tamil Nadu",
	},
	{
		abbreviation: "TS",
		name: "Telangana",
	},
	{
		abbreviation: "TR",
		name: "Tripura",
	},
	{
		abbreviation: "UP",
		name: "Uttar Pradesh",
	},
	{
		abbreviation: "UK",
		name: "Uttarakhand",
	},
	{
		abbreviation: "WB",
		name: "West Bengal",
	},
];
const cities = {
	Maharashtra: [
		{
			id: "0",
			city: "Mumbai",
		},
		{
			id: "7",
			city: "Pune",
		},
		{
			id: "12",
			city: "Nagpur",
		},
		{
			id: "15",
			city: "Thane",
		},
		{
			id: "24",
			city: "Nashik",
		},
		{
			id: "28",
			city: "Kalyan-Dombivali",
		},
		{
			id: "29",
			city: "Vasai-Virar",
		},
		{
			id: "49",
			city: "Solapur",
		},
		{
			id: "56",
			city: "Mira-Bhayandar",
		},
		{
			id: "58",
			city: "Bhiwandi",
		},
		{
			id: "61",
			city: "Amravati",
		},
		{
			id: "72",
			city: "Nanded-Waghala",
		},
		{
			id: "76",
			city: "Sangli",
		},
		{
			id: "86",
			city: "Malegaon",
		},
		{
			id: "91",
			city: "Akola",
		},
		{
			id: "98",
			city: "Latur",
		},
		{
			id: "99",
			city: "Dhule",
		},
		{
			id: "105",
			city: "Ahmednagar",
		},
		{
			id: "122",
			city: "Ichalkaranji",
		},
		{
			id: "138",
			city: "Parbhani",
		},
		{
			id: "164",
			city: "Panvel",
		},
		{
			id: "278",
			city: "Yavatmal",
		},
		{
			id: "292",
			city: "Achalpur",
		},
		{
			id: "293",
			city: "Osmanabad",
		},
		{
			id: "295",
			city: "Nandurbar",
		},
		{
			id: "306",
			city: "Satara",
		},
		{
			id: "310",
			city: "Wardha",
		},
		{
			id: "316",
			city: "Udgir",
		},
		{
			id: "320",
			city: "Aurangabad",
		},
		{
			id: "344",
			city: "Amalner",
		},
		{
			id: "354",
			city: "Akot",
		},
		{
			id: "359",
			city: "Pandharpur",
		},
		{
			id: "365",
			city: "Shrirampur",
		},
		{
			id: "367",
			city: "Parli",
		},
		{
			id: "409",
			city: "Washim",
		},
		{
			id: "423",
			city: "Ambejogai",
		},
		{
			id: "435",
			city: "Manmad",
		},
		{
			id: "450",
			city: "Ratnagiri",
		},
		{
			id: "462",
			city: "Uran Islampur",
		},
		{
			id: "466",
			city: "Pusad",
		},
		{
			id: "502",
			city: "Sangamner",
		},
		{
			id: "508",
			city: "Shirpur-Warwade",
		},
		{
			id: "512",
			city: "Malkapur",
		},
		{
			id: "524",
			city: "Wani",
		},
		{
			id: "538",
			city: "Lonavla",
		},
		{
			id: "551",
			city: "Talegaon Dabhade",
		},
		{
			id: "552",
			city: "Anjangaon",
		},
		{
			id: "572",
			city: "Umred",
		},
		{
			id: "589",
			city: "Palghar",
		},
		{
			id: "590",
			city: "Shegaon",
		},
		{
			id: "603",
			city: "Ozar",
		},
		{
			id: "607",
			city: "Phaltan",
		},
		{
			id: "621",
			city: "Yevla",
		},
		{
			id: "625",
			city: "Shahade",
		},
		{
			id: "640",
			city: "Vita",
		},
		{
			id: "649",
			city: "Umarkhed",
		},
		{
			id: "657",
			city: "Warora",
		},
		{
			id: "670",
			city: "Pachora",
		},
		{
			id: "673",
			city: "Tumsar",
		},
		{
			id: "687",
			city: "Manjlegaon",
		},
		{
			id: "689",
			city: "Sillod",
		},
		{
			id: "697",
			city: "Arvi",
		},
		{
			id: "707",
			city: "Nandura",
		},
		{
			id: "719",
			city: "Vaijapur",
		},
		{
			id: "724",
			city: "Wadgaon Road",
		},
		{
			id: "735",
			city: "Sailu",
		},
		{
			id: "747",
			city: "Murtijapur",
		},
		{
			id: "755",
			city: "Tasgaon",
		},
		{
			id: "760",
			city: "Mehkar",
		},
		{
			id: "779",
			city: "Yawal",
		},
		{
			id: "782",
			city: "Pulgaon",
		},
		{
			id: "787",
			city: "Nilanga",
		},
		{
			id: "790",
			city: "Wai",
		},
		{
			id: "799",
			city: "Umarga",
		},
		{
			id: "809",
			city: "Paithan",
		},
		{
			id: "810",
			city: "Rahuri",
		},
		{
			id: "816",
			city: "Nawapur",
		},
		{
			id: "823",
			city: "Tuljapur",
		},
		{
			id: "835",
			city: "Morshi",
		},
		{
			id: "841",
			city: "Purna",
		},
		{
			id: "855",
			city: "Satana",
		},
		{
			id: "877",
			city: "Pathri",
		},
		{
			id: "880",
			city: "Sinnar",
		},
		{
			id: "890",
			city: "Uchgaon",
		},
		{
			id: "900",
			city: "Uran",
		},
		{
			id: "903",
			city: "Pen",
		},
		{
			id: "913",
			city: "Karjat",
		},
		{
			id: "927",
			city: "Manwath",
		},
		{
			id: "930",
			city: "Partur",
		},
		{
			id: "947",
			city: "Sangole",
		},
		{
			id: "956",
			city: "Mangrulpir",
		},
		{
			id: "967",
			city: "Risod",
		},
		{
			id: "977",
			city: "Shirur",
		},
		{
			id: "983",
			city: "Savner",
		},
		{
			id: "984",
			city: "Sasvad",
		},
		{
			id: "988",
			city: "Pandharkaoda",
		},
		{
			id: "992",
			city: "Talode",
		},
		{
			id: "993",
			city: "Shrigonda",
		},
		{
			id: "997",
			city: "Shirdi",
		},
		{
			id: "1000",
			city: "Raver",
		},
		{
			id: "1003",
			city: "Mukhed",
		},
		{
			id: "1007",
			city: "Rajura",
		},
		{
			id: "1010",
			city: "Vadgaon Kasba",
		},
		{
			id: "1023",
			city: "Tirora",
		},
		{
			id: "1047",
			city: "Mahad",
		},
		{
			id: "1069",
			city: "Lonar",
		},
		{
			id: "1088",
			city: "Sawantwadi",
		},
		{
			id: "1092",
			city: "Pathardi",
		},
		{
			id: "1102",
			city: "Pauni",
		},
		{
			id: "1104",
			city: "Ramtek",
		},
		{
			id: "1109",
			city: "Mul",
		},
		{
			id: "1131",
			city: "Soyagaon",
		},
		{
			id: "1135",
			city: "Mangalvedhe",
		},
		{
			id: "1152",
			city: "Narkhed",
		},
		{
			id: "1154",
			city: "Shendurjana",
		},
		{
			id: "1172",
			city: "Patur",
		},
		{
			id: "1176",
			city: "Mhaswad",
		},
		{
			id: "1187",
			city: "Loha",
		},
		{
			id: "1203",
			city: "Nandgaon",
		},
		{
			id: "1212",
			city: "Warud",
		},
	],
	Delhi: [
		{
			id: "1",
			city: "Delhi",
		},
		{
			id: "142",
			city: "New Delhi",
		},
	],
	Karnataka: [
		{
			id: "2",
			city: "Bengaluru",
		},
		{
			id: "44",
			city: "Hubli-Dharwad",
		},
		{
			id: "82",
			city: "Belagavi",
		},
		{
			id: "84",
			city: "Mangaluru",
		},
		{
			id: "89",
			city: "Davanagere",
		},
		{
			id: "95",
			city: "Ballari",
		},
		{
			id: "115",
			city: "Tumkur",
		},
		{
			id: "127",
			city: "Shivamogga",
		},
		{
			id: "163",
			city: "Raayachuru",
		},
		{
			id: "211",
			city: "Robertson Pet",
		},
		{
			id: "245",
			city: "Kolar",
		},
		{
			id: "247",
			city: "Mandya",
		},
		{
			id: "260",
			city: "Udupi",
		},
		{
			id: "272",
			city: "Chikkamagaluru",
		},
		{
			id: "300",
			city: "Karwar",
		},
		{
			id: "311",
			city: "Ranebennuru",
		},
		{
			id: "363",
			city: "Ranibennur",
		},
		{
			id: "401",
			city: "Ramanagaram",
		},
		{
			id: "402",
			city: "Gokak",
		},
		{
			id: "422",
			city: "Yadgir",
		},
		{
			id: "451",
			city: "Rabkavi Banhatti",
		},
		{
			id: "472",
			city: "Shahabad",
		},
		{
			id: "480",
			city: "Sirsi",
		},
		{
			id: "510",
			city: "Sindhnur",
		},
		{
			id: "521",
			city: "Tiptur",
		},
		{
			id: "602",
			city: "Arsikere",
		},
		{
			id: "609",
			city: "Nanjangud",
		},
		{
			id: "613",
			city: "Sagara",
		},
		{
			id: "615",
			city: "Sira",
		},
		{
			id: "642",
			city: "Puttur",
		},
		{
			id: "664",
			city: "Athni",
		},
		{
			id: "686",
			city: "Mulbagal",
		},
		{
			id: "691",
			city: "Surapura",
		},
		{
			id: "696",
			city: "Siruguppa",
		},
		{
			id: "702",
			city: "Mudhol",
		},
		{
			id: "721",
			city: "Sidlaghatta",
		},
		{
			id: "743",
			city: "Shahpur",
		},
		{
			id: "751",
			city: "Saundatti-Yellamma",
		},
		{
			id: "753",
			city: "Wadi",
		},
		{
			id: "763",
			city: "Manvi",
		},
		{
			id: "769",
			city: "Nelamangala",
		},
		{
			id: "777",
			city: "Lakshmeshwar",
		},
		{
			id: "781",
			city: "Ramdurg",
		},
		{
			id: "784",
			city: "Nargund",
		},
		{
			id: "791",
			city: "Tarikere",
		},
		{
			id: "792",
			city: "Malavalli",
		},
		{
			id: "796",
			city: "Savanur",
		},
		{
			id: "801",
			city: "Lingsugur",
		},
		{
			id: "806",
			city: "Vijayapura",
		},
		{
			id: "857",
			city: "Sankeshwara",
		},
		{
			id: "858",
			city: "Madikeri",
		},
		{
			id: "879",
			city: "Talikota",
		},
		{
			id: "882",
			city: "Sedam",
		},
		{
			id: "883",
			city: "Shikaripur",
		},
		{
			id: "896",
			city: "Mahalingapura",
		},
		{
			id: "910",
			city: "Mudalagi",
		},
		{
			id: "943",
			city: "Muddebihal",
		},
		{
			id: "948",
			city: "Pavagada",
		},
		{
			id: "955",
			city: "Malur",
		},
		{
			id: "961",
			city: "Sindhagi",
		},
		{
			id: "962",
			city: "Sanduru",
		},
		{
			id: "976",
			city: "Afzalpur",
		},
		{
			id: "990",
			city: "Maddur",
		},
		{
			id: "994",
			city: "Madhugiri",
		},
		{
			id: "995",
			city: "Tekkalakote",
		},
		{
			id: "999",
			city: "Terdal",
		},
		{
			id: "1009",
			city: "Mudabidri",
		},
		{
			id: "1027",
			city: "Magadi",
		},
		{
			id: "1036",
			city: "Navalgund",
		},
		{
			id: "1042",
			city: "Shiggaon",
		},
		{
			id: "1058",
			city: "Shrirangapattana",
		},
		{
			id: "1074",
			city: "Sindagi",
		},
		{
			id: "1079",
			city: "Sakaleshapura",
		},
		{
			id: "1085",
			city: "Srinivaspur",
		},
		{
			id: "1137",
			city: "Ron",
		},
		{
			id: "1179",
			city: "Mundargi",
		},
		{
			id: "1184",
			city: "Sadalagi",
		},
		{
			id: "1200",
			city: "Piriyapatna",
		},
		{
			id: "1210",
			city: "Adyar",
		},
	],
	Gujarat: [
		{
			id: "3",
			city: "Ahmedabad",
		},
		{
			id: "9",
			city: "Surat",
		},
		{
			id: "18",
			city: "Vadodara",
		},
		{
			id: "21",
			city: "Rajkot",
		},
		{
			id: "69",
			city: "Bhavnagar",
		},
		{
			id: "74",
			city: "Jamnagar",
		},
		{
			id: "157",
			city: "Nadiad",
		},
		{
			id: "170",
			city: "Porbandar",
		},
		{
			id: "172",
			city: "Anand",
		},
		{
			id: "176",
			city: "Morvi",
		},
		{
			id: "183",
			city: "Mahesana",
		},
		{
			id: "197",
			city: "Bharuch",
		},
		{
			id: "206",
			city: "Vapi",
		},
		{
			id: "208",
			city: "Navsari",
		},
		{
			id: "219",
			city: "Veraval",
		},
		{
			id: "233",
			city: "Bhuj",
		},
		{
			id: "240",
			city: "Godhra",
		},
		{
			id: "257",
			city: "Palanpur",
		},
		{
			id: "284",
			city: "Valsad",
		},
		{
			id: "289",
			city: "Patan",
		},
		{
			id: "294",
			city: "Deesa",
		},
		{
			id: "312",
			city: "Amreli",
		},
		{
			id: "378",
			city: "Anjar",
		},
		{
			id: "385",
			city: "Dhoraji",
		},
		{
			id: "389",
			city: "Khambhat",
		},
		{
			id: "396",
			city: "Mahuva",
		},
		{
			id: "414",
			city: "Keshod",
		},
		{
			id: "416",
			city: "Wadhwan",
		},
		{
			id: "424",
			city: "Ankleshwar",
		},
		{
			id: "425",
			city: "Savarkundla",
		},
		{
			id: "429",
			city: "Kadi",
		},
		{
			id: "493",
			city: "Visnagar",
		},
		{
			id: "525",
			city: "Upleta",
		},
		{
			id: "529",
			city: "Una",
		},
		{
			id: "533",
			city: "Sidhpur",
		},
		{
			id: "545",
			city: "Unjha",
		},
		{
			id: "553",
			city: "Mangrol",
		},
		{
			id: "560",
			city: "Viramgam",
		},
		{
			id: "568",
			city: "Modasa",
		},
		{
			id: "596",
			city: "Palitana",
		},
		{
			id: "605",
			city: "Petlad",
		},
		{
			id: "628",
			city: "Kapadvanj",
		},
		{
			id: "654",
			city: "Sihor",
		},
		{
			id: "688",
			city: "Wankaner",
		},
		{
			id: "698",
			city: "Limbdi",
		},
		{
			id: "704",
			city: "Mandvi",
		},
		{
			id: "705",
			city: "Thangadh",
		},
		{
			id: "736",
			city: "Vyara",
		},
		{
			id: "740",
			city: "Padra",
		},
		{
			id: "771",
			city: "Lunawada",
		},
		{
			id: "805",
			city: "Rajpipla",
		},
		{
			id: "818",
			city: "Vapi",
		},
		{
			id: "828",
			city: "Umreth",
		},
		{
			id: "860",
			city: "Sanand",
		},
		{
			id: "861",
			city: "Rajula",
		},
		{
			id: "871",
			city: "Radhanpur",
		},
		{
			id: "898",
			city: "Mahemdabad",
		},
		{
			id: "914",
			city: "Ranavav",
		},
		{
			id: "949",
			city: "Tharad",
		},
		{
			id: "950",
			city: "Mansa",
		},
		{
			id: "951",
			city: "Umbergaon",
		},
		{
			id: "954",
			city: "Talaja",
		},
		{
			id: "959",
			city: "Vadnagar",
		},
		{
			id: "964",
			city: "Manavadar",
		},
		{
			id: "978",
			city: "Salaya",
		},
		{
			id: "1012",
			city: "Vijapur",
		},
		{
			id: "1019",
			city: "Pardi",
		},
		{
			id: "1082",
			city: "Rapar",
		},
		{
			id: "1106",
			city: "Songadh",
		},
		{
			id: "1150",
			city: "Lathi",
		},
		{
			id: "1202",
			city: "Adalaj",
		},
		{
			id: "1205",
			city: "Chhapra",
		},
	],
	Telangana: [
		{
			id: "4",
			city: "Hyderabad",
		},
		{
			id: "55",
			city: "Warangal",
		},
		{
			id: "113",
			city: "Nizamabad",
		},
		{
			id: "137",
			city: "Karimnagar",
		},
		{
			id: "145",
			city: "Ramagundam",
		},
		{
			id: "182",
			city: "Khammam",
		},
		{
			id: "209",
			city: "Mahbubnagar",
		},
		{
			id: "275",
			city: "Mancherial",
		},
		{
			id: "277",
			city: "Adilabad",
		},
		{
			id: "309",
			city: "Suryapet",
		},
		{
			id: "342",
			city: "Jagtial",
		},
		{
			id: "360",
			city: "Miryalaguda",
		},
		{
			id: "369",
			city: "Nirmal",
		},
		{
			id: "398",
			city: "Kamareddy",
		},
		{
			id: "400",
			city: "Kothagudem",
		},
		{
			id: "411",
			city: "Bodhan",
		},
		{
			id: "455",
			city: "Palwancha",
		},
		{
			id: "471",
			city: "Mandamarri",
		},
		{
			id: "474",
			city: "Koratla",
		},
		{
			id: "481",
			city: "Sircilla",
		},
		{
			id: "486",
			city: "Tandur",
		},
		{
			id: "504",
			city: "Siddipet",
		},
		{
			id: "513",
			city: "Wanaparthy",
		},
		{
			id: "540",
			city: "Kagaznagar",
		},
		{
			id: "541",
			city: "Gadwal",
		},
		{
			id: "544",
			city: "Sangareddy",
		},
		{
			id: "573",
			city: "Bellampalle",
		},
		{
			id: "583",
			city: "Bhongir",
		},
		{
			id: "586",
			city: "Vikarabad",
		},
		{
			id: "591",
			city: "Jangaon",
		},
		{
			id: "616",
			city: "Bhadrachalam",
		},
		{
			id: "623",
			city: "Bhainsa",
		},
		{
			id: "667",
			city: "Farooqnagar",
		},
		{
			id: "712",
			city: "Medak",
		},
		{
			id: "713",
			city: "Narayanpet",
		},
		{
			id: "783",
			city: "Sadasivpet",
		},
		{
			id: "832",
			city: "Yellandu",
		},
		{
			id: "848",
			city: "Manuguru",
		},
		{
			id: "862",
			city: "Kyathampalle",
		},
		{
			id: "982",
			city: "Nagarkurnool",
		},
	],
	"Tamil Nadu": [
		{
			id: "5",
			city: "Chennai",
		},
		{
			id: "37",
			city: "Coimbatore",
		},
		{
			id: "41",
			city: "Madurai",
		},
		{
			id: "52",
			city: "Tiruchirappalli",
		},
		{
			id: "54",
			city: "Salem",
		},
		{
			id: "85",
			city: "Tirunelveli",
		},
		{
			id: "88",
			city: "Tiruppur",
		},
		{
			id: "134",
			city: "Ranipet",
		},
		{
			id: "152",
			city: "Nagercoil",
		},
		{
			id: "153",
			city: "Thanjavur",
		},
		{
			id: "180",
			city: "Vellore",
		},
		{
			id: "205",
			city: "Kancheepuram",
		},
		{
			id: "212",
			city: "Erode",
		},
		{
			id: "237",
			city: "Tiruvannamalai",
		},
		{
			id: "253",
			city: "Pollachi",
		},
		{
			id: "267",
			city: "Rajapalayam",
		},
		{
			id: "270",
			city: "Sivakasi",
		},
		{
			id: "303",
			city: "Pudukkottai",
		},
		{
			id: "313",
			city: "Neyveli (TS)",
		},
		{
			id: "318",
			city: "Nagapattinam",
		},
		{
			id: "343",
			city: "Viluppuram",
		},
		{
			id: "347",
			city: "Tiruchengode",
		},
		{
			id: "350",
			city: "Vaniyambadi",
		},
		{
			id: "352",
			city: "Theni Allinagaram",
		},
		{
			id: "370",
			city: "Udhagamandalam",
		},
		{
			id: "373",
			city: "Aruppukkottai",
		},
		{
			id: "386",
			city: "Paramakudi",
		},
		{
			id: "408",
			city: "Arakkonam",
		},
		{
			id: "427",
			city: "Virudhachalam",
		},
		{
			id: "430",
			city: "Srivilliputhur",
		},
		{
			id: "432",
			city: "Tindivanam",
		},
		{
			id: "438",
			city: "Virudhunagar",
		},
		{
			id: "441",
			city: "Karur",
		},
		{
			id: "442",
			city: "Valparai",
		},
		{
			id: "448",
			city: "Sankarankovil",
		},
		{
			id: "449",
			city: "Tenkasi",
		},
		{
			id: "465",
			city: "Palani",
		},
		{
			id: "479",
			city: "Pattukkottai",
		},
		{
			id: "488",
			city: "Tirupathur",
		},
		{
			id: "498",
			city: "Ramanathapuram",
		},
		{
			id: "511",
			city: "Udumalaipettai",
		},
		{
			id: "522",
			city: "Gobichettipalayam",
		},
		{
			id: "532",
			city: "Thiruvarur",
		},
		{
			id: "556",
			city: "Thiruvallur",
		},
		{
			id: "564",
			city: "Panruti",
		},
		{
			id: "566",
			city: "Namakkal",
		},
		{
			id: "604",
			city: "Thirumangalam",
		},
		{
			id: "651",
			city: "Vikramasingapuram",
		},
		{
			id: "655",
			city: "Nellikuppam",
		},
		{
			id: "659",
			city: "Rasipuram",
		},
		{
			id: "675",
			city: "Tiruttani",
		},
		{
			id: "685",
			city: "Nandivaram-Guduvancheri",
		},
		{
			id: "710",
			city: "Periyakulam",
		},
		{
			id: "716",
			city: "Pernampattu",
		},
		{
			id: "731",
			city: "Vellakoil",
		},
		{
			id: "733",
			city: "Sivaganga",
		},
		{
			id: "738",
			city: "Vadalur",
		},
		{
			id: "754",
			city: "Rameshwaram",
		},
		{
			id: "758",
			city: "Tiruvethipuram",
		},
		{
			id: "762",
			city: "Perambalur",
		},
		{
			id: "802",
			city: "Usilampatti",
		},
		{
			id: "815",
			city: "Vedaranyam",
		},
		{
			id: "833",
			city: "Sathyamangalam",
		},
		{
			id: "842",
			city: "Puliyankudi",
		},
		{
			id: "852",
			city: "Nanjikottai",
		},
		{
			id: "859",
			city: "Thuraiyur",
		},
		{
			id: "870",
			city: "Sirkali",
		},
		{
			id: "872",
			city: "Tiruchendur",
		},
		{
			id: "876",
			city: "Periyasemur",
		},
		{
			id: "885",
			city: "Sattur",
		},
		{
			id: "888",
			city: "Vandavasi",
		},
		{
			id: "901",
			city: "Tharamangalam",
		},
		{
			id: "902",
			city: "Tirukkoyilur",
		},
		{
			id: "906",
			city: "Oddanchatram",
		},
		{
			id: "907",
			city: "Palladam",
		},
		{
			id: "920",
			city: "Vadakkuvalliyur",
		},
		{
			id: "921",
			city: "Tirukalukundram",
		},
		{
			id: "929",
			city: "Uthamapalayam",
		},
		{
			id: "946",
			city: "Surandai",
		},
		{
			id: "969",
			city: "Sankari",
		},
		{
			id: "979",
			city: "Shenkottai",
		},
		{
			id: "981",
			city: "Vadipatti",
		},
		{
			id: "987",
			city: "Sholingur",
		},
		{
			id: "1001",
			city: "Tirupathur",
		},
		{
			id: "1004",
			city: "Manachanallur",
		},
		{
			id: "1013",
			city: "Viswanatham",
		},
		{
			id: "1014",
			city: "Polur",
		},
		{
			id: "1015",
			city: "Panagudi",
		},
		{
			id: "1022",
			city: "Uthiramerur",
		},
		{
			id: "1041",
			city: "Thiruthuraipoondi",
		},
		{
			id: "1043",
			city: "Pallapatti",
		},
		{
			id: "1046",
			city: "Ponneri",
		},
		{
			id: "1057",
			city: "Lalgudi",
		},
		{
			id: "1060",
			city: "Natham",
		},
		{
			id: "1061",
			city: "Unnamalaikadai",
		},
		{
			id: "1071",
			city: "P.N.Patti",
		},
		{
			id: "1078",
			city: "Tharangambadi",
		},
		{
			id: "1089",
			city: "Tittakudi",
		},
		{
			id: "1094",
			city: "Pacode",
		},
		{
			id: "1123",
			city: "O' Valley",
		},
		{
			id: "1125",
			city: "Suriyampalayam",
		},
		{
			id: "1138",
			city: "Sholavandan",
		},
		{
			id: "1141",
			city: "Thammampatti",
		},
		{
			id: "1148",
			city: "Namagiripettai",
		},
		{
			id: "1155",
			city: "Peravurani",
		},
		{
			id: "1161",
			city: "Parangipettai",
		},
		{
			id: "1162",
			city: "Pudupattinam",
		},
		{
			id: "1169",
			city: "Pallikonda",
		},
		{
			id: "1178",
			city: "Sivagiri",
		},
		{
			id: "1180",
			city: "Punjaipugalur",
		},
		{
			id: "1189",
			city: "Padmanabhapuram",
		},
		{
			id: "1198",
			city: "Thirupuvanam",
		},
	],
	"West Bengal": [
		{
			id: "6",
			city: "Kolkata",
		},
		{
			id: "23",
			city: "Siliguri",
		},
		{
			id: "71",
			city: "Asansol",
		},
		{
			id: "107",
			city: "Raghunathganj",
		},
		{
			id: "120",
			city: "Kharagpur",
		},
		{
			id: "155",
			city: "Naihati",
		},
		{
			id: "159",
			city: "English Bazar",
		},
		{
			id: "174",
			city: "Baharampur",
		},
		{
			id: "188",
			city: "Hugli-Chinsurah",
		},
		{
			id: "189",
			city: "Raiganj",
		},
		{
			id: "196",
			city: "Jalpaiguri",
		},
		{
			id: "223",
			city: "Santipur",
		},
		{
			id: "224",
			city: "Balurghat",
		},
		{
			id: "229",
			city: "Medinipur",
		},
		{
			id: "230",
			city: "Habra",
		},
		{
			id: "235",
			city: "Ranaghat",
		},
		{
			id: "246",
			city: "Bankura",
		},
		{
			id: "259",
			city: "Nabadwip",
		},
		{
			id: "271",
			city: "Darjiling",
		},
		{
			id: "288",
			city: "Purulia",
		},
		{
			id: "476",
			city: "Arambagh",
		},
		{
			id: "482",
			city: "Tamluk",
		},
		{
			id: "484",
			city: "AlipurdUrban Agglomerationr",
		},
		{
			id: "505",
			city: "Suri",
		},
		{
			id: "507",
			city: "Jhargram",
		},
		{
			id: "555",
			city: "Gangarampur",
		},
		{
			id: "608",
			city: "Rampurhat",
		},
		{
			id: "695",
			city: "Kalimpong",
		},
		{
			id: "741",
			city: "Sainthia",
		},
		{
			id: "750",
			city: "Taki",
		},
		{
			id: "772",
			city: "Murshidabad",
		},
		{
			id: "786",
			city: "Memari",
		},
		{
			id: "892",
			city: "Paschim Punropara",
		},
		{
			id: "895",
			city: "Tarakeswar",
		},
		{
			id: "971",
			city: "Sonamukhi",
		},
		{
			id: "974",
			city: "PandUrban Agglomeration",
		},
		{
			id: "975",
			city: "Mainaguri",
		},
		{
			id: "1077",
			city: "Malda",
		},
		{
			id: "1119",
			city: "Panchla",
		},
		{
			id: "1124",
			city: "Raghunathpur",
		},
		{
			id: "1153",
			city: "Mathabhanga",
		},
		{
			id: "1166",
			city: "Monoharpur",
		},
		{
			id: "1192",
			city: "Srirampore",
		},
		{
			id: "1199",
			city: "Adra",
		},
	],
	Rajasthan: [
		{
			id: "8",
			city: "Jaipur",
		},
		{
			id: "33",
			city: "Jodhpur",
		},
		{
			id: "62",
			city: "Bikaner",
		},
		{
			id: "68",
			city: "Udaipur",
		},
		{
			id: "73",
			city: "Ajmer",
		},
		{
			id: "101",
			city: "Bhilwara",
		},
		{
			id: "111",
			city: "Alwar",
		},
		{
			id: "140",
			city: "Bharatpur",
		},
		{
			id: "146",
			city: "Pali",
		},
		{
			id: "175",
			city: "Barmer",
		},
		{
			id: "179",
			city: "Sikar",
		},
		{
			id: "203",
			city: "Tonk",
		},
		{
			id: "266",
			city: "Sadulpur",
		},
		{
			id: "324",
			city: "Sawai Madhopur",
		},
		{
			id: "330",
			city: "Nagaur",
		},
		{
			id: "358",
			city: "Makrana",
		},
		{
			id: "388",
			city: "Sujangarh",
		},
		{
			id: "395",
			city: "Sardarshahar",
		},
		{
			id: "478",
			city: "Ladnu",
		},
		{
			id: "490",
			city: "Ratangarh",
		},
		{
			id: "495",
			city: "Nokha",
		},
		{
			id: "503",
			city: "Nimbahera",
		},
		{
			id: "535",
			city: "Suratgarh",
		},
		{
			id: "561",
			city: "Rajsamand",
		},
		{
			id: "582",
			city: "Lachhmangarh",
		},
		{
			id: "600",
			city: "Rajgarh (Churu)",
		},
		{
			id: "606",
			city: "Nasirabad",
		},
		{
			id: "620",
			city: "Nohar",
		},
		{
			id: "674",
			city: "Phalodi",
		},
		{
			id: "709",
			city: "Nathdwara",
		},
		{
			id: "727",
			city: "Pilani",
		},
		{
			id: "732",
			city: "Merta City",
		},
		{
			id: "744",
			city: "Sojat",
		},
		{
			id: "785",
			city: "Neem-Ka-Thana",
		},
		{
			id: "797",
			city: "Sirohi",
		},
		{
			id: "800",
			city: "Pratapgarh",
		},
		{
			id: "807",
			city: "Rawatbhata",
		},
		{
			id: "808",
			city: "Sangaria",
		},
		{
			id: "813",
			city: "Lalsot",
		},
		{
			id: "834",
			city: "Pilibanga",
		},
		{
			id: "850",
			city: "Pipar City",
		},
		{
			id: "853",
			city: "Taranagar",
		},
		{
			id: "875",
			city: "Vijainagar, Ajmer",
		},
		{
			id: "884",
			city: "Sumerpur",
		},
		{
			id: "893",
			city: "Sagwara",
		},
		{
			id: "894",
			city: "Ramganj Mandi",
		},
		{
			id: "917",
			city: "Lakheri",
		},
		{
			id: "926",
			city: "Udaipurwati",
		},
		{
			id: "935",
			city: "Losal",
		},
		{
			id: "936",
			city: "Sri Madhopur",
		},
		{
			id: "937",
			city: "Ramngarh",
		},
		{
			id: "939",
			city: "Rawatsar",
		},
		{
			id: "940",
			city: "Rajakhera",
		},
		{
			id: "945",
			city: "Shahpura",
		},
		{
			id: "958",
			city: "Shahpura",
		},
		{
			id: "960",
			city: "Raisinghnagar",
		},
		{
			id: "970",
			city: "Malpura",
		},
		{
			id: "991",
			city: "Nadbai",
		},
		{
			id: "1006",
			city: "Sanchore",
		},
		{
			id: "1011",
			city: "Nagar",
		},
		{
			id: "1028",
			city: "Rajgarh (Alwar)",
		},
		{
			id: "1032",
			city: "Sheoganj",
		},
		{
			id: "1040",
			city: "Sadri",
		},
		{
			id: "1065",
			city: "Todaraisingh",
		},
		{
			id: "1084",
			city: "Todabhim",
		},
		{
			id: "1087",
			city: "Reengus",
		},
		{
			id: "1091",
			city: "Rajaldesar",
		},
		{
			id: "1110",
			city: "Sadulshahar",
		},
		{
			id: "1112",
			city: "Sambhar",
		},
		{
			id: "1113",
			city: "Prantij",
		},
		{
			id: "1116",
			city: "Mount Abu",
		},
		{
			id: "1130",
			city: "Mangrol",
		},
		{
			id: "1136",
			city: "Phulera",
		},
		{
			id: "1167",
			city: "Mandawa",
		},
		{
			id: "1170",
			city: "Pindwara",
		},
		{
			id: "1186",
			city: "Mandalgarh",
		},
		{
			id: "1197",
			city: "Takhatgarh",
		},
	],
	"Uttar Pradesh": [
		{
			id: "10",
			city: "Lucknow",
		},
		{
			id: "11",
			city: "Kanpur",
		},
		{
			id: "19",
			city: "Firozabad",
		},
		{
			id: "22",
			city: "Agra",
		},
		{
			id: "27",
			city: "Meerut",
		},
		{
			id: "30",
			city: "Varanasi",
		},
		{
			id: "36",
			city: "Allahabad",
		},
		{
			id: "45",
			city: "Amroha",
		},
		{
			id: "46",
			city: "Moradabad",
		},
		{
			id: "48",
			city: "Aligarh",
		},
		{
			id: "59",
			city: "Saharanpur",
		},
		{
			id: "63",
			city: "Noida",
		},
		{
			id: "77",
			city: "Loni",
		},
		{
			id: "78",
			city: "Jhansi",
		},
		{
			id: "109",
			city: "Shahjahanpur",
		},
		{
			id: "126",
			city: "Rampur",
		},
		{
			id: "129",
			city: "Modinagar",
		},
		{
			id: "133",
			city: "Hapur",
		},
		{
			id: "139",
			city: "Etawah",
		},
		{
			id: "156",
			city: "Sambhal",
		},
		{
			id: "177",
			city: "Orai",
		},
		{
			id: "178",
			city: "Bahraich",
		},
		{
			id: "187",
			city: "Unnao",
		},
		{
			id: "195",
			city: "Rae Bareli",
		},
		{
			id: "220",
			city: "Lakhimpur",
		},
		{
			id: "221",
			city: "Sitapur",
		},
		{
			id: "251",
			city: "Lalitpur",
		},
		{
			id: "263",
			city: "Pilibhit",
		},
		{
			id: "287",
			city: "Chandausi",
		},
		{
			id: "291",
			city: "Hardoi ",
		},
		{
			id: "296",
			city: "Azamgarh",
		},
		{
			id: "323",
			city: "Khair",
		},
		{
			id: "331",
			city: "Sultanpur",
		},
		{
			id: "346",
			city: "Tanda",
		},
		{
			id: "348",
			city: "Nagina",
		},
		{
			id: "361",
			city: "Shamli",
		},
		{
			id: "368",
			city: "Najibabad",
		},
		{
			id: "371",
			city: "Shikohabad",
		},
		{
			id: "452",
			city: "Sikandrabad",
		},
		{
			id: "460",
			city: "Shahabad, Hardoi",
		},
		{
			id: "468",
			city: "Pilkhuwa",
		},
		{
			id: "470",
			city: "Renukoot",
		},
		{
			id: "494",
			city: "Vrindavan",
		},
		{
			id: "499",
			city: "Ujhani",
		},
		{
			id: "501",
			city: "Laharpur",
		},
		{
			id: "509",
			city: "Tilhar",
		},
		{
			id: "534",
			city: "Sahaswan",
		},
		{
			id: "558",
			city: "Rath",
		},
		{
			id: "588",
			city: "Sherkot",
		},
		{
			id: "599",
			city: "Kalpi",
		},
		{
			id: "611",
			city: "Tundla",
		},
		{
			id: "635",
			city: "Sandila",
		},
		{
			id: "638",
			city: "Nanpara",
		},
		{
			id: "639",
			city: "Sardhana",
		},
		{
			id: "644",
			city: "Nehtaur",
		},
		{
			id: "648",
			city: "Seohara",
		},
		{
			id: "678",
			city: "Padrauna",
		},
		{
			id: "683",
			city: "Mathura",
		},
		{
			id: "684",
			city: "Thakurdwara",
		},
		{
			id: "739",
			city: "Nawabganj",
		},
		{
			id: "742",
			city: "Siana",
		},
		{
			id: "745",
			city: "Noorpur",
		},
		{
			id: "756",
			city: "Sikandra Rao",
		},
		{
			id: "768",
			city: "Puranpur",
		},
		{
			id: "775",
			city: "Rudauli",
		},
		{
			id: "780",
			city: "Thana Bhawan",
		},
		{
			id: "803",
			city: "Palia Kalan",
		},
		{
			id: "812",
			city: "Zaidpur",
		},
		{
			id: "830",
			city: "Nautanwa",
		},
		{
			id: "839",
			city: "Zamania",
		},
		{
			id: "843",
			city: "Shikarpur, Bulandshahr",
		},
		{
			id: "846",
			city: "Naugawan Sadat",
		},
		{
			id: "847",
			city: "Fatehpur Sikri",
		},
		{
			id: "863",
			city: "Shahabad, Rampur",
		},
		{
			id: "869",
			city: "Robertsganj",
		},
		{
			id: "873",
			city: "Utraula",
		},
		{
			id: "878",
			city: "Sadabad",
		},
		{
			id: "925",
			city: "Rasra",
		},
		{
			id: "941",
			city: "Lar",
		},
		{
			id: "942",
			city: "Lal Gopalganj Nindaura",
		},
		{
			id: "944",
			city: "Sirsaganj",
		},
		{
			id: "965",
			city: "Pihani",
		},
		{
			id: "972",
			city: "Shamsabad, Agra",
		},
		{
			id: "985",
			city: "Rudrapur",
		},
		{
			id: "986",
			city: "Soron",
		},
		{
			id: "998",
			city: "SUrban Agglomerationr",
		},
		{
			id: "1018",
			city: "Samdhan",
		},
		{
			id: "1025",
			city: "Sahjanwa",
		},
		{
			id: "1031",
			city: "Rampur Maniharan",
		},
		{
			id: "1035",
			city: "Sumerpur",
		},
		{
			id: "1037",
			city: "Shahganj",
		},
		{
			id: "1039",
			city: "Tulsipur",
		},
		{
			id: "1049",
			city: "Tirwaganj",
		},
		{
			id: "1062",
			city: "PurqUrban Agglomerationzi",
		},
		{
			id: "1063",
			city: "Shamsabad, Farrukhabad",
		},
		{
			id: "1066",
			city: "Warhapur",
		},
		{
			id: "1070",
			city: "Powayan",
		},
		{
			id: "1075",
			city: "Sandi",
		},
		{
			id: "1093",
			city: "Achhnera",
		},
		{
			id: "1095",
			city: "Naraura",
		},
		{
			id: "1096",
			city: "Nakur",
		},
		{
			id: "1101",
			city: "Sahaspur",
		},
		{
			id: "1107",
			city: "Safipur",
		},
		{
			id: "1117",
			city: "Reoti",
		},
		{
			id: "1134",
			city: "Sikanderpur",
		},
		{
			id: "1139",
			city: "Saidpur",
		},
		{
			id: "1145",
			city: "Sirsi",
		},
		{
			id: "1146",
			city: "Purwa",
		},
		{
			id: "1149",
			city: "Parasi",
		},
		{
			id: "1151",
			city: "Lalganj",
		},
		{
			id: "1157",
			city: "Phulpur",
		},
		{
			id: "1171",
			city: "Shishgarh",
		},
		{
			id: "1177",
			city: "Sahawar",
		},
		{
			id: "1182",
			city: "Samthar",
		},
		{
			id: "1188",
			city: "Pukhrayan",
		},
		{
			id: "1201",
			city: "Obra",
		},
		{
			id: "1207",
			city: "Niwai",
		},
	],
	Bihar: [
		{
			id: "13",
			city: "Patna",
		},
		{
			id: "87",
			city: "Gaya",
		},
		{
			id: "97",
			city: "Bhagalpur",
		},
		{
			id: "104",
			city: "Muzaffarpur",
		},
		{
			id: "119",
			city: "Darbhanga",
		},
		{
			id: "136",
			city: "Arrah",
		},
		{
			id: "141",
			city: "Begusarai",
		},
		{
			id: "143",
			city: "Chhapra",
		},
		{
			id: "149",
			city: "Katihar",
		},
		{
			id: "161",
			city: "Munger",
		},
		{
			id: "173",
			city: "Purnia",
		},
		{
			id: "216",
			city: "Saharsa",
		},
		{
			id: "231",
			city: "Sasaram",
		},
		{
			id: "232",
			city: "Hajipur",
		},
		{
			id: "248",
			city: "Dehri-on-Sone",
		},
		{
			id: "252",
			city: "Bettiah",
		},
		{
			id: "262",
			city: "Motihari",
		},
		{
			id: "290",
			city: "Bagaha",
		},
		{
			id: "301",
			city: "Siwan",
		},
		{
			id: "308",
			city: "Kishanganj",
		},
		{
			id: "314",
			city: "Jamalpur",
		},
		{
			id: "319",
			city: "Buxar",
		},
		{
			id: "321",
			city: "Jehanabad",
		},
		{
			id: "327",
			city: "Aurangabad",
		},
		{
			id: "334",
			city: "Lakhisarai",
		},
		{
			id: "340",
			city: "Nawada",
		},
		{
			id: "375",
			city: "Jamui",
		},
		{
			id: "376",
			city: "Sitamarhi",
		},
		{
			id: "404",
			city: "Araria",
		},
		{
			id: "463",
			city: "Gopalganj",
		},
		{
			id: "475",
			city: "Madhubani",
		},
		{
			id: "492",
			city: "Masaurhi",
		},
		{
			id: "500",
			city: "Samastipur",
		},
		{
			id: "550",
			city: "Mokameh",
		},
		{
			id: "570",
			city: "Supaul",
		},
		{
			id: "577",
			city: "Dumraon",
		},
		{
			id: "597",
			city: "Arwal",
		},
		{
			id: "610",
			city: "Forbesganj",
		},
		{
			id: "612",
			city: "BhabUrban Agglomeration",
		},
		{
			id: "627",
			city: "Narkatiaganj",
		},
		{
			id: "632",
			city: "Naugachhia",
		},
		{
			id: "671",
			city: "Madhepura",
		},
		{
			id: "693",
			city: "Sheikhpura",
		},
		{
			id: "711",
			city: "Sultanganj",
		},
		{
			id: "714",
			city: "Raxaul Bazar",
		},
		{
			id: "748",
			city: "Ramnagar",
		},
		{
			id: "765",
			city: "Mahnar Bazar",
		},
		{
			id: "820",
			city: "Warisaliganj",
		},
		{
			id: "821",
			city: "Revelganj",
		},
		{
			id: "831",
			city: "Rajgir",
		},
		{
			id: "837",
			city: "Sonepur",
		},
		{
			id: "856",
			city: "Sherghati",
		},
		{
			id: "886",
			city: "Sugauli",
		},
		{
			id: "904",
			city: "Makhdumpur",
		},
		{
			id: "905",
			city: "Maner",
		},
		{
			id: "968",
			city: "Rosera",
		},
		{
			id: "973",
			city: "Nokha",
		},
		{
			id: "1008",
			city: "Piro",
		},
		{
			id: "1029",
			city: "Rafiganj",
		},
		{
			id: "1038",
			city: "Marhaura",
		},
		{
			id: "1064",
			city: "Mirganj",
		},
		{
			id: "1080",
			city: "Lalganj",
		},
		{
			id: "1086",
			city: "Murliganj",
		},
		{
			id: "1122",
			city: "Motipur",
		},
		{
			id: "1133",
			city: "Manihari",
		},
		{
			id: "1147",
			city: "Sheohar",
		},
		{
			id: "1164",
			city: "Maharajganj",
		},
		{
			id: "1185",
			city: "Silao",
		},
		{
			id: "1204",
			city: "Barh",
		},
		{
			id: "1213",
			city: "Asarganj",
		},
	],
	"Madhya Pradesh": [
		{
			id: "14",
			city: "Indore",
		},
		{
			id: "16",
			city: "Bhopal",
		},
		{
			id: "38",
			city: "Jabalpur",
		},
		{
			id: "39",
			city: "Gwalior",
		},
		{
			id: "75",
			city: "Ujjain",
		},
		{
			id: "114",
			city: "Sagar",
		},
		{
			id: "128",
			city: "Ratlam",
		},
		{
			id: "147",
			city: "Satna",
		},
		{
			id: "154",
			city: "Murwara (Katni)",
		},
		{
			id: "168",
			city: "Morena",
		},
		{
			id: "181",
			city: "Singrauli",
		},
		{
			id: "186",
			city: "Rewa",
		},
		{
			id: "215",
			city: "Vidisha",
		},
		{
			id: "225",
			city: "Ganjbasoda",
		},
		{
			id: "234",
			city: "Shivpuri",
		},
		{
			id: "243",
			city: "Mandsaur",
		},
		{
			id: "255",
			city: "Neemuch",
		},
		{
			id: "332",
			city: "Nagda",
		},
		{
			id: "337",
			city: "Itarsi",
		},
		{
			id: "351",
			city: "Sarni",
		},
		{
			id: "355",
			city: "Sehore",
		},
		{
			id: "356",
			city: "Mhow Cantonment",
		},
		{
			id: "362",
			city: "Seoni",
		},
		{
			id: "387",
			city: "Balaghat",
		},
		{
			id: "394",
			city: "Ashok Nagar",
		},
		{
			id: "403",
			city: "Tikamgarh",
		},
		{
			id: "406",
			city: "Shahdol",
		},
		{
			id: "458",
			city: "Pithampur",
		},
		{
			id: "485",
			city: "Alirajpur",
		},
		{
			id: "516",
			city: "Mandla",
		},
		{
			id: "531",
			city: "Sheopur",
		},
		{
			id: "536",
			city: "Shajapur",
		},
		{
			id: "593",
			city: "Panna",
		},
		{
			id: "630",
			city: "Raghogarh-Vijaypur",
		},
		{
			id: "633",
			city: "Sendhwa",
		},
		{
			id: "666",
			city: "Sidhi",
		},
		{
			id: "679",
			city: "Pipariya",
		},
		{
			id: "703",
			city: "Shujalpur",
		},
		{
			id: "706",
			city: "Sironj",
		},
		{
			id: "723",
			city: "Pandhurna",
		},
		{
			id: "728",
			city: "Nowgong",
		},
		{
			id: "734",
			city: "Mandideep",
		},
		{
			id: "757",
			city: "Sihora",
		},
		{
			id: "793",
			city: "Raisen",
		},
		{
			id: "794",
			city: "Lahar",
		},
		{
			id: "814",
			city: "Maihar",
		},
		{
			id: "819",
			city: "Sanawad",
		},
		{
			id: "822",
			city: "Sabalgarh",
		},
		{
			id: "844",
			city: "Umaria",
		},
		{
			id: "845",
			city: "Porsa",
		},
		{
			id: "865",
			city: "Narsinghgarh",
		},
		{
			id: "867",
			city: "Malaj Khand",
		},
		{
			id: "868",
			city: "Sarangpur",
		},
		{
			id: "908",
			city: "Mundi",
		},
		{
			id: "912",
			city: "Nepanagar",
		},
		{
			id: "918",
			city: "Pasan",
		},
		{
			id: "922",
			city: "Mahidpur",
		},
		{
			id: "996",
			city: "Seoni-Malwa",
		},
		{
			id: "1005",
			city: "Rehli",
		},
		{
			id: "1016",
			city: "Manawar",
		},
		{
			id: "1020",
			city: "Rahatgarh",
		},
		{
			id: "1021",
			city: "Panagar",
		},
		{
			id: "1026",
			city: "Wara Seoni",
		},
		{
			id: "1030",
			city: "Tarana",
		},
		{
			id: "1045",
			city: "Sausar",
		},
		{
			id: "1052",
			city: "Rajgarh",
		},
		{
			id: "1059",
			city: "Niwari",
		},
		{
			id: "1083",
			city: "Mauganj",
		},
		{
			id: "1099",
			city: "Manasa",
		},
		{
			id: "1100",
			city: "Nainpur",
		},
		{
			id: "1103",
			city: "Prithvipur",
		},
		{
			id: "1108",
			city: "Sohagpur",
		},
		{
			id: "1129",
			city: "Nowrozabad (Khodargama)",
		},
		{
			id: "1140",
			city: "Shamgarh",
		},
		{
			id: "1142",
			city: "Maharajpur",
		},
		{
			id: "1143",
			city: "Multai",
		},
		{
			id: "1159",
			city: "Pali",
		},
		{
			id: "1160",
			city: "Pachore",
		},
		{
			id: "1165",
			city: "Rau",
		},
		{
			id: "1174",
			city: "Mhowgaon",
		},
		{
			id: "1196",
			city: "Vijaypur",
		},
		{
			id: "1211",
			city: "Narsinghgarh",
		},
	],
	"Andhra Pradesh": [
		{
			id: "17",
			city: "Visakhapatnam",
		},
		{
			id: "40",
			city: "Vijayawada",
		},
		{
			id: "60",
			city: "Guntur",
		},
		{
			id: "80",
			city: "Nellore",
		},
		{
			id: "92",
			city: "Kurnool",
		},
		{
			id: "94",
			city: "Rajahmundry",
		},
		{
			id: "112",
			city: "Kakinada",
		},
		{
			id: "123",
			city: "Tirupati",
		},
		{
			id: "135",
			city: "Anantapur",
		},
		{
			id: "144",
			city: "Kadapa",
		},
		{
			id: "148",
			city: "Vizianagaram",
		},
		{
			id: "160",
			city: "Eluru",
		},
		{
			id: "166",
			city: "Ongole",
		},
		{
			id: "167",
			city: "Nandyal",
		},
		{
			id: "194",
			city: "Machilipatnam",
		},
		{
			id: "201",
			city: "Adoni",
		},
		{
			id: "204",
			city: "Tenali",
		},
		{
			id: "218",
			city: "Chittoor",
		},
		{
			id: "222",
			city: "Hindupur",
		},
		{
			id: "227",
			city: "Proddatur",
		},
		{
			id: "242",
			city: "Bhimavaram",
		},
		{
			id: "249",
			city: "Madanapalle",
		},
		{
			id: "258",
			city: "Guntakal",
		},
		{
			id: "268",
			city: "Dharmavaram",
		},
		{
			id: "273",
			city: "Gudivada",
		},
		{
			id: "276",
			city: "Srikakulam",
		},
		{
			id: "281",
			city: "Narasaraopet",
		},
		{
			id: "302",
			city: "Rajampet",
		},
		{
			id: "305",
			city: "Tadpatri",
		},
		{
			id: "317",
			city: "Tadepalligudem",
		},
		{
			id: "326",
			city: "Chilakaluripet",
		},
		{
			id: "349",
			city: "Yemmiganur",
		},
		{
			id: "364",
			city: "Kadiri",
		},
		{
			id: "377",
			city: "Chirala",
		},
		{
			id: "381",
			city: "Anakapalle",
		},
		{
			id: "392",
			city: "Kavali",
		},
		{
			id: "413",
			city: "Palacole",
		},
		{
			id: "415",
			city: "Sullurpeta",
		},
		{
			id: "436",
			city: "Tanuku",
		},
		{
			id: "437",
			city: "Rayachoti",
		},
		{
			id: "443",
			city: "Srikalahasti",
		},
		{
			id: "445",
			city: "Bapatla",
		},
		{
			id: "487",
			city: "Naidupet",
		},
		{
			id: "496",
			city: "Nagari",
		},
		{
			id: "514",
			city: "Gudur",
		},
		{
			id: "520",
			city: "Vinukonda",
		},
		{
			id: "526",
			city: "Narasapuram",
		},
		{
			id: "527",
			city: "Nuzvid",
		},
		{
			id: "530",
			city: "Markapur",
		},
		{
			id: "539",
			city: "Ponnur",
		},
		{
			id: "543",
			city: "Kandukur",
		},
		{
			id: "549",
			city: "Bobbili",
		},
		{
			id: "569",
			city: "Rayadurg",
		},
		{
			id: "578",
			city: "Samalkot",
		},
		{
			id: "579",
			city: "Jaggaiahpet",
		},
		{
			id: "581",
			city: "Tuni",
		},
		{
			id: "584",
			city: "Amalapuram",
		},
		{
			id: "592",
			city: "Bheemunipatnam",
		},
		{
			id: "598",
			city: "Venkatagiri",
		},
		{
			id: "601",
			city: "Sattenapalle",
		},
		{
			id: "614",
			city: "Pithapuram",
		},
		{
			id: "619",
			city: "Palasa Kasibugga",
		},
		{
			id: "624",
			city: "Parvathipuram",
		},
		{
			id: "629",
			city: "Macherla",
		},
		{
			id: "636",
			city: "Gooty",
		},
		{
			id: "637",
			city: "Salur",
		},
		{
			id: "646",
			city: "Mandapeta",
		},
		{
			id: "661",
			city: "Jammalamadugu",
		},
		{
			id: "668",
			city: "Peddapuram",
		},
		{
			id: "681",
			city: "Punganur",
		},
		{
			id: "690",
			city: "Nidadavole",
		},
		{
			id: "701",
			city: "Repalle",
		},
		{
			id: "718",
			city: "Ramachandrapuram",
		},
		{
			id: "737",
			city: "Kovvur",
		},
		{
			id: "759",
			city: "Tiruvuru",
		},
		{
			id: "795",
			city: "Uravakonda",
		},
		{
			id: "829",
			city: "Narsipatnam",
		},
		{
			id: "854",
			city: "Yerraguntla",
		},
		{
			id: "915",
			city: "Pedana",
		},
		{
			id: "919",
			city: "Puttur",
		},
		{
			id: "1054",
			city: "Renigunta",
		},
		{
			id: "1067",
			city: "Rajam",
		},
		{
			id: "1073",
			city: "Srisailam Project (Right Flank Colony) Township",
		},
	],
	Punjab: [
		{
			id: "20",
			city: "Ludhiana",
		},
		{
			id: "26",
			city: "Patiala",
		},
		{
			id: "34",
			city: "Amritsar",
		},
		{
			id: "51",
			city: "Jalandhar",
		},
		{
			id: "125",
			city: "Bathinda",
		},
		{
			id: "198",
			city: "Pathankot",
		},
		{
			id: "199",
			city: "Hoshiarpur",
		},
		{
			id: "213",
			city: "Batala",
		},
		{
			id: "226",
			city: "Moga",
		},
		{
			id: "250",
			city: "Malerkotla",
		},
		{
			id: "254",
			city: "Khanna",
		},
		{
			id: "265",
			city: "Mohali",
		},
		{
			id: "279",
			city: "Barnala",
		},
		{
			id: "298",
			city: "Firozpur",
		},
		{
			id: "322",
			city: "Phagwara",
		},
		{
			id: "325",
			city: "Kapurthala",
		},
		{
			id: "345",
			city: "Zirakpur",
		},
		{
			id: "357",
			city: "Kot Kapura",
		},
		{
			id: "383",
			city: "Faridkot",
		},
		{
			id: "390",
			city: "Muktsar",
		},
		{
			id: "391",
			city: "Rajpura",
		},
		{
			id: "410",
			city: "Sangrur",
		},
		{
			id: "412",
			city: "Fazilka",
		},
		{
			id: "417",
			city: "Gurdaspur",
		},
		{
			id: "421",
			city: "Kharar",
		},
		{
			id: "431",
			city: "Gobindgarh",
		},
		{
			id: "433",
			city: "Mansa",
		},
		{
			id: "447",
			city: "Malout",
		},
		{
			id: "459",
			city: "Nabha",
		},
		{
			id: "469",
			city: "Tarn Taran",
		},
		{
			id: "483",
			city: "Jagraon",
		},
		{
			id: "554",
			city: "Sunam",
		},
		{
			id: "565",
			city: "Dhuri",
		},
		{
			id: "585",
			city: "Firozpur Cantt.",
		},
		{
			id: "622",
			city: "Sirhind Fatehgarh Sahib",
		},
		{
			id: "631",
			city: "Rupnagar",
		},
		{
			id: "643",
			city: "Jalandhar Cantt.",
		},
		{
			id: "656",
			city: "Samana",
		},
		{
			id: "662",
			city: "Nawanshahr",
		},
		{
			id: "676",
			city: "Rampura Phul",
		},
		{
			id: "720",
			city: "Nangal",
		},
		{
			id: "770",
			city: "Nakodar",
		},
		{
			id: "778",
			city: "Zira",
		},
		{
			id: "811",
			city: "Patti",
		},
		{
			id: "1033",
			city: "Raikot",
		},
		{
			id: "1055",
			city: "Longowal",
		},
		{
			id: "1068",
			city: "Urmar Tanda",
		},
		{
			id: "1098",
			city: "Morinda, India",
		},
		{
			id: "1111",
			city: "Phillaur",
		},
		{
			id: "1115",
			city: "Pattran",
		},
		{
			id: "1126",
			city: "Qadian",
		},
		{
			id: "1132",
			city: "Sujanpur",
		},
		{
			id: "1144",
			city: "Mukerian",
		},
		{
			id: "1193",
			city: "Talwara",
		},
	],
	Haryana: [
		{
			id: "25",
			city: "Faridabad",
		},
		{
			id: "47",
			city: "Gurgaon",
		},
		{
			id: "116",
			city: "Hisar",
		},
		{
			id: "117",
			city: "Rohtak",
		},
		{
			id: "118",
			city: "Panipat",
		},
		{
			id: "124",
			city: "Karnal",
		},
		{
			id: "151",
			city: "Sonipat",
		},
		{
			id: "158",
			city: "Yamunanagar",
		},
		{
			id: "162",
			city: "Panchkula",
		},
		{
			id: "169",
			city: "Bhiwani",
		},
		{
			id: "193",
			city: "Bahadurgarh",
		},
		{
			id: "202",
			city: "Jind",
		},
		{
			id: "207",
			city: "Sirsa",
		},
		{
			id: "217",
			city: "Thanesar",
		},
		{
			id: "238",
			city: "Kaithal",
		},
		{
			id: "256",
			city: "Palwal",
		},
		{
			id: "329",
			city: "Rewari",
		},
		{
			id: "380",
			city: "Hansi",
		},
		{
			id: "420",
			city: "Narnaul",
		},
		{
			id: "446",
			city: "Fatehabad",
		},
		{
			id: "477",
			city: "Gohana",
		},
		{
			id: "489",
			city: "Tohana",
		},
		{
			id: "497",
			city: "Narwana",
		},
		{
			id: "575",
			city: "Mandi Dabwali",
		},
		{
			id: "617",
			city: "Charkhi Dadri",
		},
		{
			id: "767",
			city: "Shahbad",
		},
		{
			id: "836",
			city: "Pehowa",
		},
		{
			id: "911",
			city: "Samalkha",
		},
		{
			id: "916",
			city: "Pinjore",
		},
		{
			id: "932",
			city: "Ladwa",
		},
		{
			id: "963",
			city: "Sohna",
		},
		{
			id: "966",
			city: "Safidon",
		},
		{
			id: "1002",
			city: "Taraori",
		},
		{
			id: "1044",
			city: "Mahendragarh",
		},
		{
			id: "1056",
			city: "Ratia",
		},
		{
			id: "1158",
			city: "Rania",
		},
		{
			id: "1214",
			city: "Sarsod",
		},
	],
	"Jammu and Kashmir": [
		{
			id: "31",
			city: "Srinagar",
		},
		{
			id: "81",
			city: "Jammu",
		},
		{
			id: "200",
			city: "Baramula",
		},
		{
			id: "304",
			city: "Anantnag",
		},
		{
			id: "467",
			city: "Sopore",
		},
		{
			id: "595",
			city: "KathUrban Agglomeration",
		},
		{
			id: "715",
			city: "Rajauri",
		},
		{
			id: "722",
			city: "Punch",
		},
		{
			id: "798",
			city: "Udhampur",
		},
	],
	Jharkhand: [
		{
			id: "32",
			city: "Dhanbad",
		},
		{
			id: "50",
			city: "Ranchi",
		},
		{
			id: "64",
			city: "Jamshedpur",
		},
		{
			id: "93",
			city: "Bokaro Steel City",
		},
		{
			id: "165",
			city: "Deoghar",
		},
		{
			id: "190",
			city: "Phusro",
		},
		{
			id: "191",
			city: "Adityapur",
		},
		{
			id: "241",
			city: "Hazaribag",
		},
		{
			id: "286",
			city: "Giridih",
		},
		{
			id: "297",
			city: "Ramgarh",
		},
		{
			id: "372",
			city: "Jhumri Tilaiya",
		},
		{
			id: "384",
			city: "Saunda",
		},
		{
			id: "399",
			city: "Sahibganj",
		},
		{
			id: "407",
			city: "Medininagar (Daltonganj)",
		},
		{
			id: "453",
			city: "Chaibasa",
		},
		{
			id: "618",
			city: "Chatra",
		},
		{
			id: "641",
			city: "Gumia",
		},
		{
			id: "647",
			city: "Dumka",
		},
		{
			id: "650",
			city: "Madhupur",
		},
		{
			id: "669",
			city: "Chirkunda",
		},
		{
			id: "789",
			city: "Pakaur",
		},
		{
			id: "824",
			city: "Simdega",
		},
		{
			id: "825",
			city: "Musabani",
		},
		{
			id: "840",
			city: "Mihijam",
		},
		{
			id: "874",
			city: "Patratu",
		},
		{
			id: "1048",
			city: "Lohardaga",
		},
		{
			id: "1118",
			city: "Tenu dam-cum-Kathhara",
		},
	],
	Chhattisgarh: [
		{
			id: "35",
			city: "Raipur",
		},
		{
			id: "65",
			city: "Bhilai Nagar",
		},
		{
			id: "100",
			city: "Korba",
		},
		{
			id: "108",
			city: "Bilaspur",
		},
		{
			id: "130",
			city: "Durg",
		},
		{
			id: "239",
			city: "Rajnandgaon",
		},
		{
			id: "261",
			city: "Jagdalpur",
		},
		{
			id: "282",
			city: "Raigarh",
		},
		{
			id: "285",
			city: "Ambikapur",
		},
		{
			id: "382",
			city: "Mahasamund",
		},
		{
			id: "393",
			city: "Dhamtari",
		},
		{
			id: "454",
			city: "Chirmiri",
		},
		{
			id: "542",
			city: "Bhatapara",
		},
		{
			id: "680",
			city: "Dalli-Rajhara",
		},
		{
			id: "729",
			city: "Naila Janjgir",
		},
		{
			id: "864",
			city: "Tilda Newra",
		},
		{
			id: "881",
			city: "Mungeli",
		},
		{
			id: "899",
			city: "Manendragarh",
		},
		{
			id: "1183",
			city: "Sakti",
		},
	],
	Assam: [
		{
			id: "42",
			city: "Guwahati",
		},
		{
			id: "184",
			city: "Silchar",
		},
		{
			id: "244",
			city: "Dibrugarh",
		},
		{
			id: "280",
			city: "Nagaon",
		},
		{
			id: "336",
			city: "Tinsukia",
		},
		{
			id: "440",
			city: "Jorhat",
		},
		{
			id: "464",
			city: "Bongaigaon City",
		},
		{
			id: "491",
			city: "Dhubri",
		},
		{
			id: "506",
			city: "Diphu",
		},
		{
			id: "519",
			city: "North Lakhimpur",
		},
		{
			id: "528",
			city: "Tezpur",
		},
		{
			id: "547",
			city: "Karimganj",
		},
		{
			id: "574",
			city: "Sibsagar",
		},
		{
			id: "580",
			city: "Goalpara",
		},
		{
			id: "699",
			city: "Barpeta",
		},
		{
			id: "774",
			city: "Lanka",
		},
		{
			id: "887",
			city: "Lumding",
		},
		{
			id: "933",
			city: "Mankachar",
		},
		{
			id: "953",
			city: "Nalbari",
		},
		{
			id: "1024",
			city: "Rangia",
		},
		{
			id: "1050",
			city: "Margherita",
		},
		{
			id: "1053",
			city: "Mangaldoi",
		},
		{
			id: "1105",
			city: "Silapathar",
		},
		{
			id: "1156",
			city: "Mariani",
		},
		{
			id: "1168",
			city: "Marigaon",
		},
	],
	Chandigarh: [
		{
			id: "43",
			city: "Chandigarh",
		},
	],
	Odisha: [
		{
			id: "53",
			city: "Bhubaneswar",
		},
		{
			id: "66",
			city: "Cuttack",
		},
		{
			id: "83",
			city: "Raurkela",
		},
		{
			id: "102",
			city: "Brahmapur",
		},
		{
			id: "185",
			city: "Sambalpur",
		},
		{
			id: "210",
			city: "Puri",
		},
		{
			id: "274",
			city: "Baleshwar Town",
		},
		{
			id: "299",
			city: "Baripada Town",
		},
		{
			id: "307",
			city: "Bhadrak",
		},
		{
			id: "339",
			city: "Balangir",
		},
		{
			id: "341",
			city: "Jharsuguda",
		},
		{
			id: "397",
			city: "Bargarh",
		},
		{
			id: "426",
			city: "Paradip",
		},
		{
			id: "456",
			city: "Bhawanipatna",
		},
		{
			id: "461",
			city: "Dhenkanal",
		},
		{
			id: "473",
			city: "Barbil",
		},
		{
			id: "515",
			city: "Kendujhar",
		},
		{
			id: "523",
			city: "Sunabeda",
		},
		{
			id: "537",
			city: "Rayagada",
		},
		{
			id: "559",
			city: "Jatani",
		},
		{
			id: "634",
			city: "Byasanagar",
		},
		{
			id: "653",
			city: "Kendrapara",
		},
		{
			id: "692",
			city: "Rajagangapur",
		},
		{
			id: "694",
			city: "Parlakhemundi",
		},
		{
			id: "725",
			city: "Talcher",
		},
		{
			id: "749",
			city: "Sundargarh",
		},
		{
			id: "827",
			city: "Phulabani",
		},
		{
			id: "851",
			city: "Pattamundai",
		},
		{
			id: "889",
			city: "Titlagarh",
		},
		{
			id: "909",
			city: "Nabarangapur",
		},
		{
			id: "957",
			city: "Soro",
		},
		{
			id: "1081",
			city: "Malkangiri",
		},
		{
			id: "1127",
			city: "Rairangpur",
		},
		{
			id: "1209",
			city: "Tarbha",
		},
	],
	Kerala: [
		{
			id: "57",
			city: "Thiruvananthapuram",
		},
		{
			id: "67",
			city: "Kochi",
		},
		{
			id: "90",
			city: "Kozhikode",
		},
		{
			id: "106",
			city: "Kollam",
		},
		{
			id: "110",
			city: "Thrissur",
		},
		{
			id: "171",
			city: "Palakkad",
		},
		{
			id: "192",
			city: "Alappuzha",
		},
		{
			id: "328",
			city: "Malappuram",
		},
		{
			id: "374",
			city: "Ponnani",
		},
		{
			id: "418",
			city: "Vatakara",
		},
		{
			id: "428",
			city: "Kanhangad",
		},
		{
			id: "434",
			city: "Taliparamba",
		},
		{
			id: "439",
			city: "Koyilandy",
		},
		{
			id: "444",
			city: "Neyyattinkara",
		},
		{
			id: "457",
			city: "Kayamkulam",
		},
		{
			id: "518",
			city: "Nedumangad",
		},
		{
			id: "548",
			city: "Kannur",
		},
		{
			id: "557",
			city: "Tirur",
		},
		{
			id: "563",
			city: "Kottayam",
		},
		{
			id: "567",
			city: "Kasaragod",
		},
		{
			id: "571",
			city: "Kunnamkulam",
		},
		{
			id: "576",
			city: "Ottappalam",
		},
		{
			id: "587",
			city: "Thiruvalla",
		},
		{
			id: "594",
			city: "Thodupuzha",
		},
		{
			id: "626",
			city: "Chalakudy",
		},
		{
			id: "645",
			city: "Changanassery",
		},
		{
			id: "652",
			city: "Punalur",
		},
		{
			id: "658",
			city: "Nilambur",
		},
		{
			id: "665",
			city: "Cherthala",
		},
		{
			id: "677",
			city: "Perinthalmanna",
		},
		{
			id: "682",
			city: "Mattannur",
		},
		{
			id: "708",
			city: "Shoranur",
		},
		{
			id: "726",
			city: "Varkala",
		},
		{
			id: "746",
			city: "Paravoor",
		},
		{
			id: "752",
			city: "Pathanamthitta",
		},
		{
			id: "761",
			city: "Peringathur",
		},
		{
			id: "766",
			city: "Attingal",
		},
		{
			id: "826",
			city: "Kodungallur",
		},
		{
			id: "838",
			city: "Pappinisseri",
		},
		{
			id: "866",
			city: "Chittur-Thathamangalam",
		},
		{
			id: "924",
			city: "Muvattupuzha",
		},
		{
			id: "928",
			city: "Adoor",
		},
		{
			id: "938",
			city: "Mavelikkara",
		},
		{
			id: "952",
			city: "Mavoor",
		},
		{
			id: "989",
			city: "Perumbavoor",
		},
		{
			id: "1076",
			city: "Vaikom",
		},
		{
			id: "1097",
			city: "Palai",
		},
		{
			id: "1163",
			city: "Panniyannur",
		},
		{
			id: "1175",
			city: "Guruvayoor",
		},
		{
			id: "1194",
			city: "Puthuppally",
		},
		{
			id: "1206",
			city: "Panamattom",
		},
	],
	Uttarakhand: [
		{
			id: "70",
			city: "Dehradun",
		},
		{
			id: "150",
			city: "Hardwar",
		},
		{
			id: "214",
			city: "Haldwani-cum-Kathgodam",
		},
		{
			id: "228",
			city: "Srinagar",
		},
		{
			id: "269",
			city: "Kashipur",
		},
		{
			id: "283",
			city: "Roorkee",
		},
		{
			id: "366",
			city: "Rudrapur",
		},
		{
			id: "405",
			city: "Rishikesh",
		},
		{
			id: "660",
			city: "Ramnagar",
		},
		{
			id: "672",
			city: "Pithoragarh",
		},
		{
			id: "700",
			city: "Manglaur",
		},
		{
			id: "717",
			city: "Nainital",
		},
		{
			id: "923",
			city: "Mussoorie",
		},
		{
			id: "1017",
			city: "Tehri",
		},
		{
			id: "1034",
			city: "Pauri",
		},
		{
			id: "1114",
			city: "Nagla",
		},
		{
			id: "1120",
			city: "Sitarganj",
		},
		{
			id: "1208",
			city: "Bageshwar",
		},
	],
	Puducherry: [
		{
			id: "79",
			city: "Pondicherry",
		},
		{
			id: "379",
			city: "Karaikal",
		},
		{
			id: "562",
			city: "Yanam",
		},
		{
			id: "773",
			city: "Mahe",
		},
	],
	Tripura: [
		{
			id: "96",
			city: "Agartala",
		},
		{
			id: "849",
			city: "Udaipur",
		},
		{
			id: "897",
			city: "Dharmanagar",
		},
		{
			id: "980",
			city: "Pratapgarh",
		},
		{
			id: "1181",
			city: "Kailasahar",
		},
		{
			id: "1190",
			city: "Belonia",
		},
		{
			id: "1195",
			city: "Khowai",
		},
	],
	Karnatka: [
		{
			id: "103",
			city: "Mysore",
		},
	],
	Mizoram: [
		{
			id: "121",
			city: "Aizawl",
		},
		{
			id: "546",
			city: "Lunglei",
		},
		{
			id: "1191",
			city: "Saiha",
		},
	],
	Meghalaya: [
		{
			id: "131",
			city: "Shillong",
		},
		{
			id: "419",
			city: "Tura",
		},
		{
			id: "934",
			city: "Nongstoin",
		},
	],
	Manipur: [
		{
			id: "132",
			city: "Imphal",
		},
		{
			id: "663",
			city: "Thoubal",
		},
		{
			id: "1090",
			city: "Lilong",
		},
		{
			id: "1173",
			city: "Mayang Imphal",
		},
	],
	"Himachal Pradesh": [
		{
			id: "236",
			city: "Shimla",
		},
		{
			id: "517",
			city: "Mandi",
		},
		{
			id: "817",
			city: "Solan",
		},
		{
			id: "931",
			city: "Nahan",
		},
		{
			id: "1051",
			city: "Sundarnagar",
		},
		{
			id: "1072",
			city: "Palampur",
		},
	],
	Nagaland: [
		{
			id: "264",
			city: "Dimapur",
		},
		{
			id: "338",
			city: "Kohima",
		},
		{
			id: "764",
			city: "Zunheboto",
		},
		{
			id: "776",
			city: "Tuensang",
		},
		{
			id: "804",
			city: "Wokha",
		},
		{
			id: "891",
			city: "Mokokchung",
		},
	],
	Goa: [
		{
			id: "315",
			city: "Marmagao",
		},
		{
			id: "335",
			city: "Panaji",
		},
		{
			id: "353",
			city: "Margao",
		},
		{
			id: "730",
			city: "Mapusa",
		},
	],
	"Andaman and Nicobar Islands": [
		{
			id: "333",
			city: "Port Blair",
		},
	],
	"Arunachal Pradesh": [
		{
			id: "788",
			city: "Naharlagun",
		},
		{
			id: "1121",
			city: "Pasighat",
		},
	],
	"Dadra and Nagar Haveli": [
		{
			id: "1128",
			city: "Silvassa",
		},
	],
};
const storeType = ["Small", "Medium", "Large"];
const LocationType = [
	"CorporateBuilding",
	"CoWorking",
	"BagpackerHostel",
	"College/University",
	"Salon/Spa",
	"Clinic",
	"Hospital",
	"RetailStore",
	"Mall",
	"Housing",
	"Hotel",
];

const storageType = ["Ambient", "Chilled", "Frozen"];
const deliveryType = ["To Store", "To Warehouse"];
const constant = {
	API_URL,
	Bucket,
	BASE_S3_URL,
	apiUrl: "",
	insuranceCompany,
	storeType,
	UserType,
	storageType,
	deliveryType,
	LocationType,
	states,
	cities,
	// slugifyUrl,
	axios: $axios,
	api_error_code,
	api_header_code,
	api_success_code,
	response_alert,
	user_status,
	policy_status,
	hasConnection: navigator.onLine,
	mainMenuLinksArray: ["/dashboard"],
	spaceRegex: /^\S*$/,
	// eslint-disable-next-line
	passwordRegex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z0-9!_@./#&+-\d]{8,}$/,
	// eslint-disable-next-line
	emailRegex:
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};
export default constant;
