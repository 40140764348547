import React, { Suspense } from "react";
import { Provider } from "react-redux";
import Store from "./store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./routes";
import { useEffect, useState } from "react";
import OfflineDetector from "./utility/OfflineDetector";

const App: React.FC = () => {
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    function updateOnlineStatus() {
      setIsOffline(!navigator.onLine);
    }
    updateOnlineStatus();
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);
  return (
    <div>
       {isOffline ? <OfflineDetector /> : (
<>
<div className="root-container">
        <Provider store={Store}>
          <Suspense fallback={"Loading..."}>
            {
              <Routes />
            }
          </Suspense>
        </Provider>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
</>
       )}
    </div>
  );
};

export default App;
