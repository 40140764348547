import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { shiftCreate } from '../../pages/billing/action'
import { Button, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function ShiftStart({ onShiftCreated }: { onShiftCreated: () => void }) {
    const dispatch:any = useDispatch()
    const { storeId: getStoreId } = useSelector((state:any) => state.commonReducer);
    const billerId = localStorage.getItem("id");
    const storeName = localStorage.getItem("storename")
    const userName = localStorage.getItem('username');
    const [openingCash,setOpeningCash] = useState("");
    const { userId } = useSelector((state:any) => state.commonReducer);

    let navigate = useNavigate();

    const handleShiftCreate = () => {
      dispatch(shiftCreate(getStoreId,billerId,openingCash,navigate,userId,userName))
      onShiftCreated(); 
    }

  return (
      <>
        <Box
      sx={{
        backgroundColor: '#f4f6f8',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        margin: '20px auto',
        fontFamily: 'Roboto, sans-serif',
      }}
    >
         <Typography
        variant="h6"
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
          mb: 3,
          color: '#333',
        }}
      >
        Welcome to {storeName}, {userName}! It's time to start your shift
        {/* Hello {userName}, start your shift */}
      </Typography>
      <Box sx={{ width: '100%', maxWidth: '100%', display: 'flex', justifyContent: 'center' }}>
  <TextField
    variant="outlined"
    label="Opening Cash"
    size="small"
    name="openingcash"
    onChange={(e) => {
      const value:any = e.target.value;
      if (!isNaN(value) && (Number(value) >= 0 || value === "")) {
        setOpeningCash(value);
      }
    }}
    value={openingCash}
    InputLabelProps={{ style: { color: 'black' } }}
    InputProps={{
      placeholder: 'Enter opening cash',
      sx: {
        borderRadius: '10px',
      },
    }}
  />
</Box>
      </Box>
  
         <Box
         sx={{
           display: 'flex',
           justifyContent: 'center',
           mt: 3,
         }}
       >
             <Button
             disabled={!openingCash}
          onClick={handleShiftCreate}
          variant="contained"
          sx={{
            backgroundColor: '#FFD700',
            color: '#333',
            borderRadius: '20px',
            padding: '10px 20px',
            fontSize: '16px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#FFD700',
            },
          }}
        >
          START
        </Button>
    </Box>
    </>
  )
}

export default ShiftStart