export default function LoadingComponent() {
  return (
  <div style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }}>
    <img style={{
    width:500
  }}  src="https://wpamelia.com/wp-content/uploads/2018/11/ezgif-2-6d0b072c3d3f.gif" alt="Loading..." />
  </div> 
  );
}

