import { useEffect,useState } from 'react';
import CreditTableTab from './CreditTableTab';
import { CreditByStore } from './action';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import CreditBarcodeInput from '../../component/inputs/CreditBarcodeInput';
import { Box } from '@mui/material';

const CreditTab = () => {
  // const getStoreId = localStorage.getItem("storeId")
  const { storeId: getStoreId } = useSelector(
    (state: any) => state.commonReducer
  );
  const dispatch: any = useDispatch();
  const {creditData } = useSelector((state: any) => state.creditsReducer);
  const[barcode,setBarcode] = useState("")

  useEffect(()=>{
    dispatch(CreditByStore(getStoreId,""))
  },[dispatch,getStoreId,barcode])

  const searchWithNameOrNumber = (e:any) =>{
    setBarcode(e.target.value)
  }

  return (
    <>
         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end',width: "65%"  }}>
      <CreditBarcodeInput
        onChange={searchWithNameOrNumber}
        value={barcode}
        name={"Credit"}
      />
    </Box>
    {creditData && <CreditTableTab creditData={creditData} />}
    </>
  )
};

export default CreditTab;