import { makeStyles } from "@mui/styles";

export const useBillingStyles = makeStyles(() => ({
  tableHeader: {
    backgroundColor: '#ffeb3b', // Yellow background for header
    alignItems:"center",
    textAlign:"center",
    whiteSpace:"nowrap"
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px', // Adjust border-radius for rounded corners
      '& fieldset': {
        borderColor: 'black', // Border color
      },
      '&:hover fieldset': {
        borderColor: '#3f51b5', // Color when hovering
      },
      '&.Mui-focused fieldset': {
        borderColor: '#3f51b5', // Color when focused
      },
    },
    '& input': {
      padding: '8px', // Adjust padding inside the input
    },
  },
  headerCell: {
    fontWeight: 'bold', // Make sure header text is bold
    fontSize: '16px',
    textAlign: 'left',
    wordWrap: 'break-word',
    maxWidth: '200px', // Adjust as needed
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cashCollectMethod: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 20px", // Adjusted padding for better spacing
    margin: "0 5px", // Added margin on both sides for spacing
    cursor: "pointer",
    borderRadius: "10px",
    textAlign: "center",
    maxWidth: "150px", // Added max-width to prevent content overflow
    flex: "1 1 auto", // Ensures items grow and shrink as needed
    boxSizing: "border-box", // Ensures padding and border are included in width/height
    alignItems: "center",
    display: "flex", // Added display flex for vertical centering
    justifyContent: "center", // Center text horizontally
  },
    customerDetails: {
      backgroundColor: "white",
      color: "black",
      padding: "10px 25px",
      marginRight: "1px !important",
      cursor: "pointer",
      borderRadius:"10px",
      textAlign:"center",
      width:"5vw",
    },
    amount: {
      // width: 60,
      "&>input": {
        padding: 10,
        boxSizing: "border-box",
        width: "100%",
      },
    },
  }));