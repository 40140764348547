import Utils from '../../utility'
import { getBillerId, logoutRefreshToken } from '../login/action'
import { clearCustomerDetails } from './reducer'
export const getProducts = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    const { order, sortField, offset, search } = getState().productsReducer
    Utils.api.getApiCall(
      Utils.endPoints.storeStock,
      `/1?order=${order}&sortField=${sortField}&offset=${offset}&take=${50}&search=${search}`,
      (respData: any) => {
        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: {
            products: respData.data?.storeStocks,
            count: respData?.data?.count,
          },
        })
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        let { data } = error
        if (data?.message === '' || !data) {
          Utils.showAlert(2, 'Store Stocks Get Error:')
        } else {
          Utils.showAlert(2, 'Store Stocks Get Error: ' + data?.message)
        }
      },
    )
  }
}
//customer pin api
export const CustomerPin = (id: any, setFiledValue: any) => {
  return (dispatch: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    Utils.api.getApiCall(
      Utils.endPoints.customerPin,
      `?pin=${id}`,
      (respData: any) => {
        setFiledValue('customerName', respData.data?.name)
        setFiledValue('phoneNumber', respData.data?.phoneNumber)

        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: {
            pin: respData.data,
          },
        })
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        let { data } = error
        if (data?.message === '' || !data) {
          Utils.showAlert(2, 'Customer By Pin Get Error:')
        } else {
          Utils.showAlert(2, 'Customer By Pin Get Error: ' + data?.message)
        }
      },
    )
  }
}

export const creatOrder = (
  test: any,
  products: any,
  totalAndDiscounts: any,
  setOpen: any,
  open?: any,
  onlinePinValue?: any,
  setAddedProduct?: any,
  storeId?: any,
  setBarcode?: any,
  setIsDisabled?: any,
  setDiscountPercentage?: any,
  setDiscountAmount?: any,
  setButtonClicked?: any,
  StoreID?: any,
  setCashReceive?:any,
  setCashReturn?:any,
  setApiSuccess?:any,
  currentCart?:string,
  setCurrentCart?:any,
  testChangeCart?:any
) => {
  return (dispatch: any, getState: any) => {
    const { storeId } = getState().commonReducer
    const {customerDetails,customerName,customerNumber}:any = getState().productsReducer

    setButtonClicked(true)
    setIsDisabled(true)
    const { grandTotal, discounPersentage, discounAmount } = totalAndDiscounts
    let dataToSend: any = {}
    products.forEach((item: any) => {
      item.quantity > 0 &&
        (dataToSend = {
          product: products.map((item: any) => {
            return {
              product: item.id,
              quantity: item.quantity,
              sellingPrice: item.sellingPrice,
            }
          }),
          customer: {
            customerName: customerDetails.name || test.customerName || customerName,
            customerPhoneNumber:
              test.customerNumber === '' ? null : customerDetails.number || test.customerNumber || customerNumber,
            gst: customerDetails.gst || test.customerGst,
            email: test.email,
            companyName: customerDetails.company || test.customerCompany,
            newAddress: test.address,
          },
          paymentMethod: open ? open : '',
          store: parseInt(storeId || StoreID),
          age: test.ageGroup ? test.ageGroup : '',
          notes: '',
          gender: test.gender ? test.gender : '',
          orderAmountTotal: grandTotal,
          discountAmount: discounAmount === '' ? 0 : discounAmount,
          discountPercent: discounPersentage === '' ? 0 : discounPersentage,
          // eslint-disable-next-line
          orderType:
            (test?.payType === 'swiggy' && 'swiggy') ||
            (test?.payType === 'zomato' && 'zomato') ||
            (test?.payType === 'ondc' && 'ondc') ||
            // (values?.payType === 'paytm' && 'paytm') ||
            'pos',
          walletUsed:
            onlinePinValue && !discounAmount && !discounPersentage
              ? onlinePinValue
              : 0,
        })
    })

    if (!dataToSend.gender) {
      delete dataToSend.gender
    }
    if (!dataToSend.age) {
      delete dataToSend.age
    }
    if (!dataToSend.notes) {
      delete dataToSend.notes
    }
    if (!dataToSend.paymentMethod) {
      delete dataToSend.paymentMethod
    }
    Utils.api.postApiCall(
      Utils.endPoints.orderCreate,
      dataToSend,
      (respData: any) => {
        // localStorage.removeItem("cart1");
        // const cart2 = localStorage.getItem("cart2");
        // if (cart2) {
        //   localStorage.setItem("cart1", cart2);
        //   localStorage.removeItem("cart2");
        // }

      dispatch(clearCustomerDetails())
        Utils.showAlert(1, 'Success')
        setOpen('')
        dispatch(orderInvoice(respData.data.id))
        setAddedProduct([])
        setBarcode('')
        setDiscountPercentage(0)
        setDiscountAmount(0)
        setIsDisabled(false)
        setButtonClicked(false)
        setCashReturn("")
        setCashReceive("")
        // setApiSuccess(true)
        if (currentCart === 'Cart 1') {
          localStorage.removeItem('cart1');
          setCurrentCart("Cart 2")
          testChangeCart()
        } else if (currentCart === 'Cart 2') {
          localStorage.removeItem('cart2');
          setCurrentCart("Cart 1")
          testChangeCart()
        }
        if(!currentCart){
          setApiSuccess(true)
        }
      },
      (error: any) => {
        let { data } = error
        // eslint-disable-next-line
        if (
          data?.message === 'customerPhoneNumber must be a valid phone number'
        ) {
          // eslint-disable-next-line
          Utils.showAlert(
            2,
            'Enter Valid Phone Number ' +
              data?.message +
              '.' +
              ' It should be of 10 digits',
          )
          setIsDisabled(true)
        } else if (data?.message === '' || !data) {
          Utils.showAlert(2, 'Orders Add Error')
          setIsDisabled(true)
        } else {
          Utils.showAlert(2, 'Orders Add Error: ' + data?.message)
          setIsDisabled(true)
        }
        // setAddedProduct([])
        setBarcode('')
      },
    )
  }
}

export const orderInvoice = (id: any) => {
  return (dispatch: any, getState: any) => {
    const { storeId } = getState().commonReducer

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    Utils.api.getApiCall(
      Utils.endPoints.orderInvoice + storeId + "/" + id,
      '',
      (respData: any) => {
        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: {
            invoiceHtml: respData.data,
        },
        });

        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        dispatch(getProductsBackendSearch())
      },
      (error: any) => {
        let { data } = error
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        if (data?.message === '' || !data) {
          Utils.showAlert(2, 'Invoice Get Error')
        } else {
          Utils.showAlert(
            2,
            'Invoice Get Error: ' + data?.error || data?.message,
          )
        }
      },
    )
  }
}
export const getProductsBackendSearch: any = (
  billerIdData: any,
  barcode?: any,
) => {
  return (dispatch: any, getState: any) => {
    // const StoreID = localStorage.getItem('storeId')
    const { storeId: StoreID } = getState().commonReducer
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    const { order, sortField, offset } = getState().productsReducer
    Utils.api.getApiCall(
      Utils.endPoints.storeStock,
      `/${StoreID}?order=${order}&sortField=${sortField}&offset=${offset}&take=${15}&search=${barcode}`,
      (respData: any) => {
        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: {
            products: respData.data?.storeStocks,
            // count: respData?.data?.count,
          },
        })
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        let { data } = error
        if (data?.message === '' || !data) {
          Utils.showAlert(2, 'Store Stocks Get Error:')
        } else {
          Utils.showAlert(2, 'Store Stocks Get Error: ' + data?.message)
        }
      },
    )
  }
}

export const getCustomernamebynumber: any = (
  Phonenumber: any,
  setFiledValue: any,
  setIsDisabled: any,
) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })

    Utils.api.getApiCall(
      Utils.endPoints.customerName,
      `getCustomerFromPhoneNumber?phoneNumber=${Phonenumber}`,
      (respData: any) => {      
        setIsDisabled(false)
        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: {
            customerName: respData.data.name.toString(),
            customerNumber:  respData.data.phoneNumber.toString(),
            customerCompany: respData.data.companyName,
            customerGst: respData.data.GSTIN,
            loading: false,
          },
        })
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
      },
      (error: any) => {
        // let { data } = error
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: {customerName:  '',loading: false },
        })
        //   if(data?.message === "" || !data) {
        //     Utils.showAlert(2,"Customers Name Get Error:")
        // }else{
        //     Utils.showAlert(2,"Customers Name Get Error: " + data?.message)
        // }
      },
    )
  }
}

// Create an action creator
export const updateCustomerDetails = (details) => {
  return (dispatch: any, getState: any) => {
        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: {
            customerDetails:details
          }
  });
  };
}

export const shiftCreate = (
  storeId: any,
  billerId:any,
  openingCash:string,
  navigate: any,
  userId:any,
  billerName:any,
) => {
  return (dispatch:any) => {
    let dataToSend = {}
    dataToSend = {
      storeId: Number(storeId),
      billerId: billerId,
      openingCash: Number(openingCash),
      billerName:billerName
    }
    Utils.api.postApiCall(
      Utils.endPoints.shift,
      dataToSend,
      (data:any) => {
          //  localStorage.setItem("previousShiftId", prev);
        // localStorage.setItem("shiftId",data.data.id)
        localStorage.setItem("startTime",data.data.startTime)
        localStorage.setItem("status",data.data.status)
        Utils.showAlert(1, data.data.status)
        dispatch(getBillerId(userId,navigate));
      },
      (error: any) => {
        let { data } = error
        Utils.showAlert(2, data?.message)
      },
    )
  }
}

export const shiftClose = (
  shiftId: any,
  billerId:any,
  closingCash:string,
  comments:string,
  navigate: any,
  userId:any,
  closedByBillerName?:any,
  approvedByBillerName?:any,
  approvedByBillerId?:any,
  approved?:string,
  closingCashApprove?:any
) => {
  return (dispatch:any) => {
    let dataToSend = {}
    dataToSend = {
      shiftId: Number(shiftId),
      shiftCloseStatus: "closed by biller",
      closedByBiller: billerId,
      closingCash:Number(closingCash),
      comments:comments,
      approvedByBiller: approvedByBillerId,
      approvingCash:closingCashApprove,
      closedByBillerName:closedByBillerName,
      approvedByBillerName:approvedByBillerName
    }

    Utils.api.postApiCall(
      Utils.endPoints.shiftClose,
      dataToSend,
      (data:any) => {
          localStorage.setItem("shiftAlreadyCreated","yes")
        localStorage.setItem("shiftId","")
        Utils.showAlert(1, data.data.status)
       {approved!=="approved" && dispatch(logoutRefreshToken(navigate))} 
       dispatch(getBillerId(userId,navigate));
      },
      (error: any) => {
        let { data } = error
        Utils.showAlert(2, data?.message)
      },
    )
  }
}

export const getShiftDetails: any = (
  shiftId: any,
) => {
  return (dispatch: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
  })
  Utils.api.getApiCall(
      Utils.endPoints.shiftDetails, `${shiftId}`,
      (respData: any) => {    
        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: {
            shiftTestDetails:respData.data
          },
        })
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
      })
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
      })
      },
    )
  }
}

export const getAllShiftDetails = (storeID: any, fromDate: any, toDate: any) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });

    const {offset, take } = getState().productsReducer;

    Utils.api.getApiCall(
      Utils.endPoints.shiftStoreDetails,
      `${storeID}?order=DESC&offset=${offset}&take=${take}&fromDate=${fromDate}&toDate=${toDate}`,
      (respData: any) => {
        // console.log(respData.data.shifts)
        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: {
            shiftStoreDetails: respData.data.shifts,
            loading: false,
          },
        });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(2, data?.message);
      },
    );
  };
};

export const shiftApprove = (id: any,closingCash:any,userName:string | null,comments:string,fetchDefaultWeekData: () => void) => {
  return (dispatch: any) => {
      let dataToSend = {
          status:"approved",
          approvalAmount:Number(closingCash),
          approvedByBillerName:userName,
          approvedByBillerComments:comments
      }
      Utils.api.putApiCall(
          // eslint-disable-next-line
          Utils.endPoints.shiftDetails + `${id}`,
          dataToSend,
          () => {
              Utils.showAlert(1, 'Shift Approved')
              fetchDefaultWeekData();
          },
          (error: any) => {
              let { data } = error
              if(data?.error === "" || !data) {
                  Utils.showAlert(2,"Shift Approve Error")
              }else{
                  Utils.showAlert(2,"Shift Approve Error: " +  data?.error)
              }
          },
      )
  }
}