
import ReactToPrint from 'react-to-print';
import { useEffect, useMemo, useRef, useState } from 'react';
import {MenuItem,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper,Checkbox,Button,Select, FormControl, InputLabel, Box} from '@mui/material';
import { useSelector } from 'react-redux';
import { updateCredit } from './action';
import { useDispatch } from "react-redux";
import { orderInvoice } from '../billing/action';
import { clearInvoiceDetails } from '../billing/reducer';

const CreditOrders = ({ customerId,setOpen }:any) => {
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const { customerCreditData } = useSelector((state: any) => state.creditsReducer);
  // const getStoreId = localStorage.getItem("storeId");
  const { storeId: getStoreId } = useSelector(
    (state: any) => state.commonReducer
  );
  const dispatch: any = useDispatch();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedIds,setSelectedIds] = useState<any>([]);
  const [paymentMethods, setPaymentMethods] = useState(""); 

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedItems(customerCreditData.map((item) => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (event, itemId, id: any) => {
    const itemIndex = selectedItems.indexOf(id);
    if (event.target.checked && itemIndex === -1) {
      setSelectedItems([...selectedItems, id]);
      setSelectAll(true);
      setSelectedIds([...selectedIds,id])
    } else if (!event.target.checked && itemIndex !== -1) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    }
  };

  const totalSelectedAmount = useMemo(() => {
    const selectedAmounts = customerCreditData
      .filter((row) => selectedItems.includes(row.id))
      .map((row) => parseFloat(row.order.orderAmountAfterDiscount));
    return selectedAmounts.reduce((acc, curr) => acc + curr, 0);
  }, [customerCreditData, selectedItems]);

  const sum = useMemo(()=>{
    return(
      customerCreditData
  .map((item:any) => parseFloat(item.order.orderAmountAfterDiscount))
  .reduce((prev:string, curr:string) => prev + curr, 0)
    )
    // eslint-disable-next-line
},[customerCreditData])

  const creditButton = () => {
    if (selectedItems.length === customerCreditData.length) {
      dispatch(updateCredit(getStoreId, customerId, totalSelectedAmount.toString(), selectedItems,paymentMethods,setOpen));
    }else if(selectAll===true){
      dispatch(updateCredit(getStoreId,customerId,totalSelectedAmount.toString(),selectedIds,paymentMethods,setOpen))
    }
  };

  const handleChangePaymentMethod = (event) => {
    setPaymentMethods(event.target.value);
  };

  const invoiceHtml = useSelector((state: any) => state.productsReducer.invoiceHtml);
    const componentRef = useRef<HTMLDivElement>(null);
    const reactToPrintTrigger = useRef<any>();
  
    useEffect(() => {
      if (invoiceHtml && reactToPrintTrigger.current) {
        reactToPrintTrigger.current.handlePrint();
      }
    }, [invoiceHtml]);

    const handleClearInvoiceHtml = () => {
      dispatch(clearInvoiceDetails())
    };

  return (
    <>
          {invoiceHtml && (
   <ReactToPrint
   content={() => componentRef.current}
   ref={reactToPrintTrigger}
   onAfterPrint={handleClearInvoiceHtml} 
 />
      )}

      {invoiceHtml && (
        <div 
        id="invoice-printable"
        ref={componentRef} dangerouslySetInnerHTML={{ __html: invoiceHtml }} />
      )}
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                indeterminate={
                  selectedItems.length > 0 &&
                  selectedItems.length < customerCreditData.length
                }
                disabled={customerCreditData.map((item) => item.orderStatus === "paid") ? true : false}
                checked={selectedItems.length === customerCreditData.length && !selectAll}
                onChange={handleSelectAll}
              />
            </TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Invoice Number</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Payment Status</TableCell>
            <TableCell>Created By</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customerCreditData.map((row: any) => (
            <TableRow key={row.id}>
              <TableCell>
                <Checkbox
                  disabled={row.orderStatus === "paid"}
                  checked={selectedItems.includes(row.id)}
                  onChange={(e) => handleSelectItem(e, row.order.id, row.id)}
                />
              </TableCell>
              <TableCell>{new Date(row.createdAt).toLocaleDateString('en-US')}</TableCell>
              <TableCell style={{cursor: "pointer"}} onClick={() =>dispatch(orderInvoice(row?.order?.id))}>{row.order.stateInvoiceNo}</TableCell>
              <TableCell>{row.order.orderAmountAfterDiscount}</TableCell>
              <TableCell>{row.orderStatus}</TableCell>
              <TableCell>{row.createdBy.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      <TableRow>
        <TableCell colSpan={1} align="right"><b>Total:- </b></TableCell>
        <TableCell colSpan={1} align="left"><b>{sum}</b></TableCell>
        <TableCell colSpan={3} align="right">
        <Box>
        <FormControl>
        <InputLabel id="demo-simple-select-label">Payment</InputLabel>
                <Select
                style={{width:"150px",color:"black"}}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={paymentMethods}
                  label="Payment"
                  placeholder='Payment Method'
                  disabled={totalSelectedAmount===0?true:false}
                  onChange={(e) => handleChangePaymentMethod(e)}
                >
                  <MenuItem value="cash">Cash</MenuItem>
                  <MenuItem value="card">Card</MenuItem>
                </Select>
                </FormControl>
                </Box>
              </TableCell>
              <TableCell colSpan={2} align="right">
      <Button style={{ right: 0, marginTop: "5px" }} disabled={paymentMethods===""} onClick={creditButton}>Settle Credit</Button>
      </TableCell>
      </TableRow>
      </Table>
    </TableContainer>
    </>
  );
};

export default CreditOrders;
