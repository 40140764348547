import Utils from '../../utility'

const initialState = {
  orderID: "",
  mID: "",
  txnAmount: "",
  txnID: '',
}
export const paytmReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case `${Utils.ActionName.PAYTM}`:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return { ...state }
  }
}
