import api from "./api";
import showAlert from "./alert";
import endPoints from "./endPoints";
import constants from "./constant";
import ActionName from "./actionName";
import commonFunction from "./commonFunction";

const Utils = {
  api: api,
  showAlert: showAlert,
  endPoints: endPoints,
  constants: constants,
  ActionName: ActionName,
  commonFunction: commonFunction,
};

export default Utils;
