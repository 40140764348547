import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

type Props = {
  handleChange: any;
  value: any;
  label: string;
  option?: any; // you can replace 'any' with the proper type for your options
  name?: string;
  error?: any;
  disabled?: boolean;
  minWidth?: number;
};

export default function SelectOtherProps({
  handleChange,
  value,
  label,
  option,
  name,
  error,
  disabled,
  minWidth,
}: Props) {
  return (
    <FormControl
      error={error}
      disabled ={disabled}
      size="small"
      sx={{
        width: "100%",
        pr: minWidth ? 0.5 : 2,
        maxWidth: "100%",
        minWidth: minWidth ? minWidth : "auto",
      }}
    >
      <InputLabel id="demo-simple-select-disabled-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-disabled-label"
        id="demo-simple-select-disabled"
        value={value}
        label={label}
        onChange={handleChange}
        name={name}
      >
        {option}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
}
