import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const commonErr = "OOPS! something went wrong!";
const toastList = new Set();
const MAXIMUM_TOAST = 1;
const ShowAlert = (type: number, message: any = commonErr) => {
  if (toast.error === undefined) {
    //@ts-ignore
    toast.configure({
      autoClose: 3000,
      draggable: false,
      newestOnTop: true,
      position: "bottom-left",
    });
  }
  switch (type) {
    case 1: {
      if (toastList.size < MAXIMUM_TOAST) {
        const id = toast.success(message, {
          onClose: () => toastList.delete(id),
        });
        toastList.add(id);
      }
      break;
    }
    case 2: {
      if (toastList.size < MAXIMUM_TOAST) {
        const id = toast.error(
          message.constructor === Array ? message.join(",") : message,
          {
            onClose: () => toastList.delete(id),
          }
        );
        toastList.add(id);
      }
      break;
    }
    case 3: {
      if (toastList.size < MAXIMUM_TOAST) {
        const id = toast.info(message, {
          onClose: () => toastList.delete(id),
        });
        toastList.add(id);
      }
      break;
    }
  }
};

export default ShowAlert;
